// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    protocol: "https:",
	previewURL: "https://dev-member.myaltituderewards.com",
    // apiUrl: "dev-service-app.exploro.com"
    apiUrl: "api-dev.myaltituderewards.com",
    NEXT_PUBLIC_GOOGLE_MAP_API_KEY:"AIzaSyCWonGvSa0V3GCl1eWb03slx_EvVG-5AOc"
    // apiUrl: "dev-services.myaltituderewards.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


/*
Local Storage Keys
ACCOUNT_DETAILS
LANGUAGES
 */
