import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {catchError, finalize, Subject, takeUntil} from "rxjs";
import {
    DestinationAttributesType,
    DestinationOptionType,
    DestinationPropType,
    DestinationType
} from "../types/destinations-attributes.types";
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {toNumber} from 'lodash';
import {FuseAlertComponent} from "../../../../../@fuse/components/alert";
import {RestFactoryService} from "../../../../core/services/rest-factory.service";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {NgSelectModule} from '@ng-select/ng-select';

@Component({
    selector: "destinations-attribute-management",
    styleUrls: ["destinations-attributes-management.component.scss"],
    templateUrl: "./destinations-attributes-management.component.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    animations: fuseAnimations,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        MatTabGroup,
        MatTab,
        MatTabContent,
        FormsModule,
        MatGridList,
        MatGridTile,
        FuseAlertComponent,
        MatSelectionList,
        MatListOption,
        NgSelectModule
    ]
})
export class DestinationsAttributesManagementComponent implements OnInit {

    @Output() commandEvent = new EventEmitter<string>(); // Declare the output property
    @Input() public destinationPropertyFull: DestinationAttributesType;
    @Input() public destinationTypes: DestinationType[];
    @Input() adminMode: boolean = false; // Declare the input property

    inputDestinationMin: number = 1;
    inputDestinationMax: number = 3;
    inputDestinationOrder: number = 99;
    inputDestinationID: string = null;
    destinationTypesFiltered: DestinationType[] = [];

    inputOptionName: string = null;
    inputOptionLabel: string = null;
    inputOptionIconName: string = null;
    inputOptionOrder: number = 99;

    typingTimeout: any;

    inputRowEditor: number = null;

    objectSave: boolean = false;

    searchLoader: boolean = false;
    options = [];
    selectedDestinationOption = null;
    selectedDestinationOptionEditor = null;

    destinationproptypeArray = [
        {en_destinationproptype: 1, ds_en_destinationproptype: "Property Feature"},
        {en_destinationproptype: 2, ds_en_destinationproptype: "Navigation Property"},
    ];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private API_GET_DESTINATIONS = "/admin/destination/search";

    private API_POST_DESTINATION_PROPERTY = "/admin/destinationprop/addedit";
    private API_DELETE_DESTINATIONS_PROPERTIES = "/admin/destinationprop/delete";


    private API_ADD_EDIT_DESTINATIONS_TYPE = "/admin/destinationprop/dtype/addedit";
    private API_DELETE_DESTINATIONS_TYPE = "/admin/destinationprop/dtype/delete";


    private API_ADD_EDIT_OPTIONS_TYPE = "/admin/destinationprop/option/addedit";
    private API_DELETE_OPTIONS_TYPE = "/admin/destinationprop/option/delete";

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _restFactoryService: RestFactoryService,
        private _fuseConfirmationService: FuseConfirmationService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getUniqueDestinationTypes();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // TODO: Find a way to merge it with modifyValueGeneric
    modifyValue(property: string, amount: number, limit: number = 0) {
        let newValue = toNumber(this[property]) + amount;
        if (newValue < limit) {
            newValue = limit;
        }
        this[property] = newValue;
        if (property === "inputDestinationMin" && newValue > this.inputDestinationMax) {
            this.inputDestinationMax = newValue;
        } else if (property === "inputDestinationMax" && newValue < this.inputDestinationMin) {
            this.inputDestinationMin = newValue;
        }
    }

    // TODO: Find a way to merge it with modifyValue
    modifyValueGeneric(obj: DestinationPropType, property: string, amount: number, limit: number = 0) {
        let newValue = toNumber(obj[property]) + amount;
        if (newValue < limit) {
            newValue = limit;
        }
        obj[property] = newValue;
        if (property === "vi_min" && newValue > obj.vi_max) {
            obj.vi_max = newValue;
        } else if (property === "vi_max" && newValue < obj.vi_min) {
            obj.vi_min = newValue;
        }
    }

    // Helper for HTML method to sum values
    sumValues(obj: any, property: string, amount: number) {
        obj[property] = toNumber(obj[property]) + amount;
    }

    // Helper for HTML method to check if a value is a number
    checkIfNumber(value: any): boolean {
        return isNaN(Number(value));
    }

    adjustMinMaxValues() {
        if (this.inputDestinationMax < 1) {
            this.inputDestinationMax = 1;
        }
        if (this.inputDestinationMin < 0) {
            this.inputDestinationMin = 0;
        }
        if (this.inputDestinationMin > this.inputDestinationMax) {
            this.inputDestinationMin = toNumber(this.inputDestinationMax);
        } else if (this.inputDestinationMax < this.inputDestinationMin) {
            this.inputDestinationMax = toNumber(this.inputDestinationMin);
        }
    }

    adjustMinMaxValuesGeneric(obj: DestinationPropType, propertyMin: string, propertyMax: string) {
        if (obj[propertyMax] < 1) {
            obj[propertyMax] = 1;
        }
        if (obj[propertyMin] < 0) {
            obj[propertyMin] = 0;
        }
        if (obj[propertyMin] > obj[propertyMax]) {
            obj[propertyMin] = toNumber(obj[propertyMax]);
        } else if (obj[propertyMax] < obj[propertyMin]) {
            obj[propertyMax] = toNumber(obj[propertyMin]);
        }
    }

    verifyInputValues(): boolean {
        return !!(this.inputOptionName && this.inputOptionLabel && this.inputOptionIconName);
    }

    editOptionRow(index: number): void {
        if (this.destinationPropertyFull.ar_destinationpropoptions[index].id_destination) {
            this.selectedDestinationOptionEditor = {
                id_destination: this.destinationPropertyFull.ar_destinationpropoptions[index].id_destination,
                ds_name: this.destinationPropertyFull.ar_destinationpropoptions[index].ds_destination
            };
        } else {
            this.selectedDestinationOptionEditor = null;
        }
        this.inputRowEditor = index;
        this._changeDetectorRef.detectChanges();
    }


    getUniqueDestinationTypes() {
        const idToExclude = this.destinationPropertyFull.ar_destinationpropdtypes.map(item => item.id_destinationtype);
        this.destinationTypesFiltered = this.destinationTypes.filter(item => !idToExclude.includes(item.id_destinationtype));
        this.destinationTypesFiltered.sort((a, b) => a.ds_name.localeCompare(b.ds_name));
    }

    // A method that emits the command event
    sendCommand() {
        if (this.destinationPropertyFull.id_destinationprop === null) {
            const confirmation = this._fuseConfirmationService.open({
                title: "New Attribute will be lost if not saved!",
                message: `Are you sure you want to go back? This action will make you lose progress!`,
                actions: {
                    confirm: {
                        label: "Go Back"
                    }
                }
            });
            // Subscribe to the confirmation dialog closed action
            confirmation.afterClosed().subscribe((result) => {
                // If the confirm button pressed...
                if (result === "confirmed") {
                    this.commandEvent.emit("search");
                }
            });
        } else {
            if (this.objectSave) {
                this.commandEvent.emit("search");
            } else {
                this.commandEvent.emit(null);
            }
        }
    }

    verifyDestinationPropertyFull(): boolean {
        if (!this.destinationPropertyFull.id_destinationprop) {
            return (this.destinationPropertyFull.ds_name !== null && this.destinationPropertyFull.ds_name !== "") &&
                (this.destinationPropertyFull.ds_label !== null && this.destinationPropertyFull.ds_label !== "") &&
                (this.destinationPropertyFull.ds_dataname !== null && this.destinationPropertyFull.ds_dataname !== "") &&
                this.destinationPropertyFull.en_destinationproptype !== null;
        } else {
            return this.destinationPropertyFull.ds_name !== null &&
                this.destinationPropertyFull.ds_label !== null &&
                this.destinationPropertyFull.ds_dataname !== null &&
                this.destinationPropertyFull.en_destinationproptype !== null;
        }
    }

    /**
     * Delete Destination Property
     */
    deleteAttribute(): void {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: "Delete item",
            message: `Are you sure you want to remove ${this.destinationPropertyFull.ds_name}?
            This action cannot be undone! Notice if you have any references to this attribute, the removal will fail!`,
            actions: {
                confirm: {
                    label: "Delete"
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {
            // If the confirm button pressed...
            if (result === "confirmed") {

                const payload = new FormData();
                payload.append("id_destinationprop", this.destinationPropertyFull.id_destinationprop);

                // Delete the product on the server

                this._unsubscribeAll.next(null);
                this._restFactoryService.httpPostService(this.API_DELETE_DESTINATIONS_PROPERTIES, payload)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        finalize(() => {
                            this.commandEvent.emit("search");
                        }),
                        catchError((err) => {
                            return err;
                        })
                    )
                    .subscribe(
                        () => {
                        });
            }
        });
    }

    /**
     * Delete Destination Property Type
     */
    deletePropertyType(property: DestinationPropType) {
        const payload = new FormData();
        payload.append("id_destinationpropdtype", property.id_destinationpropdtype);

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_DESTINATIONS_TYPE, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.destinationPropertyFull.ar_destinationpropdtypes.splice(this.inputRowEditor, 1);
                    this.getUniqueDestinationTypes();
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Add or Edit Destination Property Type
     */
    addEditPropertyType(property: DestinationPropType = null) {
        const payload = new FormData();

        payload.append("id_destinationprop", this.destinationPropertyFull.id_destinationprop);
        payload.append("id_destinationpropdtype", property ? property.id_destinationpropdtype : "");
        payload.append("id_destinationtype", property ? property.id_destinationtype : this.inputDestinationID);
        payload.append("vi_min", (property ? property.vi_min : this.inputDestinationMin).toString());
        payload.append("vi_max", (property ? property.vi_max : this.inputDestinationMax).toString());
        payload.append("vi_order", (property ? property.vi_order : this.inputDestinationOrder).toString());

        // // @ts-ignore
        // for (var pair of payload.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }
        // return;
        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_DESTINATIONS_TYPE, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (property) {
                        this.destinationPropertyFull.ar_destinationpropdtypes.splice(this.inputRowEditor, 1);
                    } else {
                        this.inputDestinationMin = 1;
                        this.inputDestinationMax = 3;
                        this.inputDestinationOrder = 99;
                        this.inputDestinationID = null;
                    }
                    this.destinationPropertyFull.ar_destinationpropdtypes.push(response.data);
                    this.destinationPropertyFull.ar_destinationpropdtypes.sort((a, b) => a.vi_order - b.vi_order);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * On Keyup Autosave
     */
    onKeyupAutosave(term: string): void {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            this.searchDestinations(term);
        }, 500);
    }

    /**
     * Search Destinations
     */
    searchDestinations(term: string) {
        if (term.length < 3) {
            return;
        }

        let data = {
            "ds_name": term
        };
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        this.searchLoader = true;
        this._changeDetectorRef.detectChanges();

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_DESTINATIONS, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this.searchLoader = false;
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.options = response.ar_results;
                });
    }

    /**
     * Delete Destination Option Type
     */
    deleteOption(property: DestinationOptionType) {
        const payload = new FormData();
        payload.append("id_destinationpropoption", property.id_destinationpropoption);

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_OPTIONS_TYPE, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.destinationPropertyFull.ar_destinationpropoptions.splice(this.inputRowEditor, 1);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Add or Edit Destination Option Type
     */
    addEditOptionType(property: DestinationOptionType = null) {
        const payload = new FormData();

        if (property) {
            payload.append("id_destination", this.selectedDestinationOptionEditor?.id_destination || "");
        } else {
            payload.append("id_destination", this.selectedDestinationOption?.id_destination || "");
        }
        payload.append("id_destinationprop", this.destinationPropertyFull.id_destinationprop);
        payload.append("id_destinationpropoption", property ? property.id_destinationpropoption : "");
        payload.append("ds_name", property ? property.ds_name : this.inputOptionName);
        payload.append("ds_label", property ? property.ds_label : this.inputOptionLabel);
        payload.append("ds_dataname", (property ? property.ds_dataname : this.inputOptionName.toLowerCase().replace(/ /g, '_')));
        payload.append("ds_iconname", (property ? property.ds_iconname : this.inputOptionIconName));
        payload.append("vi_order", (property ? property.vi_order : this.inputOptionOrder).toString());

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_OPTIONS_TYPE, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (property) {
                        this.destinationPropertyFull.ar_destinationpropoptions.splice(this.inputRowEditor, 1);
                    } else {
                        // Reset the input values
                        this.inputOptionName = null;
                        this.inputOptionLabel = null;
                        this.inputOptionIconName = null;
                        this.inputOptionOrder = 99;
                        this.selectedDestinationOption = null;
                    }
                    this.destinationPropertyFull.ar_destinationpropoptions.push(response.data);
                    this.destinationPropertyFull.ar_destinationpropoptions.sort((a, b) => a.vi_order - b.vi_order);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Save and close
     */
    saveAttribute(): void {
        const payload = new FormData();

        payload.append("id_destinationprop", this.destinationPropertyFull.id_destinationprop || "");
        payload.append("ds_name", this.destinationPropertyFull.ds_name);
        payload.append("ds_label", this.destinationPropertyFull.ds_label);
        payload.append("ds_dataname", this.destinationPropertyFull.ds_dataname);
        payload.append("bl_values", (this.destinationPropertyFull.bl_values).toString());
        payload.append("vi_order", (this.destinationPropertyFull.vi_order).toString());
        payload.append("en_destinationproptype", (this.destinationPropertyFull.en_destinationproptype).toString());

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_POST_DESTINATION_PROPERTY, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.objectSave = true;
                    this.destinationPropertyFull.id_destinationprop = response.data.id_destinationprop;
                });
    }
}
