export const en_DestinationType = [
    {value: 1, view: "Home Page"},
    {value: 2, view: "Country"},
    {value: 3, view: "City"},
    {value: 4, view: "Hotel"},
    {value: 5, view: "Restaurant"},
    {value: 6, view: "Attraction"},
    {value: 7, view: "Shop"},
    {value: 8, view: "Other"},
    {value: 9, view: "Airline"},
    {value: 10, view: "Continent"},
    {value: 11, view: "FlyRegion"},
    {value: 12, view: "OuterRegion"},
    {value: 13, view: "State"},
    {value: 14, view: "InnerRegion"},
    {value: 15, view: "MiniDestination"},
    {value: 16, view: "Regions"},
    {value: 17, view: "Attractions"},
    {value: 18, view: "Destination"},

    {value: 30, view: "Climate"},

    {value: 100, view: "Gallery"},
    {value: 101, view: "Room Types"},
    {value: 102, view: "Rides"},
    {value: 103, view: "Products"},
    {value: 104, view: "Tours"},

    {value: 106, view: "Menu"},

    {value: 200, view: "HRAs"},
    {value: 299, view: "FeaturedChilds"},

    {value: 300, view: "All-Stories"},
    {value: 301, view: "Story-Info"},
    {value: 302, view: "Story-FlyInfo"},
    {value: 303, view: "Story-Place"},
    {value: 304, view: "Story-Advertisement"},
    {value: 305, view: "Story-Showcase"},
    {value: 306, view: "Story-Experience"},
    {value: 307, view: "Story-Activity"},
    {value: 308, view: "Story-Learning"},
    {value: 309, view: "Story-Map"},

    {value: 400, view: "Features (amenities, etc)"},
];

export const en_DestinationSuperType= [
    {value: 1, view: "SystemDestination"},
    {value: 2, view: "ClientDestination"},
    {value: 3, view: "PublicDestination"},
    {value: 4, view: "Information"},
    {value: 100, view: "SystemInternal"},
];

export const en_State= [
    {value: -1, view: "All"},
    {value: 0, view: "New"},
    {value: 1, view: "Editing"},
    {value: 2, view: "Draft"},
    {value: 3, view: "Approved"},
    {value: 4, view: "Production"},
    {value: 5, view: "Rejected"},
    {value: 6, view: "In Review"},
];


export const QuillConfiguration = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],
        [{align: []}, {list: "ordered"}, {list: "bullet"}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'size': ['small', false, 'large', 'huge']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}, {'background': []}],
        [{'font': []}],
        [{'align': []}],
        ["clean"]
    ]
};

