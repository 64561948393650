<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Change Parent</div>
        <button
            mat-icon-button
            (click)="closeDialog()"
            [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Compose form -->
    <div
        class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">

        <!-- Name -->

        <mat-form-field class="w-full">
            <mat-label>Parent</mat-label>
            <input type="text"
                   #autoCompleteTrigger="matAutocompleteTrigger"
                   autofocus
                   placeholder="Parent"
                   aria-label="Parent"
                   (blur)="onBlur()"
                   (keyup)="onKeyupAutoSearch()"
                   matInput [formControl]="parentControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete
                (optionSelected)="countryClick($event)"
                #auto="matAutocomplete"
                [displayWith]="displayFn"
                autoActiveFirstOption="true">
                <mat-option *ngFor="let city of filteredParents | async" [value]="city">
                    {{city.ds_name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="parentControl.invalid && (parentControl.touched || parentControl.dirty)">
                Please select a valid parent.
            </mat-error>
        </mat-form-field>

        <!-- Actions -->
        <div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
            <div class="-ml-2">
            </div>
            <div class="flex items-center mt-4 sm:mt-0">
                <!-- Discard -->
                <button
                    class="ml-auto sm:ml-0"
                    mat-stroked-button
                    (click)="closeDialog()">
                    Cancel
                </button>
                <!-- Save as draft -->
                <div class="sm:mx-3"></div>
                <!-- Send -->
                <button
                    class="order-first sm:order-last"
                    mat-flat-button
                    [color]="'primary'"
                    (click)="saveAndClose()">
                    Send
                </button>
            </div>
        </div>
    </div>
</div>
