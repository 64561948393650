import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CommonModule, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {DestinationSuperTypesTypes} from '../types/destination-super-types.types';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {ImagePreloadDirective} from '../../../../core/directives/image-preload.directive';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {catchError, finalize, Subject, takeUntil} from 'rxjs';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: "destination-super-types-list",
    templateUrl: "./destination-super-types-list.component.html",
    styleUrls: ["./destination-super-types-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatDatepickerModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        ImagePreloadDirective,
        NgOptimizedImage,
        NgxSpinnerComponent,
    ]
})
export class DestinationSuperTypesListComponent implements OnInit, OnDestroy {

    isMobile: boolean;
    loadingMessage: string = null;

    selectedItem: DestinationSuperTypesTypes = null;
    selectedItemForm: UntypedFormGroup;

    imageURLHelper: string = null;

    flashMessage: 'success' | 'error' | 'dateChange' | null = null;

    enComboArrayOne: { value: any, viewValue: any }[] = [
        {value: 1, viewValue: 'SystemDestination'},
        {value: 2, viewValue: 'ClientDestination'},
        {value: 3, viewValue: 'PublicDestination'},
        {value: 4, viewValue: 'Information'},
        {value: 100, viewValue: 'SystemInternal'}
    ];


    // New TODO

    inputSearchControl: UntypedFormControl = new UntypedFormControl("");
    originalTableArray: DestinationSuperTypesTypes[] = [];
    tableStoryTypesArray: DestinationSuperTypesTypes[] = [];

    // Pagination
    pageIndex: number = 0;
    pageSize: number = 10;
    pageLength: number = 0;
    isLoading: boolean = false;
    @ViewChild(MatPaginator) private _paginator: MatPaginator;


    // API URL
    private API_GET_DEST_SUPER_TYPES = "/admin/destinationsupertype/list";
    private API_ADD_EDIT_DEST_SUPER_TYPES = "/admin/destinationsupertype/addedit";
    private API_DELETE_DEST_SUPER_TYPES = "/admin/destinationsupertype/delete";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _restFactoryService: RestFactoryService,
        private spinner: NgxSpinnerService,
        private breakpointObserver: BreakpointObserver,
        private _snackBar: MatSnackBar
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            this.isMobile = result.matches;
        });

        this.getTableElements();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getTableElements(sID: string = null): void {
        this.isLoading = true;
        if (!sID) {
            this.selectedItem = null;
            this.selectedItemForm = null;
        }
        this._unsubscribeAll.next(null);
        this.spinner.show().then();
        this._restFactoryService.httpPostService(this.API_GET_DEST_SUPER_TYPES, null)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this.spinner.hide().then();
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.originalTableArray = response.data;
                    if (this.originalTableArray.length === 0) {
                        this.pageIndex = 0;
                        this.pageLength = 0;
                        this._paginator.firstPage();
                        this.loadingMessage = "No results found";
                    } else {
                        this.searchElements(true);
                    }
                    if (sID) {
                        this.toggleDetails(sID);
                    }
                    this.isLoading = false;
                    this.spinner.hide().then();
                });
    }

    /**
     * Page changed
     */
    pageChanged(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchElements();
    }

    /**
     * Open new-search-window dialog
     */
    searchElements(searcher: boolean = false): void {
        if (searcher) {
            this.pageIndex = 0;
            this._paginator.firstPage();
        }
        const start = this.pageIndex * this.pageSize;
        const end = start + this.pageSize;

        if (this.inputSearchControl.getRawValue()) {
            this.tableStoryTypesArray = this.originalTableArray
                .filter(prop => prop.ds_name.toLowerCase().includes(this.inputSearchControl.getRawValue()))
                .slice(start, end);
            this.pageLength = this.tableStoryTypesArray.length;
        } else {
            this.tableStoryTypesArray = this.originalTableArray.slice(start, end);
            this.pageLength = this.originalTableArray.length;
        }
        this._changeDetectorRef.detectChanges();
    }

    findStoryType(id: number) {
        return this.enComboArrayOne.find(storyType => storyType.value === id);
    }

    /**
     * Toggle product details
     *
     * @param soID
     */
    toggleDetails(soID: string): void {
        // If the product is already selected...
        if (this.selectedItem && this.selectedItem.id_destinationsupertype === soID) {
            // Close the details
            this.closeDetails();
            return;
        }

        if (this.selectedItem && this.selectedItem.id_destinationsupertype === null) {
            this.closeDetails();
            return;
        }

        // Find the selected special offer
        this.selectedItem = this.tableStoryTypesArray.find(item => item.id_destinationsupertype === soID) || null;

        // If a special offer is selected...
        if (this.selectedItem) {
            // Create the selected product form
            this.imageURLHelper = this.selectedItem.id_media;
            this.selectedItemForm = this._formBuilder.group({
                id_destinationsupertype: [this.selectedItem.id_destinationsupertype],
                id_media: [this.selectedItem.id_media],
                en_destinationsupertype: [this.selectedItem.en_destinationsupertype, Validators.required],
                ds_en_destinationsupertype: [this.selectedItem.ds_en_destinationsupertype],
                ds_name: [this.selectedItem.ds_name, Validators.required],
                ds_shortname: [this.selectedItem.ds_shortname, Validators.required],
                ds_summary: [this.selectedItem.ds_summary],
                tx_description: [this.selectedItem.tx_description, Validators.required]
            });
        }

        this._changeDetectorRef.detectChanges();
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        if (this.selectedItem.id_destinationsupertype === null || (this.selectedItemForm.touched && this.selectedItemForm.dirty)) {
            const message = this._fuseConfirmationService.open({
                title: "Warning!",
                message: "The story type is not saved, and will be lost on toggle!",
                actions: {
                    confirm: {
                        show: true
                    },
                    cancel: {
                        show: true
                    }
                },
                dismissible: false
            });
            message.afterClosed().subscribe(
                (value) => {
                    if (value === "confirmed") {
                        if (this.selectedItem.id_destinationsupertype === null) {
                            this.tableStoryTypesArray.shift();
                        }
                        this.selectedItem = null;
                        this.selectedItemForm = null;
                        this._changeDetectorRef.markForCheck();
                    }
                }
            );
        } else {
            this.selectedItem = null;
            this.selectedItemForm = null;
            this._changeDetectorRef.markForCheck();
        }
    }

    createAndToggleNewSpecialOffer(): void {
        // Create a new SpecialOffersType object
        let newStoryType: DestinationSuperTypesTypes = {
            ds_en_destinationsupertype: null,
            ds_name: null,
            ds_shortname: null,
            ds_summary: null,
            en_destinationsupertype: 1,
            id_destinationsupertype: null,
            id_media: null,
            tx_description: null
            // ...
        };

        this.imageURLHelper = null;

        // Push the new special offer to the top of the specialOffersList array
        this.tableStoryTypesArray.unshift(newStoryType);

        // Toggle the new special offer
        this.toggleDetails(newStoryType.id_destinationsupertype);
    }

    deleteStoryType(): void {
        const message = this._fuseConfirmationService.open({
            title: "Delete Destination Super Type",
            message: "Are you sure you want to delete this Destination Super Type?",
            actions: {
                confirm: {
                    show: true
                },
                cancel: {
                    show: true
                }
            },
            dismissible: false
        });

        message.afterClosed().subscribe(
            (value) => {
                if (value === "confirmed") {
                    const payload = new FormData();
                    payload.append("id_destinationsupertype", this.selectedItem.id_destinationsupertype);
                    this._unsubscribeAll.next(null);
                    this._restFactoryService.httpPostService(this.API_DELETE_DEST_SUPER_TYPES, payload)
                        .pipe(
                            takeUntil(this._unsubscribeAll),
                            finalize(() => {
                                this._changeDetectorRef.detectChanges();
                            }),
                            catchError((err) => {
                                return err;
                            })
                        )
                        .subscribe(
                            () => {
                                this.getTableElements();
                            });
                }
            }
        );
    }

    /**
     * upload File Manager
     */
    onChangeFile(event: any, property: string, source: string, id: string = null): void {
        const inpF = event.target as HTMLInputElement;
        const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp"];
        const fileExtension = (inpF.files[0] as any).name.toLowerCase().split('.').pop();

        if (allowedExtensions.includes(fileExtension)) {


            const file = inpF.files[0];
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(file);

            this.selectedItemForm.get('im_media').setValue(file);
            this.imageURLHelper = urlCreator.createObjectURL(file);
            this._changeDetectorRef.markForCheck();

        } else {
            let messageErr = "Invalid image format. Please upload a .jpg, .png, .jpeg, .gif, or .webp file.";
            const message = this._fuseConfirmationService.open({
                title: "Error!",
                message: messageErr,
                actions: {
                    confirm: {
                        show: false
                    },
                    cancel: {
                        show: false
                    }
                },
                dismissible: false
            });
            setTimeout(() => {
                message.close();
            }, 2000);
        }
    }

    /**
     * Open new-search-window dialog
     */
    saveDestSuperType(): void {

        const payload = new FormData();

        if (this.selectedItemForm.get("id_destinationsupertype").value) {
            payload.append("id_destinationsupertype", this.selectedItemForm.get("id_destinationsupertype").value);
        }

        if (this.selectedItemForm.get("id_media").value !== this.selectedItem.id_media) {
            payload.append("im_image", this.selectedItemForm.get("id_media").value);
        }

        payload.append("ds_name", this.selectedItemForm.get("ds_name").value);
        payload.append("ds_en_destinationsupertype", this.enComboArrayOne.find(item => item.value === this.selectedItemForm.get("en_destinationsupertype").value).viewValue);
        payload.append("en_destinationsupertype", this.selectedItemForm.get("en_destinationsupertype").value);
        payload.append("ds_shortname", this.selectedItemForm.get("ds_shortname").value);
        payload.append("ds_summary", this.selectedItemForm.get("ds_summary").value);
        payload.append("tx_description", this.selectedItemForm.get("tx_description").value);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_DEST_SUPER_TYPES, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.getTableElements();
                });
    }

    onInputMaxReach(event: any, maxReach: number = 15): void {
        const inputValue = event.target.value;
        if (inputValue.length >= 25) {
            // The input value has reached or exceeded the limit
            // You can take appropriate action here
            this._snackBar.open(`This field has a limit of ${maxReach} characters. Any text exceeding this limit will be cut off.`, "Close", {
                duration: 5000,  // Duration is in milliseconds (5000ms = 5s),
                panelClass: ['bg-card', 'dark:bg-transparent']
            });
        }
    }

}
