import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
    selector: 'img[default]',
    standalone: true,
    host: {
        '(error)': 'updateUrl()',
        '(load)': 'load()',
        '[src]': 'src'
    }
})
export class ImagePreloadDirective {
    @Input() src: string;
    @Input() default: string;

    @HostBinding('class') className: string;

    updateUrl() {
        // If the image fails to load, set the src attribute to the default URL
        this.src = this.default;
    }

    load() {
        // Mark the image as loaded (you can apply custom styling here)
        this.className = 'image-loaded';
    }
}
