import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // No error if the control is empty
        }
        return control.value.length >= minLength ? null : { minLength: { requiredLength: minLength, actualLength: control.value.length } };
    };
}

export function matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const controlToMatch = control.get(controlName);
        const matchingControl = control.get(matchingControlName);
        return controlToMatch && matchingControl && controlToMatch.value === matchingControl.value ? null : { mismatch: true };
    };
}
