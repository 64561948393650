import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {catchError, finalize, forkJoin, of, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {DestinationTypeType} from "../types/destinations-types.types";
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {
    DestinationsTypesManagementComponent
} from '../destinations-types-management/destinations-types-management.component';
import {DestinationSuperTypesTypes} from '../../destination-super-types/types/destination-super-types.types';
import {StoryTypesTypes} from '../../story-types/types/story-types.types';
import {DestinationAttributesType} from '../../destinations-attributes/types/destinations-attributes.types';

@Component({
    selector: "destinations-types-list",
    templateUrl: "./destinations-types-list.component.html",
    styleUrls: ["./destinations-types-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    animations: fuseAnimations,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        NgxSpinnerComponent,
        DestinationsTypesManagementComponent,
    ]
})
export class DestinationsTypesListComponent implements OnInit, OnDestroy {

    inputSearchControl: UntypedFormControl = new UntypedFormControl("");
    showList: boolean = false;
    loadingMessage: string = null;

    searchTableArray: DestinationTypeType[] = [];
    originalTableArray: DestinationTypeType[] = [];
    superTypesArray: DestinationSuperTypesTypes[] = [];
    storyTypesArray: StoryTypesTypes[] = [];
    destinationsPropertiesArray: DestinationAttributesType[] = [];
    selectedElement: DestinationTypeType = null;

    // Pagination
    pageIndex: number = 0;
    pageSize: number = 10;
    pageLength: number = 0;
    isLoading: boolean = false;
    @ViewChild(MatPaginator) private _paginator: MatPaginator;

    // API URL
    private API_GET_DESTINATIONS_TYPES_LIST = "/admin/destinationtype/list";
    private API_GET_DESTINATION_TYPE = "/admin/destinationtype/get";
    private API_GET_DESTINATION_SUPER_TYPES = "/admin/destinationsupertype/list";
    private API_GET_DESTINATION_STORY_TYPES = "/admin/storytype/list";
    private API_GET_DESTINATION_ATTRIBUTES_LIST = "/admin/destinationprop/list";


    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _restFactoryService: RestFactoryService,
        private spinner: NgxSpinnerService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the data
        this.spinner.show().then();
        const payload = new FormData();
        payload.append("en_destinationproptype", "1");
        this.getApiService([
            {
                api: this.API_GET_DESTINATIONS_TYPES_LIST,
                key: "data",
                table: this.originalTableArray,
                payload: null
            },
            {
                api: this.API_GET_DESTINATION_SUPER_TYPES,
                key: "data",
                table: this.superTypesArray,
                payload: null
            },
            {
                api: this.API_GET_DESTINATION_STORY_TYPES,
                key: "data",
                table: this.storyTypesArray,
                payload: null
            },
            {
                api: this.API_GET_DESTINATION_ATTRIBUTES_LIST,
                key: "data",
                table: this.destinationsPropertiesArray,
                payload: payload
            },
        ]);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Table Elements
     */
    getApiService(apis: { api: string; key: string; table: any, payload: FormData }[]): void {
        forkJoin(
            apis.map(({api, key, payload}) =>
                this._restFactoryService.httpPostService(api, payload, key)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        catchError(() => {
                            return of([]);
                        })
                    )
            )
        ).pipe(
            finalize(() => {
                if (this.originalTableArray.length === 0) {
                    this.loadingMessage = "No results found";
                } else {
                    this.searchElements();
                }
                this.isLoading = false;
                this.spinner.hide().then();
            })
        ).subscribe((responses) => {
            responses.forEach((response, index) => {
                apis[index].table.push(...response);
                this._changeDetectorRef.markForCheck();
            });
        });
    }

    /**
     * Page changed
     */
    pageChanged(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchElements();
    }

    /**
     * Search Destinations Attributes
     */
    searchElements(searcher: boolean = false): void {
        if (searcher) {
            this.pageIndex = 0;
            this._paginator.firstPage();
        }
        const start = this.pageIndex * this.pageSize;
        const end = start + this.pageSize;

        if (this.inputSearchControl.getRawValue()) {
            this.searchTableArray = this.originalTableArray
                .filter(prop => prop.ds_name.toLowerCase().includes(this.inputSearchControl.getRawValue()))
                .slice(start, end);
            this.pageLength = this.searchTableArray.length;
        } else {
            this.searchTableArray = this.originalTableArray.slice(start, end);
            this.pageLength = this.originalTableArray.length;
        }
        this.searchTableArray.sort((a, b) => a.vi_order - b.vi_order);
        this._changeDetectorRef.detectChanges();
    }

    processElement(id: string = null): void {
        if (id) {
            const formData = new FormData();
            formData.append("id_destinationtype", id);
            this._restFactoryService.httpPostService(
                this.API_GET_DESTINATION_TYPE,
                formData,
                "data").subscribe((response) => {
                this.openDestinationAttribute(response);
            });
        } else {
            this.openDestinationAttribute({
                ar_destinationchildconfig: [],
                ar_destinationparentconfig: [],
                ar_destinationpropconfig: [],
                ar_destinationstoryconfig: [],
                bl_offers: false,
                bl_mainbutton: false,
                bl_locationboundaries: false,
                ds_destinationsupertype: "",
                ds_en_destinationsupertype: "",
                ds_en_destinationtype: "",
                ds_iconname: "",
                ds_label: "",
                ds_datalabel1: "",
                ds_datalabel2: "",
                ds_datalabel3: "",
                ds_layout1: "",
                ds_layout2: "",
                ds_layout3: "",
                ds_layout4: "",
                ds_layout5: "",
                ds_name: "",
                ds_shortname: "",
                ds_subtitle: "",
                ds_subtitle1: "",
                ds_subtitle2: "",
                ds_subtitle3: "",
                ds_subtitle4: "",
                ds_subtitle5: "",
                ds_subtitle6: "",
                ds_subtitle7: "",
                ds_subtitle8: "",
                ds_subtitle9: "",
                ds_subtitle10: "",
                ds_summary: "",
                ds_title: "",
                ds_title1: "",
                ds_title2: "",
                ds_title3: "",
                ds_title4: "",
                ds_title5: "",
                ds_title6: "",
                ds_title7: "",
                ds_title8: "",
                ds_title9: "",
                ds_title10: "",
                en_destinationsupertype: 1,
                en_destinationtype: 1,
                id_destinationsupertype: "",
                id_destinationtype: "",
                id_displaylayout: "",
                id_media: "",
                tx_description: "",
                vi_order: -1,
            });
        }
    }

    /**
     * Switch to the destination attribute
     */
    openDestinationAttribute(destinationProperty: DestinationTypeType): void {
        this.selectedElement = destinationProperty;
        this.showList = !this.showList;

        this._changeDetectorRef.markForCheck();
        setTimeout(() => {
            this._changeDetectorRef.markForCheck();
        }, 1000);
        return;
    }

    /**
     * Handle Command from the child component
     */
    handleCommand(command: string) {
        // Do something with the command
        this.selectedElement = null;
        this.showList = !this.showList;
        this._changeDetectorRef.markForCheck();
        if (command === "search") {
            this.originalTableArray = [];
            const payload = new FormData();
            this.getApiService([
                {
                    api: this.API_GET_DESTINATIONS_TYPES_LIST,
                    key: "data",
                    table: this.originalTableArray,
                    payload: payload
                }
            ]);
        }
    }
}
