import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {catchError, finalize, forkJoin, of, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {DestinationAttributesType, DestinationType} from "../types/destinations-attributes.types";
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {
    DestinationsAttributesManagementComponent
} from '../destinations-attribute-management/destinations-attributes-management.component';

@Component({
    selector: "destinations-properties-list",
    templateUrl: "./destinations-attributes-list.component.html",
    styleUrls: ["./destinations-attributes-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    animations: fuseAnimations,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        NgxSpinnerComponent,
        DestinationsAttributesManagementComponent,
    ]
})
export class DestinationsAttributesListComponent implements OnInit, OnDestroy {

    inputSearchControl: UntypedFormControl = new UntypedFormControl("");
    showList: boolean = false;
    loadingMessage: string = null;
    destinationAttribute: DestinationAttributesType;

    tableDestinationPropertiesArray: DestinationAttributesType[] = [];
    originalDestinationPropertiesArray: DestinationAttributesType[] = [];
    destinationTypesArray: DestinationType[] = [];
    title: string = "Destination Attributes";

    // Pagination
    pageIndex: number = 0;
    pageSize: number = 10;
    pageLength: number = 0;
    isLoading: boolean = false;
    @ViewChild(MatPaginator) private _paginator: MatPaginator;

    // API URL
    private API_GET_DESTINATIONS_TYPES = "/admin/list/destinationtypes";
    private API_GET_DESTINATIONS_PROPERTIES = "/admin/destinationprop/list";
    private API_GET_DESTINATION_PROPERTY = "/admin/destinationprop/get";


    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _restFactoryService: RestFactoryService,
        private spinner: NgxSpinnerService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the data
        this.spinner.show().then();
        const payload = new FormData();
        payload.append("en_destinationproptype", this.getNumberFromCurrentURL().toString());
        this.getApiService([
            {
                api: this.API_GET_DESTINATIONS_TYPES,
                key: "data",
                table: this.destinationTypesArray,
                payload: new FormData()
            },
            {
                api: this.API_GET_DESTINATIONS_PROPERTIES,
                key: "data",
                table: this.originalDestinationPropertiesArray,
                payload: payload
            }
        ]);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getNumberFromCurrentURL(): number | null {
        const path = window.location.pathname;
        const segments = path.split("/");
        const lastSegment = segments.pop(); // Get the last segment
        const currentNumber = parseInt(lastSegment, 10);
        if (currentNumber === 1) {
            this.title = "Property Attributes";
        } else {
            this.title = "Navigation Attributes";
        }
        return lastSegment ? parseInt(lastSegment, 10) : null;
    }

    /**
     * Get Table Elements
     */
    getApiService(apis: { api: string; key: string; table: any, payload: FormData }[]): void {
        forkJoin(
            apis.map(({api, key, payload}) =>
                this._restFactoryService.httpPostService(api, payload, key)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        catchError(() => {
                            return of([]);
                        })
                    )
            )
        ).pipe(
            finalize(() => {
                if (this.originalDestinationPropertiesArray.length === 0) {
                    this.loadingMessage = "No results found";
                } else {
                    this.searchElements();
                }
                this.isLoading = false;
                this.spinner.hide().then();
            })
        ).subscribe((responses) => {
            responses.forEach((response, index) => {
                apis[index].table.push(...response);
                this._changeDetectorRef.markForCheck();
            });
        });
    }

    /**
     * Page changed
     */
    pageChanged(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchElements();
    }

    /**
     * Search Destinations Attributes
     */
    searchElements(searcher: boolean = false): void {
        if (searcher) {
            this.pageIndex = 0;
            this._paginator.firstPage();
        }
        const start = this.pageIndex * this.pageSize;
        const end = start + this.pageSize;

        if (this.inputSearchControl.getRawValue()) {
            this.tableDestinationPropertiesArray = this.originalDestinationPropertiesArray
                .filter(prop => prop.ds_name.toLowerCase().includes(this.inputSearchControl.getRawValue()))
                .slice(start, end);
            this.pageLength = this.tableDestinationPropertiesArray.length;
        } else {
            this.tableDestinationPropertiesArray = this.originalDestinationPropertiesArray.slice(start, end);
            this.pageLength = this.originalDestinationPropertiesArray.length;
        }
        this._changeDetectorRef.detectChanges();
    }

    booleanToString(value: boolean): string {
        return value ? "Active" : "Inactive";
    }

    processDestinationProperty(id: string = null): void {
        if (id) {
            const formData = new FormData();
            formData.append("id_destinationprop", id);
            this._restFactoryService.httpPostService(
                this.API_GET_DESTINATION_PROPERTY,
                formData,
                "data").subscribe((response) => {
                this.openDestinationAttribute(response);
            });
        } else {
            this.openDestinationAttribute({
                id_destinationprop: null,
                ds_name: null,
                ds_label: null,
                ds_dataname: null,
                bl_values: false,
                vi_order: 99,
                en_destinationproptype: null,
                ds_en_destinationproptype: null,
                ar_destinationpropdtypes: [],
                ar_destinationpropoptions: []
            });
        }
    }

    /**
     * Switch to the destination attribute
     */
    openDestinationAttribute(destinationProperty: DestinationAttributesType): void {
        this.destinationAttribute = destinationProperty;
        this.showList = !this.showList;

        this._changeDetectorRef.markForCheck();
        setTimeout(() => {
            this._changeDetectorRef.markForCheck();
        }, 1000);
        return;
    }

    /**
     * Handle Command from the child component
     */
    handleCommand(command: string) {
        // Do something with the command
        this.destinationAttribute = null;
        this.showList = !this.showList;
        this._changeDetectorRef.markForCheck();
        if (command === "search") {
            this.originalDestinationPropertiesArray = [];
            const payload = new FormData();
            payload.append("en_destinationproptype", this.getNumberFromCurrentURL().toString());
            this.getApiService([
                {
                    api: this.API_GET_DESTINATIONS_PROPERTIES,
                    key: "data",
                    table: this.originalDestinationPropertiesArray,
                    payload: payload
                }
            ]);
        }
    }
}
