import {TextFieldModule} from '@angular/cdk/text-field';
import {NgClass, NgIf} from '@angular/common';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    FormsModule,
    NgForm,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {FuseAlertComponent} from '@fuse/components/alert';
import {catchError, finalize, Subject, takeUntil} from 'rxjs';
import {OfferVoucherType} from '../destinations.types';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {FuseCardComponent} from '../../../../../@fuse/components/card';
import {QuillEditorComponent} from 'ngx-quill';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';

@Component({
    selector: 'special-offers-redeem',
    templateUrl: './special-offers-redeem.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule, NgIf, FuseAlertComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, TextFieldModule, FuseCardComponent, NgClass, QuillEditorComponent],
})
export class SpecialOffersRedeemComponent implements OnInit, OnDestroy {
    @ViewChild('supportNgForm') supportNgForm: NgForm;
    @ViewChild('flippableCard01') flippableCard01: FuseCardComponent;

    alert: any;
    voucherForm: UntypedFormGroup;
    voucher: OfferVoucherType;


    quillModules: any = {
        toolbar: false
    };


    // API URL
    private API_GET_VOUCHER = "/destinationowner/getvoucher";
    private API_VOUCHER_REDEEM = "/destinationowner/redeemvoucher";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        private _restFactoryService: RestFactoryService,
        private route: ActivatedRoute  // Inject ActivatedRoute here
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const id_redeem = this.route.snapshot.queryParamMap.get('id_redeem');

        // Create the support form
        this.voucherForm = this._formBuilder.group({
            offer_code: [null, Validators.required],
        });
        if (id_redeem) {
            this.voucherForm.get('offer_code').setValue(id_redeem);
            this.voucherForm.updateValueAndValidity();
            this.voucherForm.markAsDirty();
            this.voucherForm.markAsTouched();
            this.sendForm();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Clear the form
     */
    clearForm(): void {
        // Reset the form
        this.supportNgForm.resetForm();
    }

    /**
     * Search for the voucher
     */
    sendForm(): void {
        const payload = new FormData();
        if (this.voucherForm.get('offer_code').value.length < 6) {
            payload.append('ds_redeemid', this.voucherForm.get('offer_code').value);
        } else {
            payload.append('id_offerclaimredeem', this.voucherForm.get('offer_code').value);
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_VOUCHER, payload, "offer")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.voucher = response;
                    this.flippableCard01.face = this.flippableCard01.face === 'front' ? 'back' : 'front';
                });
    }

    flipCard(): void {
        this.flippableCard01.face = this.flippableCard01.face === 'front' ? 'back' : 'front';
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }


    /**
     * Redeem the voucher
     */
    saveAndClose(): void {
        // Prepare the payload
        const payload = new FormData();
        if (this.voucherForm.get('offer_code').value.length < 6) {
            payload.append('ds_redeemid', this.voucherForm.get('offer_code').value);
        } else {
            payload.append('id_offerclaimredeem', this.voucherForm.get('offer_code').value);
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_VOUCHER_REDEEM, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    const message = this._fuseConfirmationService.open({
                        "title": "Sucess",
                        "message": "The voucher for client: has successfully been claimed!",
                        "icon": {
                            "show": true,
                            "name": "heroicons_outline:exclamation-triangle",
                            "color": "warn"
                        },
                        "actions": {
                            "confirm": {
                                "show": false,
                                "label": "Remove",
                                "color": "warn"
                            },
                            "cancel": {
                                "show": false,
                                "label": "Cancel"
                            }
                        },
                        "dismissible": false
                    });
                    setTimeout(() => {
                        message.close();
                        this.clearForm();
                        this.voucher = null;
                        this.flippableCard01.face = this.flippableCard01.face === 'front' ? 'back' : 'front';
                    }, 2000);
                });
    }
}
