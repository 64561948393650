import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {
    FormControl,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {catchError, filter, finalize, map, Observable, startWith, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {DestinationsManagementComponent} from '../destinations-management/destinations-management.component';
import {DestinationSmallType, DestinationsStoryType, DestinationType} from '../destinations.types';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {FuseAlertComponent} from '../../../../../@fuse/components/alert';
import {FuseCardComponent} from '../../../../../@fuse/components/card';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {MatDialog} from '@angular/material/dialog';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {en_State} from '../../../../../environments/fixed-enum';

@Component({
    selector: "destinations-list",
    templateUrl: "./destinations-list.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        DestinationsManagementComponent,
        NgxSpinnerComponent,
        FuseAlertComponent,
        FuseCardComponent,
        NgSelectModule,
        MatAutocomplete,
        MatAutocompleteTrigger,
    ]
})
export class DestinationsListComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator) private _paginator: MatPaginator;

    showList: boolean = false;
    displayAddDestination: boolean = false;
    loadingMessage: string = "Loading...";
    nameSearchInputControl: UntypedFormControl = new UntypedFormControl("");
    parentSearchInputControl: UntypedFormControl = new UntypedFormControl("");
    keywordSearchInputControl: UntypedFormControl = new UntypedFormControl("");
    typeSearchInputControl: UntypedFormControl = new UntypedFormControl(-1);

    title: string = "Destinations";
    reviewMode: boolean = false;

    destination_ID: string;
    destinationType: DestinationSmallType;
    destinationTypeParent: DestinationSmallType;
    parentControl = new FormControl(null, Validators.required);

    tableElements: DestinationType[];
    destinationParentsArray: DestinationType[];
    destinationParentsArrayObserve: Observable<DestinationType[]>;

    storyTypesArray: DestinationsStoryType[] = [];

    typingTimeout: any;
    newDestinationForm: UntypedFormGroup;
    alert: any;

    searchParameters = en_State;

    // API URL
    private API_GET_DESTINATIONS = "/admin/destination/search";
    private API_REVIEW_DESTINATIONS = "/admin/destination/review/search";
    private API_PUBLISH_DESTINATIONS = "/admin/destination/publish/search";
    private API_POST_NEW_DESTINATION = "/admin/destination/add";
    private API_GET_STORY_TYPES = "/admin/list/storytypes";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _restFactoryService: RestFactoryService,
        private _matDialog: MatDialog,
        private spinner: NgxSpinnerService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.createForm();
        this.tableElements = [];


        if (window.location.pathname === '/content/destinations/list/review') {
            this.reviewMode = true;
            this.typeSearchInputControl.setValue(2);
        }
        if (window.location.pathname === '/content/destinations/list/publish') {
            this.reviewMode = true;
            this.typeSearchInputControl.setValue(3);
        }

        // Get the data
        this.searchElements(null);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onBlur(): void {
        setTimeout(() => {
            const value = this.parentControl.value;
            if (typeof value === 'string' && !this.destinationParentsArray.find(obj => obj.ds_name === value)) {
                this.parentControl.reset();
                this.parentControl.setErrors({'invalid': true});
                this.parentControl.markAsTouched();
            }
        }, 250);
    }

    displayFn(obj: any): string {
        return obj ? obj.ds_name : '';
    }

    selectClick(event: any) {
        console.log(event.option.value.id_destination);
        this.newDestinationForm.get('id_parent').setValue(event.option.value.id_destination);
        console.log(this.newDestinationForm.get('id_parent').value);
        this._changeDetectorRef.detectChanges();
    }

    onKeyup(): void {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            this.searchElements(null, true);
        }, 500);
    }

    private _filterArray(ds_name: string): any[] {
        const filterValue = ds_name.toLowerCase();

        return this.destinationParentsArray.filter(obj => obj.ds_name.toLowerCase().includes(filterValue));
    }

    /**
     * Create the form
     */
    createForm(): void {
        this.newDestinationForm = this._formBuilder.group({
            id_type: ["type", [Validators.required]],
            ds_name: [null, Validators.required],
            id_parent: [null, Validators.required],
        });
        this.newDestinationForm.get('id_type').disable();
    }

    /**
     * Open new-search-window dialog
     */
    searchElements(searcher: string, skip: boolean = false): void {
        let data = {
            "ds_name": this.nameSearchInputControl.getRawValue(),
            "ds_parentname": this.parentSearchInputControl.getRawValue(),
            "ds_keyword": this.keywordSearchInputControl.getRawValue(),
            "en_state": this.typeSearchInputControl.getRawValue(),
        };
        const urlParams = new URLSearchParams(window.location.search);
        const idType = urlParams.get('id_type'); // replace 'id_type' with the actual parameter name
        if (idType !== null) {
            data["id_destinationtype"] = idType;
        }

        let dynaAPI = this.API_GET_DESTINATIONS;

        if (window.location.pathname === '/content/destinations/list/review') {
            data["id_destinationtype"] = "";
            dynaAPI = this.API_REVIEW_DESTINATIONS;
        }

        if (window.location.pathname === '/content/destinations/list/publish') {
            data["id_destinationtype"] = "";
            dynaAPI = this.API_PUBLISH_DESTINATIONS;
        }

        const payload = this._restFactoryService.createPayloadPaginator(searcher, this._paginator, data, 20);
        this.tableElements = [];
        this.loadingMessage = null;
        this.spinner.show().then();

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(dynaAPI, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    this.spinner.hide().then();
                    this.loadingMessage = "No results found!";
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.spinner.hide().then(
                        () => {
                            this.tableElements = response.ar_results;
                            this.destinationType = response.destinationType;
                            if (this.destinationType) this.title = response.destinationType.ds_name;

                            if (window.location.pathname === '/content/destinations/list/review') {
                                this.title = "Review Destinations";
                            }
                            if (window.location.pathname === '/content/destinations/list/publish') {
                                this.title = "Publish Destinations";
                            }

                            this._paginator.length = response.vi_TotalRows;
                            if (this.tableElements.length === 0) {
                                this.loadingMessage = "No results found";
                            }
                            this.destinationTypeParent = response.destinationTypeParent;
                            this._changeDetectorRef.markForCheck();
                            if (!skip && this.destinationType) {
                                this.newDestinationForm.get('id_type').setValue(this.destinationType.id_destinationtype);
                                this.searchParent();
                            }
                        }
                    );
                });
    }

    getStoryType() {
        const data = {
            "id_destinationtype": this.destinationType.id_destinationtype
        };
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_STORY_TYPES, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    this.spinner.hide().then();
                    this.loadingMessage = "No results found!";
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.storyTypesArray = response.data;
                    this._changeDetectorRef.markForCheck();
                });
    }

    searchParent(): void {
        const data = {
            "id_destinationtype": this.destinationTypeParent.id_destinationtype
        }
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_DESTINATIONS, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    this.spinner.hide().then();
                    this.loadingMessage = "No results found!";
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.spinner.hide().then(
                        () => {
                            this.destinationParentsArray = response.ar_results;
                            this.destinationParentsArrayObserve = this.parentControl.valueChanges
                                .pipe(
                                    startWith(''),
                                    map(value => value && typeof value === 'string' ? value : value?.ds_name),
                                    map(ds_name => ds_name ? this._filterArray(ds_name) : this.destinationParentsArray.slice())
                                );

                            this.parentControl.valueChanges.pipe(
                                startWith(''),
                                map(value => value && typeof value === 'string' ? value : value?.ds_name),
                                map(ds_name => ds_name ? this._filterArray(ds_name) : this.destinationParentsArray.slice()),
                                filter(value => typeof value === 'string' && !this.destinationParentsArray.find(obj => obj.ds_name === value))
                            ).subscribe(() => this.parentControl.reset());

                            this._changeDetectorRef.markForCheck();
                            this.getStoryType();
                        }
                    );
                });
    }
    /**
     * Handle command from the child component
     */
    handleCommand(command: string) {
        // Do something with the command
        this.destination_ID = null;
        this.showList = !this.showList;
        this._changeDetectorRef.markForCheck();
        if (command) {
            this.onKeyup();
        }
    }

    /**
     * Switch to the child component
     */
    switchToDestination(dest: string): void {
        this.destination_ID = dest;
        this.displayAddDestination = false;
        this.showList = !this.showList;
        this._changeDetectorRef.detectChanges();
    }

    removeHtmlTags(str: string): string {
        return str.replace(/<[^>]*>/g, '');
    }

    createDestination(event: Event): void {
        event.preventDefault();
        const payload = new FormData();

        payload.append("ds_name", this.newDestinationForm.get('ds_name').value);
        payload.append("id_destinationtype", this.newDestinationForm.get('id_type').value);
        payload.append("id_destinationparent", this.newDestinationForm.get('id_parent').value);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_POST_NEW_DESTINATION, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.keywordSearchInputControl.setValue(this.newDestinationForm.get('ds_name').value);
                    this.switchToDestination(response.id_destination);
                });
    }
}
