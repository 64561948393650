import {Route} from '@angular/router';
import {initialDataResolver} from 'app/app.resolvers';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {
    SpecialOffersRedeemComponent
} from './modules/content/destinations/special-offers-redeem/special-offers-redeem.component';
import {
    DestinationsManagementComponent
} from './modules/content/destinations/destinations-management/destinations-management.component';
import {DestinationsListComponent} from './modules/content/destinations/destinations-list/destinations-list.component';
import {
    DestinationsAttributesListComponent
} from './modules/catalogs/destinations-attributes/destinations-attributes-list/destinations-attributes-list.component';
import {StoryTypesListComponent} from './modules/catalogs/story-types/story-types-list/story-types-list.component';
import {
    DestinationSuperTypesListComponent
} from './modules/catalogs/destination-super-types/destination-super-types-list/destination-super-types-list.component';
import {
    DestinationsTypesListComponent
} from './modules/catalogs/destinations-types/destinations-types-list/destinations-types-list.component';
import {BusinessRegisterComponent} from './modules/auth/business-register/business-register.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: "", pathMatch: "full", redirectTo: "dashboards/project"},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboards/project"},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')}
        ]
    },

    // Auth routes for guests
    {
        path: 'business_activate',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: "",
                component: BusinessRegisterComponent,
                data: { shouldReload: true, title: "Business Register", checkSim: 0 }
            },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')}
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [

            // Dashboards
            {
                path: "dashboards", children: [
                    {
                        path: "project",
                        loadChildren: () => import("app/modules/dashboards/project/project.routes")
                    },
                ]
            },
            // Content
            {
                path: "content", children: [
                    {
                        path: "destinations",
                        children: [
                            {
                                path: "management",
                                component: DestinationsManagementComponent,
                                data: { shouldReload: true }
                            },
                            {
                                path: "list",
                                children: [
                                    {
                                        path: ":id",
                                        component: DestinationsListComponent,
                                        data: { shouldReload: true }
                                    },
                                    {
                                        path: "review",
                                        component: DestinationsListComponent,
                                        data: { shouldReload: true }
                                    }
                                ],
                            },
                        ]
                    },
                ]
            },
            // Content
            {
                path: "catalogs", children: [
                    {
                        path: "properties",
                        children: [
                            {
                                path: "destinations-attributes/:id",
                                component: DestinationsAttributesListComponent,
                                data: { shouldReload: true }
                            },
                            {
                                path: "destination-types",
                                component: DestinationsTypesListComponent,
                                data: { shouldReload: true }
                            },
                            {
                                path: "story-types",
                                component: StoryTypesListComponent,
                                data: { shouldReload: true }
                            },
                            {
                                path: "destination-super-types",
                                component: DestinationSuperTypesListComponent,
                                data: { shouldReload: true }
                            },

                        ]
                    },
                ]
            },
            // Special Offers Redeem
            {
                path: "offer", children: [
                    {
                        path: "redeem",
                        component: SpecialOffersRedeemComponent,
                    },
                ]
            },



            // Pages
            {path: 'profile', children:
                    [
                        // Settings
                        {
                            path: 'settings',
                            loadChildren: () => import('app/modules/settings/settings.routes')
                        },
                    ]
            },

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/pages/error/error-404/error-404.routes')},
            {path: '**', redirectTo: '404-not-found'}
        ]
    },

    { path: "**", redirectTo: "/dashboards/project", pathMatch: "full" },
];
