import {Component} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
    ) {
        // This is for the multiple tab sync reload issue
        window.addEventListener('storage', function (event) {
            if (event.key == 'reload' && event.newValue == 'true') {
                let redirectURL = '/signed-in-redirect';
                let params = new URLSearchParams(window.location.search);
                redirectURL = params.get('redirectURL');
                if (localStorage.getItem('ACCOUNT_DETAILS') !== null) {
                    const currentUser = JSON.parse(localStorage.getItem('ACCOUNT_DETAILS')).ds_email;
                    if (currentUser === localStorage.getItem('last_user')) {
                        _router.navigateByUrl(redirectURL).then();
                    } else {
                        window.location.href = '/dashboards/project';
                    }
                } else {
                    // Here you can reload the page or fetch new data
                    window.location.reload();
                }
                localStorage.setItem('reload', 'false');
            }
        });
    }
}
