<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
     *ngIf="!showList else showComponent" [@zoomOut]="!showList" [@zoomIn]="showList && showComponent">

<!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->
        <div
            class="text-4xl font-extrabold tracking-tight">{{ title }}
        </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search by Keyword -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 pr-1"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup)="searchElements(true)"
                    [formControl]="inputSearchControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search for keyword'">
            </mat-form-field>
            <!-- Search button -->
            <button
                *ngIf="false"
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="searchElements(true)">
                <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-plus'"></mat-icon>
                <span class="ml-2 mr-1">Search</span>
            </button>
            <!-- Add button -->
            <button
                class="ml-4"
                mat-stroked-button
                [color]="'primary'"
                (click)="processDestinationProperty(null)">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <ng-container>
                <ng-container>
                    <div class="grid">
                        <!-- Header -->
                        <div
                            class="grid-destinations z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black">
                            <div class="hidden sm:block">ID</div>
                            <div>Name</div>
                            <div>Status</div>
                            <div>Actions</div>
                        </div>
                        <!-- Rows -->
                        <ng-container *ngIf="tableDestinationPropertiesArray?.length > 0; else noElements">
                            <ng-container *ngFor="let prop of tableDestinationPropertiesArray">
                                <div class="grid-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                    <!-- ID -->
                                    <div class="truncate hidden md:block">
                                        {{ prop.id_destinationprop }}
                                    </div>

                                    <!-- Name -->
                                    <div class="truncate">
                                        {{ prop.ds_name }}
                                    </div>

                                    <!-- Type -->
                                    <div class="truncate">
                                        {{ booleanToString(prop.bl_values) }}
                                    </div>

                                    <!-- Details button -->
                                    <div>
                                        <button
                                            class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                            mat-stroked-button
                                            (click)="processDestinationProperty(prop.id_destinationprop)">
                                            <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-template #noElements>
                            <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">{{
                                    loadingMessage
                                }}
                                <ngx-spinner
                                    bdColor="rgba(51,51,51,0)"
                                    color="#fff"
                                    size="medium"
                                    type="ball-clip-rotate-multiple">
                                    <p style="font-size: 20px; color: white">Loading...</p></ngx-spinner>
                            </div>
                        </ng-template>

                        <mat-paginator
                            class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                            [ngClass]="{'pointer-events-none': isLoading}"
                            (page)="pageChanged($event)"
                            [length]="pageLength"
                            [pageIndex]="pageIndex"
                            [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 100]"
                            [showFirstLastButtons]="true"></mat-paginator>
                    </div>

                </ng-container>

            </ng-container>

        </div>

    </div>

</div>

<ng-template #showComponent>
    <destinations-attribute-management (commandEvent)="handleCommand($event)" class="max-w-full overflow-hidden"
                                  [destinationPropertyFull]="destinationAttribute" [destinationTypes]="destinationTypesArray" [adminMode]="true"></destinations-attribute-management>
</ng-template>
