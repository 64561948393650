import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {catchError, finalize, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {toNumber} from 'lodash';
import {FuseAlertComponent} from "../../../../../@fuse/components/alert";
import {RestFactoryService} from "../../../../core/services/rest-factory.service";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {NgSelectModule} from '@ng-select/ng-select';
import {
    DestinationChildParentConfig,
    DestinationStoryConfig,
    DestinationTypeType
} from '../types/destinations-types.types';
import {QuillEditorComponent} from 'ngx-quill';
import {DestinationSuperTypesTypes} from '../../destination-super-types/types/destination-super-types.types';
import {StoryTypesTypes} from '../../story-types/types/story-types.types';
import {
    DestinationAttributesType,
    DestinationPropType
} from '../../destinations-attributes/types/destinations-attributes.types';
import {en_DestinationSuperType, en_DestinationType, QuillConfiguration} from '../../../../../environments/fixed-enum';

@Component({
    selector: "destinations-types-management",
    styleUrls: ["destinations-types-management.component.scss"],
    templateUrl: "./destinations-types-management.component.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    animations: fuseAnimations,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        MatTabGroup,
        MatTab,
        MatTabContent,
        FormsModule,
        MatGridList,
        MatGridTile,
        FuseAlertComponent,
        MatSelectionList,
        MatListOption,
        NgSelectModule,
        QuillEditorComponent
    ]
})
export class DestinationsTypesManagementComponent implements OnInit {

    @Output() commandEvent = new EventEmitter<string>(); // Declare the output property
    @Input() public destinationType: DestinationTypeType;
    @Input() public destinationTypes: DestinationTypeType[];
    @Input() public destinationSuperTypeArray: DestinationSuperTypesTypes[];
    @Input() public destinationStoryTypeArray: StoryTypesTypes[];
    @Input() public destinationsPropertiesArray: DestinationAttributesType[] = [];
    @Input() adminMode: boolean = false; // Declare the input property

    inputDestinationID: string = null;
    inputDependency: number = 1;
    inputOrder: number = 99;

    inputDestinationMin: number = 1;
    inputDestinationMax: number = 3;
    inputDestinationOrder: number = 99;

    inputRowEditor: number = null;

    objectSave: boolean = false;

    destinationDestinationTypeArray = en_DestinationType;

    destinationEnSuperTypeArray = en_DestinationSuperType;

    quillModules: any = QuillConfiguration;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private API_ADD_EDIT_DESTINATION_TYPE = "/admin/destinationtype/addedit";
    private API_DELETE_DESTINATIONS_TYPE = "/admin/destinationtype/delete";
    private API_ADD_EDIT_CHILD_PARENT_CONFIG = "/admin/destinationtype/childconfig/addedit";
    private API_DELETE_CHILD_PARENT_CONFIG = "/admin/destinationtype/childconfig/delete";
    private API_ADD_EDIT_STORY_CONFIG = "/admin/destinationtype/storytypes/addedit";
    private API_DELETE_STORY_CONFIG = "/admin/destinationtype/storytypes/delete";
    private API_ADD_EDIT_PROP_CONFIG = "/admin/destinationprop/dtype/addedit";
    private API_DELETE_PROP_CONFIG = "/admin/destinationprop/dtype/delete";

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _restFactoryService: RestFactoryService,
        private _fuseConfirmationService: FuseConfirmationService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // TODO: Find a way to merge it with modifyValueGeneric
    modifyValue(property: string, amount: number, limit: number = 0) {
        let newValue = toNumber(this[property]) + amount;
        if (newValue < limit) {
            newValue = limit;
        }
        this[property] = newValue;
        if (property === "inputDestinationMin" && newValue > this.inputDestinationMax) {
            this.inputDestinationMax = newValue;
        } else if (property === "inputDestinationMax" && newValue < this.inputDestinationMin) {
            this.inputDestinationMin = newValue;
        }
    }

    // TODO: Find a way to merge it with modifyValue
    modifyValueGeneric(obj: any, property: string, amount: number, limit: number = 0) {
        let newValue = toNumber(obj[property]) + amount;
        if (newValue < limit) {
            newValue = limit;
        }
        obj[property] = newValue;
        if (property === "vi_min" && newValue > obj.vi_max) {
            obj.vi_max = newValue;
        } else if (property === "vi_max" && newValue < obj.vi_min) {
            obj.vi_min = newValue;
        }
    }

    adjustMinMaxValuesGeneric(obj: any, propertyMin: string, propertyMax: string) {
        if (obj[propertyMax] < 1) {
            obj[propertyMax] = 1;
        }
        if (obj[propertyMin] < 0) {
            obj[propertyMin] = 0;
        }
        if (obj[propertyMin] > obj[propertyMax]) {
            obj[propertyMin] = toNumber(obj[propertyMax]);
        } else if (obj[propertyMax] < obj[propertyMin]) {
            obj[propertyMax] = toNumber(obj[propertyMin]);
        }
    }

    adjustMinMaxValues() {
        if (this.inputDestinationMax < 1) {
            this.inputDestinationMax = 1;
        }
        if (this.inputDestinationMin < 0) {
            this.inputDestinationMin = 0;
        }
        if (this.inputDestinationMin > this.inputDestinationMax) {
            this.inputDestinationMin = toNumber(this.inputDestinationMax);
        } else if (this.inputDestinationMax < this.inputDestinationMin) {
            this.inputDestinationMax = toNumber(this.inputDestinationMin);
        }
    }

    onKeyupAutosave() {

    }

    // Helper for HTML method to sum values
    sumValues(obj: any, property: string, amount: number) {
        obj[property] = toNumber(obj[property]) + amount;
    }

    // Helper for HTML method to check if a value is a number
    checkIfNumber(value: any): boolean {
        return isNaN(Number(value));
    }

    // To check if the destinationType is new or not
    verifyValidSave(): boolean {
        return (this.destinationType.ds_name !== null && this.destinationType.ds_name !== "") &&
            (this.destinationType.ds_label !== null && this.destinationType.ds_label !== "") &&
            (this.destinationType.id_destinationsupertype !== null && this.destinationType.id_destinationsupertype !== "");
    }

    /**
     * Delete Destination Property Type
     */
    deleteAttributeConfig(story: DestinationPropType) {
        const payload = new FormData();
        payload.append("id_destinationpropdtype", story.id_destinationpropdtype);

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_PROP_CONFIG, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.destinationType.ar_destinationpropconfig.splice(this.inputRowEditor, 1);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Add or Edit Destination Property Type
     */
    addEditAttributeConfig(attribute: DestinationPropType = null) {
        const payload = new FormData();

        payload.append("id_destinationpropdtype", attribute ? attribute.id_destinationpropdtype : "");
        payload.append("id_destinationprop", attribute ? attribute.id_destinationprop : this.inputDestinationID);
        payload.append("id_destinationtype", this.destinationType.id_destinationtype);
        payload.append("vi_min", (attribute ? attribute.vi_min : this.inputDestinationMin).toString());
        payload.append("vi_max", (attribute ? attribute.vi_max : this.inputDestinationMax).toString());
        payload.append("vi_order", (attribute ? attribute.vi_order : this.inputDestinationOrder).toString());

        // // @ts-ignore
        // for (var pair of payload.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }
        // return;

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_PROP_CONFIG, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (attribute) {
                        this.destinationType.ar_destinationpropconfig.splice(this.inputRowEditor, 1);
                    } else {
                        this.inputDestinationMin = 1;
                        this.inputDestinationMax = 3;
                        this.inputDestinationOrder = 99;
                        this.inputDestinationID = null;
                    }
                    this.destinationType.ar_destinationpropconfig.push(response.data);
                    this.destinationType.ar_destinationpropconfig.sort((a, b) => a.vi_order - b.vi_order);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Delete Destination Property Type
     */
    deleteStoryConfig(story: DestinationStoryConfig) {
        const payload = new FormData();
        payload.append("id_destinationstoryconfig", story.id_destinationstoryconfig);

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_STORY_CONFIG, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.destinationType.ar_destinationstoryconfig.splice(this.inputRowEditor, 1);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Add or Edit Destination Property Type
     */
    addEditStoryConfig(story: DestinationStoryConfig = null) {
        const payload = new FormData();

        payload.append("id_destinationtype", this.destinationType.id_destinationtype);
        payload.append("id_destinationstoryconfig", story ? story.id_destinationstoryconfig : "");
        payload.append("id_storytype", story ? story.id_storytype : this.inputDestinationID);
        payload.append("vi_min", (story ? story.vi_min : this.inputDestinationMin).toString());
        payload.append("vi_max", (story ? story.vi_max : this.inputDestinationMax).toString());
        payload.append("vi_order", (story ? story.vi_order : this.inputDestinationOrder).toString());

        // // @ts-ignore
        // for (var pair of payload.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }
        // return;

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_STORY_CONFIG, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (story) {
                        this.destinationType.ar_destinationstoryconfig.splice(this.inputRowEditor, 1);
                    } else {
                        this.inputDestinationMin = 1;
                        this.inputDestinationMax = 3;
                        this.inputDestinationOrder = 99;
                        this.inputDestinationID = null;
                    }
                    this.destinationType.ar_destinationstoryconfig.push(response.data);
                    this.destinationType.ar_destinationstoryconfig.sort((a, b) => a.vi_order - b.vi_order);
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Delete Destination Property Type
     */
    deleteConfig(config: DestinationChildParentConfig, configType: boolean): void {
        const payload = new FormData();
        payload.append("id_destinationchildconfig", config.id_destinationchildconfig);

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_CHILD_PARENT_CONFIG, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    if (configType) {
                        this.destinationType.ar_destinationparentconfig.splice(this.inputRowEditor, 1);
                    } else {
                        this.destinationType.ar_destinationchildconfig.splice(this.inputRowEditor, 1);
                    }
                    this.inputRowEditor = null;
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Add or Edit Destination Property Type
     */
    addEditConfig(config: DestinationChildParentConfig = null, parent: boolean): void {
        const payload = new FormData();
        payload.append("id_destinationtype", this.destinationType.id_destinationtype);
        payload.append("en_dependencytype", config ? config.en_dependencytype.toString() : this.inputDependency.toString());
        payload.append("vi_order", config ? config.vi_order.toString() : this.inputOrder.toString());

        if (config) {
            payload.append("id_destinationchildconfig", config.id_destinationchildconfig);
            const destinationTypeIdKey = parent ? "id_destinationtypeparent" : "id_destinationtypechild";
            payload.append(destinationTypeIdKey, config[destinationTypeIdKey.replace("parent", "")]);
        } else {
            const inputIdKey = parent ? "id_destinationtypeparent" : "id_destinationtypechild";
            const inputIdValue = parent ? this.inputDestinationID : this.inputDestinationID;
            payload.append(inputIdKey, inputIdValue);
        }

        // // @ts-ignore
        // for (var pair of payload.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }

        // Delete the product on the server
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_CHILD_PARENT_CONFIG, payload, "data")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this.commandEvent.emit("search");
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    const targetArray = parent ? this.destinationType.ar_destinationparentconfig : this.destinationType.ar_destinationchildconfig;

                    // If config is truthy, replace the item at inputRowEditor index; otherwise, push the new response
                    if (config) {
                        targetArray[this.inputRowEditor] = response;
                    } else {
                        targetArray.push(response);
                    }

                    // Sort the target array by vi_order
                    targetArray.sort((a, b) => a.vi_order - b.vi_order);

                    // Reset input fields
                    this.inputRowEditor = null;
                    this.inputDestinationID = null;
                    this.inputDependency = 1;
                    this.inputOrder = 99;

                    // Trigger change detection
                    this._changeDetectorRef.detectChanges();
                });
    }

    /**
     * Delete Destination TYPE
     */
    deleteType(): void {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: "Delete item",
            message: `Are you sure you want to remove ${this.destinationType.ds_name}?
            This action cannot be undone! Notice if you have any references to this attribute, the removal will fail!`,
            actions: {
                confirm: {
                    label: "Delete"
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {
            // If the confirm button pressed...
            if (result === "confirmed") {

                const payload = new FormData();
                payload.append("id_destinationtype", this.destinationType.id_destinationtype);

                // Delete the product on the server

                this._unsubscribeAll.next(null);
                this._restFactoryService.httpPostService(this.API_DELETE_DESTINATIONS_TYPE, payload)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        finalize(() => {
                            this.commandEvent.emit("search");
                        }),
                        catchError((err) => {
                            return err;
                        })
                    )
                    .subscribe(
                        () => {
                        });
            }
        });
    }

    /**
     * Save and close
     */
    saveType(): void {
        const payload = new FormData();
        const properties = [
            "ds_name", "ds_label", "ds_title", "ds_iconname", "ds_shortname", "ds_subtitle", "ds_summary",
            "ds_datalabel1", "ds_datalabel2", "ds_datalabel3", "ds_layout1", "ds_layout2", "ds_layout3",
            "ds_layout4", "ds_layout5", "ds_title1", "ds_title2", "ds_title3", "ds_title4", "ds_title5",
            "ds_title6", "ds_title7", "ds_title8", "ds_title9", "ds_title10", "ds_subtitle1", "ds_subtitle2",
            "ds_subtitle3", "ds_subtitle4", "ds_subtitle5", "ds_subtitle6", "ds_subtitle7", "ds_subtitle8",
            "ds_subtitle9", "ds_subtitle10", "tx_description", "id_destinationsupertype"
        ];

        if (this.destinationType.id_destinationtype) {
            payload.append("id_destinationtype", this.destinationType.id_destinationtype);
        }

        properties.forEach(prop => {
            payload.append(prop, this.destinationType[prop]);
        });
        payload.append("en_destinationsupertype", this.destinationType.en_destinationsupertype.toString());
        payload.append("en_destinationtype", this.destinationType.en_destinationtype.toString());
        payload.append("vi_order", this.destinationType.vi_order.toString());
        payload.append("bl_offers", this.destinationType.bl_offers.toString());
        payload.append("bl_mainbutton", this.destinationType.bl_mainbutton.toString());
        payload.append("bl_locationboundaries", this.destinationType.bl_locationboundaries.toString());


        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_DESTINATION_TYPE, payload, "data")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.objectSave = true;
                    this.destinationType.id_destinationtype = response.id_destinationtype;
                    this.destinationType.ds_en_destinationsupertype = response.ds_en_destinationsupertype;
                });
    }

    // A method that emits the command event
    sendCommand() {
        if (this.destinationType.id_destinationtype === null) {
            const confirmation = this._fuseConfirmationService.open({
                title: "New Type will be lost if not saved!",
                message: `Are you sure you want to go back? This action will make you lose progress!`,
                actions: {
                    confirm: {
                        label: "Go Back"
                    }
                }
            });
            // Subscribe to the confirmation dialog closed action
            confirmation.afterClosed().subscribe((result) => {
                // If the confirm button pressed...
                if (result === "confirmed") {
                    this.commandEvent.emit("search");
                }
            });
        } else {
            if (this.objectSave) {
                this.commandEvent.emit("search");
            } else {
                this.commandEvent.emit(null);
            }
        }
    }
}
