import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import {
    DestinationsManagementComponent
} from '../../modules/content/destinations/destinations-management/destinations-management.component';
import {
    DestinationsListComponent
} from '../../modules/content/destinations/destinations-list/destinations-list.component';

export class CustomReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Check if the current route's component is in the list of components to reload
        // if (curr.routeConfig && curr.routeConfig.data && this.componentsToReload.includes(curr.routeConfig.data.id)) {
        // if (curr.routeConfig && curr.routeConfig.data
        //     && (curr.routeConfig.component === DestinationsListComponent
        //         || curr.routeConfig.component === DestinationsManagementComponent)) {
        //     // If it is, return false to force a reload of the component
        //     return false;
        // }
        if (curr.routeConfig && curr.routeConfig.data && curr.routeConfig.data.shouldReload) {
            return false;
        }

        // Otherwise, reuse the route and its component
        return future.routeConfig === curr.routeConfig;
    }
}
