<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <!-- Rings -->
    <svg class="absolute inset-0 pointer-events-none"
         viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
         xmlns="http://www.w3.org/2000/svg">
        <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
            <circle r="234" cx="196" cy="23"></circle>
            <circle r="234" cx="790" cy="491"></circle>
        </g>
    </svg>
    <!-- Dots -->
    <svg class="absolute -top-16 -right-0 text-gray-700"
         viewBox="0 0 220 192" width="220" height="192" fill="none">
        <defs>
            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                     patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
            </pattern>
        </defs>
        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
    </svg>

    <!-- Content -->
    <div class="w-full h-full sm:h-[90%] sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card overflow-y-hidden">
        <mat-drawer-container class="h-full flex-auto">
            <!-- Drawer -->
            <mat-drawer
                class="w-90 dark:bg-gray-900"
                [autoFocus]="false"
                [mode]="drawerMode"
                [opened]="drawerOpened"
                #matDrawer
            >
                <div class="flex flex-col items-start border-b p-8">
                    <!-- Course title & description -->
                    <img ngSrc="../../../../assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png"
                         priority="true"
                         width="640" height="264" alt="">
                    <!-- Course category -->
                    <div
                        class="mt-7 rounded-full px-3 py-0.5 text-sm font-semibold bg-blue-100 text-blue-800 dark:bg-blue-500 dark:text-blue-50">
                        Business Activation
                    </div>
                    <div class="mt-7 text-secondary">Step-by-step guidance to activate your business profile.</div>
                </div>

                <!-- Steps -->
                <div class="px-8 py-2">
                    <ol>
                        <ng-container *ngFor="let step of formSteps; index as i">
                            <li
                                class="group relative py-6"
                                [class.current-step]="step.step === currentStep"
                            >
                                <div *ngIf="step.step === 5"
                                     class="absolute left-4 top-6 -ml-px h-full w-0.5"
                                     [ngClass]="{
                                        'bg-primary': step.step < currentStep,
                                        'bg-gray-300 dark:bg-gray-600':
                                            step.step >= currentStep,
                                    }"
                                ></div>
                                <div
                                    class="relative pointer-events-none flex items-start">
                                    <div
                                        class="bg-card flex h-8 w-8 flex-0 items-center justify-center rounded-full ring-2 ring-inset dark:bg-default"
                                        [ngClass]="{
                                        'bg-primary text-on-primary ring-transparent group-hover:bg-primary-800 dark:bg-primary':
                                            step.step < currentStep || formSteps.length - 1  === currentStep,
                                        'ring-primary':
                                            step.step === currentStep,
                                        'ring-gray-300 group-hover:ring-gray-400 dark:ring-gray-600':
                                            step.step > currentStep,
                                    }"
                                    >
                                        <!-- Check icon, show if the step is completed -->
                                        @if (step.step < currentStep || formSteps.length - 1  === currentStep) {
                                            <mat-icon
                                                class="text-current icon-size-5"
                                                [svgIcon]="'heroicons_solid:check'"
                                            ></mat-icon>
                                        }
                                        <!-- Step order, show if the step is the current step -->
                                        @if (step.step === currentStep && formSteps.length - 1  !== currentStep) {
                                            <div
                                                class="text-md font-semibold text-primary dark:text-primary-500"
                                            >
                                                {{ step.step + 1 }}
                                            </div>
                                        }
                                        <!-- Step order, show if the step is not completed -->
                                        @if (step.step > currentStep) {
                                            <div
                                                class="text-hint text-md font-semibold group-hover:text-secondary"
                                            >
                                                {{ step.step + 1 }}
                                            </div>
                                        }
                                    </div>
                                    <div class="ml-4">
                                        <div class="font-medium leading-4">
                                            {{ step.title }}
                                        </div>
                                        <div
                                            class="text-secondary mt-1.5 text-md leading-4"
                                        >
                                            {{ step.subtitle }}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ol>
                </div>
            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col overflow-hidden" [formGroup]="signUpForm">
                <mat-progress-bar
                    class="hidden h-0.5 w-full flex-0 lg:block"
                    [value]="(100 * (currentStep + 1)) / 5"
                ></mat-progress-bar>

                <!-- Main -->
                <div class="flex-auto overflow-y-auto" cdkScrollable>
                    <!-- Steps -->
                    <mat-tab-group
                        class="fuse-mat-no-header sm:h-[90%] w-160"
                        [animationDuration]="'200'"
                        #courseSteps
                    >
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="bg-card h-full sm:h-auto prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4">
                                    <h1>Welcome to Altitude Rewards!</h1>
                                    <h2>Verify Your Business to Get Started</h2>

                                    <p>Welcome aboard! We’re thrilled to have your business join the Altitude Rewards
                                        community. To complete your registration and make your profile visible to
                                        travelers, please go through the quick verification process below.</p>

                                    <p>This step is essential to create a trusted experience for both your business and
                                        our users. Once the form is submitted, our team will review your information,
                                        and you’ll be ready to connect with travelers eager to discover exceptional
                                        experiences.</p>

                                    <p>Thank you for partnering with us! If you have any questions, don’t hesitate to
                                        reach out.</p>

                                    <p><strong>The Altitude Rewards Team</strong> ✈️🌍</p>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="bg-card h-full sm:h-auto prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                >
                                    <h2>Activate Key</h2>
                                    <p>
                                        Please enter your Verification Code to continue with your business account
                                        setup. If you received an activation link, the key should already be filled
                                        in for you. Otherwise, you can manually type in the key provided in your
                                        welcome email.
                                    </p>
                                    <p><em>Note: Ensure the activation key is entered exactly as provided to avoid
                                        any errors.</em></p>
                                    <mat-form-field class="w-full mt-6">
                                        <mat-label>Activation Key</mat-label>
                                        <input id="id_activationkey" matInput
                                               maxlength="10"
                                               [formControlName]="'id_activationkey'">
                                    </mat-form-field>
                                    <mat-form-field class="w-full mt-6">
                                        <mat-label>Verification Code</mat-label>
                                        <input id="ds_reservationCode" matInput
                                               maxlength="10"
                                               [formControlName]="'ds_reservationCode'">
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="bg-card h-full sm:h-auto prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                >
                                    <h2>Business Information</h2>
                                    <p>
                                        Please review your business information to ensure accuracy. This information
                                        has been pre-filled and cannot be edited. If any details appear incorrect,
                                        please contact support to update your records.
                                    </p>
                                    <mat-form-field class="w-full mt-7">
                                        <mat-label>Business Name</mat-label>
                                        <input id="ds_business_name" matInput [formControlName]="'ds_business_name'"
                                               readonly>
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Phone Number</mat-label>
                                        <input id="ds_phone_number" matInput [formControlName]="'ds_phone_number'"
                                               readonly>
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Address</mat-label>
                                        <input id="ds_address" matInput [formControlName]="'ds_address'" readonly>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="bg-card h-full sm:h-auto prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4"
                                >
                                    <h2>Owner Information</h2>
                                    <p>
                                        Please review and complete your owner information. Some fields may already be
                                        filled, but you can update or edit any information as needed to ensure
                                        everything is accurate.
                                    </p>
                                    <!-- Alert -->
                                    <fuse-alert
                                        class="mt-2"
                                        *ngIf="showAlert"
                                        [appearance]="'outline'"
                                        [showIcon]="false"
                                        [type]="alert.type"
                                        [@shake]="alert.type === 'error'">
                                        {{ alert.message }}
                                    </fuse-alert>

                                    <mat-form-field class="w-full mt-7">
                                        <mat-label>Email</mat-label>
                                        <input id="ds_email" matInput [formControlName]="'ds_email'">
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Name</mat-label>
                                        <input id="ds_name" matInput [formControlName]="'ds_name'">
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Lastname</mat-label>
                                        <input id="ds_lastname" matInput [formControlName]="'ds_lastname'">
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Password</mat-label>
                                        <input id="ds_password" matInput [formControlName]="'ds_password'"
                                               type="password">
                                    </mat-form-field>

                                    <mat-form-field class="w-full">
                                        <mat-label>Password Verification</mat-label>
                                        <input id="ds_password_verification" matInput
                                               [formControlName]="'ds_password_verification'" type="password">
                                    </mat-form-field>

                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab>
                            <ng-template matTabContent>
                                <div
                                    class="bg-card h-full sm:h-auto prose prose-sm mx-auto max-w-3xl overflow-hidden rounded-2xl p-6 shadow sm:my-2 sm:p-10 sm:py-12 lg:mt-4">
                                    <h1>Congratulations! Your Registration is Complete 🎉</h1>

                                    <p>Thank you for joining Altitude Rewards! Your business is now part of our exclusive network, connecting you with travelers eager to discover extraordinary experiences.</p>

                                    <p>To finalize your registration, please <strong>check your email for a confirmation message</strong>. Simply click the verification link provided to activate your account. This helps us ensure a secure and trusted platform for both you and our users.</p>

                                    <p>Once verified, you’ll be ready to customize your profile and start attracting travelers to your destination!</p>

                                    <p>If you have any questions or need further assistance, don’t hesitate to reach out. We’re here to help every step of the way.</p>

                                    <p><strong>Welcome to Altitude Rewards!</strong></p>
                                    <p><strong>The Altitude Rewards Team</strong> 🌐✨</p>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>

                    <!-- Navigation - Desktop -->
                    <div class="sticky bottom-4 z-10 hidden p-4 lg:flex">
                        <button
                            *ngIf="currentStep === 4"
                            class="flex-0 mx-auto"
                            mat-flat-button
                            [color]="'primary'"
                            (click)="onNavigate()"
                        >Go to Sign In</button>
                        <div
                            *ngIf="currentStep !== 4"
                            class="mx-auto flex items-center justify-center rounded-full bg-primary p-2 shadow-lg"
                        >
                            <button
                                class="flex-0"
                                mat-flat-button
                                [color]="currentStep === 0 ? 'basic' : 'primary'"
                                [disabled]="currentStep === 0"
                                (click)="goToPreviousStep()"
                            >
                            <span class="inline-flex items-center">
                                <mat-icon
                                    class="mr-2"
                                    [svgIcon]="
                                        'heroicons_outline:arrow-long-left'
                                    "
                                ></mat-icon>
                                <span class="mr-1">Prev</span>
                            </span>
                            </button>
                            <div
                                class="mx-2.5 flex items-center justify-center font-medium leading-5 text-on-primary"
                            >
                                <span>{{ currentStep + 1 }}</span>
                                <span class="text-hint mx-0.5">/</span>
                                <span>{{ 5 }}</span>
                            </div>
                            <button
                                class="flex-0"
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="(currentStep === 1 && !signUpForm.get('ds_reservationCode').valid) || (currentStep === 3 && !signUpForm.valid)"
                                (click)="goToNextStep()"
                            >
                            <span class="inline-flex items-center">
                                <span class="ml-1">Next</span>
                                <mat-icon
                                    class="ml-2"
                                    [svgIcon]="
                                        'heroicons_outline:arrow-long-right'
                                    "
                                ></mat-icon>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Progress & Navigation - Mobile -->
                <div class="bg-card flex items-center border-t p-4 lg:hidden">
                    <button mat-icon-button (click)="matDrawer.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-4'"></mat-icon>
                    </button>
                    <div
                        class="ml-1 flex items-center justify-center font-medium leading-5 lg:ml-2"
                    >
                        <span>{{ currentStep + 1 }}</span>
                        <span class="text-hint mx-0.5">/</span>
                        <span>{{ formSteps.length }}</span>
                    </div>
                    <mat-progress-bar
                        class="ml-6 flex-auto rounded-full"
                        [value]="(100 * (currentStep + 1)) / 5"
                    ></mat-progress-bar>
                    <button
                        class="ml-4"
                        mat-icon-button
                        [disabled]="currentStep === 0"
                        (click)="goToPreviousStep()"
                    >
                        <mat-icon
                            [svgIcon]="'heroicons_outline:arrow-long-left'"
                        ></mat-icon>
                    </button>
                    <button
                        [disabled]="(currentStep === 1 && !signUpForm.get('ds_reservationCode').valid) || (currentStep === 3 && !signUpForm.valid)"
                        class="ml-0.5" mat-icon-button (click)="goToNextStep()">
                        <mat-icon
                            [svgIcon]="'heroicons_outline:arrow-long-right'"
                        ></mat-icon>
                    </button>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
