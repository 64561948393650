import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UntypedFormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class AuthService {
    private _authenticated: boolean = false;
    private api: string = environment.apiUrl;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for session ID
     */
    set sessionID(token: string) {
        localStorage.setItem("sessionID", token);
    }

    get sessionID(): string {
        return localStorage.getItem("sessionID") ?? "";
    }

    // -----------------------------------------------------------------------------------------------------
    // @ private methods
    // -----------------------------------------------------------------------------------------------------

    private buildURL(serviceUrl: string): string {
        return `${environment.protocol}//${this.api + serviceUrl}`;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Get Languages
     *
     */
    getLanguages() {
        const url = this.buildURL("/registration/languages");

        const payload = {
            id_application: "af137dde-31f7-11ed-a261-0242ac120002",
            vi_appVersionMajor: 1.0,
            vi_appVersionMinor: 1.0,
            id_session: this.sessionID,
            id_calltoken: "9f628bf8-147d-4782-9a76-98d7d1730a72",
            id_country: JSON.parse(localStorage.getItem("ACCOUNT_DETAILS")).id_country
        };

        return this._httpClient.post(url, payload).pipe(
            switchMap((response: any) => {
                // Return a new observable with the response
                localStorage.setItem("LANGUAGES", JSON.stringify(response.languages));
                return of(response.languages);
            })
        );
    }

    /**
     * Get Account Details
     *
     */
    getAccountDetails() {
        const url = this.buildURL("/account/accountdetails");

        const payload = {
            id_application: "af137dde-31f7-11ed-a261-0242ac120002",
            vi_appVersionMajor: 1.0,
            vi_appVersionMinor: 1.0,
            id_session: this.sessionID,
            id_calltoken: "9f628bf8-147d-4782-9a76-98d7d1730a72"
        };

        return this._httpClient.post(url, payload).pipe(
            switchMap((response: any) => {
                // Return a new observable with the response
                localStorage.setItem("LAST_LOGIN", String(Date.now()));
                localStorage.setItem("ACCOUNT_DETAILS", JSON.stringify(response));
                return of(response);
            })
        );
    }

    /**
     * Get Session ID
     *
     */
    postSessionID(): Observable<any> {
        const url = this.buildURL("/session/start");
        let latitude = null;
        let longitude = null;

        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         latitude = position.coords.latitude;
        //         longitude = position.coords.longitude;
        //         console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        //     }, (error) => {
        //         console.error("Error occurred while getting geolocation: ", error);
        //     });
        // } else {
        //     console.log("Geolocation is not supported by this browser.");
        // }

        const payload = {
            "id_application": "af137dde-31f7-11ed-a261-0242ac120002",
            "vi_appVersionMajor": 1.0,
            "vi_appVersionMinor": 1.0,
            "js_DeviceData": {}
        };

        return this._httpClient.post(url, payload).pipe(
            switchMap((response: any) => {
                this.sessionID = response.id_session;

                return of(true);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return of('User is already logged in.');
        }

        const url = this.buildURL("/session/authenticate");

        const payload = {
            "id_application": "af137dde-31f7-11ed-a261-0242ac120002",
            "vi_appVersionMajor": 1.0,
            "vi_appVersionMinor": 1.0,
            "id_session": this.sessionID,
            "id_calltoken": "9f628bf8-147d-4782-9a76-98d7d1730a72",
            "ds_accountid": "",
            "ds_phone": "",
            "ds_email": credentials.email,
            "ds_password": credentials.password
        };

        return this._httpClient.post(url, payload)
            .pipe(
                switchMap((response: any) => {
                        // Store the access token in the local storage
                        // this.accessToken = response.accessToken;

                        // Set the authenticated flag to true
                        if (response.ds_Status.toUpperCase() === "OK") {
                            this._authenticated = true;
                        }

                        // Store the user on the user service
                        this._userService.user = response.user;

                        // Return a new observable with the response
                        return of(response);
                    }
                )
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.clear();
        sessionStorage.clear();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        const last_Login = Number(localStorage.getItem("LAST_LOGIN"));
        const account_details = localStorage.getItem("ACCOUNT_DETAILS");

        // TEMP USER CHECK
        if (last_Login && account_details) {
            if (Date.now() - last_Login > 86400000) {
                localStorage.clear();
                sessionStorage.clear();
                return of(false);
            }
            return of(true);
        }

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    convertToFormData(formGroup: UntypedFormGroup): FormData {
        const formData = new FormData();
        const formValue = formGroup.value;

        for (const key in formValue) {
            if (formValue.hasOwnProperty(key)) {
                formData.append(key, formValue[key]);
            }
        }

        return formData;
    }

    /**
     * Get hashtag
     *
     */
    appendGeneralPayload(payload: FormData) {
        payload.append("id_application", "af137dde-31f7-11ed-a261-0242ac120002");
        payload.append("vi_appVersionMinor", "1");
        payload.append("vi_appVersionMajor", "1");
        payload.append("id_session", this.sessionID);
        payload.append("id_calltoken", "9f628bf8-147d-4782-9a76-98d7d1730a72");

        return payload;
    }

    /**
     * HTTP SERVICE
     *
     */
    httpPostService(URL: string, payload: FormData, key?: string) {
        return this._httpClient.post(this.buildURL(URL), this.appendGeneralPayload(payload)).pipe(
            switchMap((response: any) => {
                // Return a new observable with the response
                if (key) {
                    return of(response[key]);
                } else {
                    return of(response);
                }
            })
        );
    }
}
