<div class="flex flex-col flex-auto min-w-0">

    <!-- Main -->
    <div class="flex flex-col flex-auto items-center p-6 sm:p-10">
        <div class="flex flex-col w-full max-w-xl">
            <div class="text-2xl sm:text-7xl font-extrabold tracking-tight leading-tight">
                Redeem Offers
            </div>
            <fuse-card
                [ngClass]="{'h-[850px]': flippableCard01.face !== 'front'}"
                class="mt-8 p-6 pb-7 sm:p-10 sm:pb-7 rounded-2xl bg-card"
                [flippable]="true"
                [face]="'front'"
                #flippableCard01="fuseCard">
                <ng-container fuseCardFront>
                    <!-- Alert -->
                    <fuse-alert
                        class="mb-8"
                        *ngIf="alert"
                        [type]="alert.type"
                        [showIcon]="false">
                        {{ alert.message }}
                    </fuse-alert>
                    <form
                        class="space-y-3"
                        [formGroup]="voucherForm"
                        #supportNgForm="ngForm">
                        <div class="mb-6">
                            <div class="text-2xl font-bold tracking-tight">Submit the Offer Short Code</div>
                            <div class="text-secondary">Short code</div>
                        </div>
                        <!-- Name -->
                        <mat-form-field class="w-full">
                            <input
                                matInput
                                [formControlName]="'offer_code'"
                                [required]="true">
                            <mat-label>Code</mat-label>
                            <mat-error *ngIf="voucherForm.get('offer_code').hasError('required')">
                                Required
                            </mat-error>
                        </mat-form-field>
                        <!-- Actions -->
                        <div class="flex items-center justify-end">
                            <button
                                class="text-red-600"
                                mat-stroked-button
                                [color]="'accent'"
                                (click)="clearForm()">
                                Clear
                            </button>
                            <button
                                class="ml-2"
                                mat-stroked-button
                                [color]="'primary'"
                                [disabled]="voucherForm.pristine || voucherForm.invalid"
                                (click)="sendForm()">
                                Submit
                            </button>
                        </div>
                    </form>
                </ng-container>

                <ng-container fuseCardBack>
                    <div class="flex flex-col flex-auto m-4">
                        <div class="flex items-center justify-center" *ngIf="voucher">
                            <div class="flex flex-col gap-1 w-full">
                                <div class="relative w-full max-w-xl h-auto py-4">
                                    <img class="object-cover rounded-xl w-full h-auto"
                                         [src]="voucher.im_media"
                                         alt="Special Offer">
                                </div>
                                <div class="relative w-full max-w-xl h-auto py-4">
                                    <img class="object-cover rounded-xl w-full h-auto"
                                         [src]="voucher.im_voucher"
                                         alt="Special Offer">
                                </div>
                                <div class="flex flex-col py-4">
                                    <mat-form-field class="w-full">
                                        <mat-label>Property</mat-label>
                                        <input
                                            readonly
                                            matInput
                                            [(ngModel)]="voucher.parent.ds_title">
                                    </mat-form-field>
                                    <mat-form-field class="w-full">
                                        <mat-label>Offer</mat-label>
                                        <input
                                            readonly
                                            matInput
                                            [(ngModel)]="voucher.ds_offer">
                                    </mat-form-field>
                                    <mat-form-field class="w-full">
                                        <mat-label>Member Name</mat-label>
                                        <input
                                            readonly
                                            matInput
                                            [(ngModel)]="voucher.ds_member">
                                    </mat-form-field>

                                    <div class="w-full max-h-fit">
                                        <!-- Description -->
                                        <mat-label>Description</mat-label>
                                        <!-- Body -->
                                        <quill-editor
                                            [readOnly]="true"
                                            class="mt-2 w-full"
                                            [(ngModel)]="voucher.tx_description"
                                            [bounds]="'self'"
                                            [modules]="quillModules"></quill-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 rounded-2xl flex justify-between sticky bottom-0 bg-gray-50 dark:bg-black dark:bg-opacity-85">
                        <!-- Discard -->
                        <button
                            class="sm:ml-0"
                            mat-stroked-button
                            (click)="flipCard()">
                            Cancel
                        </button>

                        <!-- Send -->
                        <button
                            class="order-last sm:order-last"
                            mat-stroked-button
                            [color]="'primary'"
                            (click)="saveAndClose()">
                            Redeem
                        </button>
                    </div>
                </ng-container>
            </fuse-card>
        </div>
    </div>
</div>
