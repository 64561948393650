import {ActivatedRoute} from '@angular/router';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl} from "@angular/forms";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {catchError, finalize, Observable, of, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {CommonModule, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs';
import {QuillEditorComponent} from 'ngx-quill';
import {environment} from '../../../../../environments/environment';
import {DestinationsStoryType, DestinationType, GalleryType, ParentType, PropertiesType} from '../destinations.types';
import {FuseScrollbarDirective} from '../../../../../@fuse/directives/scrollbar';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {ImagePreloadDirective} from '../../../../core/directives/image-preload.directive';
import {MatChipListbox, MatChipOption} from '@angular/material/chips';
import {FuseAlertComponent} from '../../../../../@fuse/components/alert';
import {GalleryComponent, GalleryModule, ImageItem} from 'ng-gallery';
import {SpecialOffersListComponent} from '../special-offers-list/special-offers-list.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {SearchParentWindowComponent} from './search-parent-window/search-parent-window.component';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {CityTypes} from '../../../auth/auth.types';
import '../../../../core/quill-options/gold-text';
import {QuillConfiguration} from '../../../../../environments/fixed-enum';
import {GoogleMapsModule, MapAdvancedMarker} from '@angular/google-maps';
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";

// declare var google: google.maps;


@Component({
    selector: "destinations-management",
    templateUrl: "./destinations-management.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatDatepickerModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        MatTabGroup,
        MatTab,
        MatTabContent,
        FormsModule,
        QuillEditorComponent,
        NgOptimizedImage,
        FuseScrollbarDirective,
        ImagePreloadDirective,
        MatChipListbox,
        MatChipOption,
        FuseAlertComponent,
        GalleryModule,
        SpecialOffersListComponent,
        MatAutocomplete,
        GoogleMapsModule,  // Import GoogleMapsModule
        MapAdvancedMarker,
        MatAutocompleteTrigger,
        NgxSpinnerComponent
    ]
})
export class DestinationsManagementComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() commandEvent = new EventEmitter<string>(); // Declare the output property
    @Input() destination_ID: any; // Declare the input property
    @Input() adminMode: boolean = false; // Declare the input property
    @Input() reviewMode: boolean = false; // Declare the input property
    @Input() storyTypesArray: DestinationsStoryType[] = []; // Declare the input property

    // A method that emits the command event
    sendCommand(command: string) {
        this.commandEvent.emit(command);
    }

    @ViewChild('videoElement') videoElement: ElementRef;
    @ViewChild('galleryRef') gallery: GalleryComponent;

    // Parent search variables

    @ViewChild('autoCompleteTrigger') autoCompleteTrigger: MatAutocompleteTrigger;
    init: boolean = true;
    selectedParent: string;
    parentArray: CityTypes[] = [];
    parentControl = new FormControl(null);
    filteredParents: Observable<CityTypes[]>;

    parentCheckerRequired: boolean = true;


    commentInputControl: UntypedFormControl = new UntypedFormControl("");

    typingTimeout: any;
    googleAddressSearch: string;
    selectedStoryID: string = null;

    destination: DestinationType;

    // flashMessage: "success" | "error" | null = null;

    index = 0;
    disableSave: boolean = false;

    // quillModules = quillModulesStandard;

    quillModules: any = QuillConfiguration;

    disableAutoSave: boolean = true;

    // quillModules: any = {
    //     toolbar: {
    //         container: [
    //             [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, false] }],
    //             ['bold', 'italic', 'underline'],
    //             ['goldText'],
    //             ['image', 'code-block']
    //         ],
    //         handlers: {
    //             'goldText': function() {
    //                 this.quill.format('goldText', true);
    //             }
    //         }
    //     },
    //     // Register custom formats
    //     formats: ['goldText']
    // };

    galleryImages = [];
    loadGallery: boolean = false;
    showVideoDelete: boolean = false;
    cancelingReview: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private API_OWNER_GET_DESTINATION = "/destinationowner/get";
    private API_POST_DESTINATION = "/destinationowner/set";
    private API_POST_MAIN_IMAGE = "/admin/destination/destinationmainimageadd";
    private API_POST_MAIN_VIDEO = "/admin/destination/destinationmainvideoadd";
    private API_DELETE_MAIN_VIDEO = "/admin/destination/destinationmainvideoremove";
    private API_UNPUBLISH_DESTINATION = "/admin/destination/destinationunpublish";
    private API_POST_MAIN_CARD_IMAGE = "/admin/destination/destinationcardimageadd";
    private API_POST_GALLERY_IMAGE = "/admin/destination/galleryadd";
    private API_DELETE_GALLERY_IMAGE = "/admin/destination/galleryremove";
    private API_POST_PRODUCT = "/admin/destination/productadd";
    private API_POST_STORY = "/admin/destination/storyadd";
    private API_POST_PRODUCT_IMAGE = "/admin/destination/productimageadd";
    private API_POST_STORY_IMAGE = "/admin/destination/storymainimageadd";
    private API_VERIFY_GOOGLE_ADDRESS = "/admin/destination/checklocation";
    private API_GET_DESTINATION = "/admin/destination/search";
    private API_CHANGE_PARENT = "/admin/destination/setparent";
    private API_SEND_TO_REVIEW = "/admin/destination/sendtoreview";

    private API_REVIEW_GET = "/admin/destination/review/get";
    private API_REVIEW_CANCEL = "/admin/destination/review/cancel";
    private API_REVIEW_REJECT = "/admin/destination/review/reject";
    private API_REVIEW_APPROVE = "/admin/destination/review/approve";

    private API_PUBLISH_GET = "/admin/destination/publish/get";
    private API_PUBLISH_CANCEL = "/admin/destination/publish/cancel";
    private API_PUBLISH_REJECT = "/admin/destination/publish/reject";
    private API_PUBLISH_APPROVE = "/admin/destination/publish/publish";

    private API_GET_STORY_TYPES = "/admin/list/storytypes";

    /**
     * Constructor
     */
    constructor(
        private _fuseConfirmationService: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private el: ElementRef,
        private renderer: Renderer2,
        private _restFactoryService: RestFactoryService,
        private route: ActivatedRoute,
        private _matDialog: MatDialog,
        private _snackBar: MatSnackBar,
        private spinner: NgxSpinnerService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (!this.adminMode) {
            this.route.paramMap.subscribe((params: { get: (arg0: string) => any; }) => {
                const id_destination = params.get('id_destination');
                if (id_destination) {
                    this.destination_ID = id_destination;
                }
            });
        }
        this.initializeDestination();
        this.dataInitializer();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        // Unsubscribe from all subscriptions
        if (this.reviewMode && !this.cancelingReview) {
            this.cancelingReview = true;
            this.cancelReview();
            this._unsubscribeAll.next(null);
            this._unsubscribeAll.complete();
        }
    }

    ngAfterViewInit(): void {
        // if (!this.map && this.mapElement && this.mapElement.nativeElement) {
        // }
        setTimeout(() => {
            this.disableAutoSave = false;
        }, 3000);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Map Stuff
    // -----------------------------------------------------------------------------------------------------

    loadMapVar: boolean = false;
    mapCenter: any;
    markersList: any[] = [];
    centralMarker: any;
    firstMarker: any;
    secondMarker: any;
    thirdMarker: any;
    fourthMarker: any;

    async mapAngularInit() {
        if (this.destination.vr_long && this.destination.vr_lat) {
            this.mapCenter = {lat: this.destination.vr_lat, lng: this.destination.vr_long};
        }

        const {
            PinElement
        } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        this.centralMarker = {
            position: {lat: this.destination.vr_lat, lng: this.destination.vr_long},
            content: new PinElement({
                background: '#FF0000',
            }).element,
            gmpDraggable: !this.reviewMode,
            draggable: !this.reviewMode,
            title: "Central",
            icon: "C"
        };
        this.markersList.push(this.centralMarker);

        if (this.destination.bl_locationboundaries) {
            this.firstMarker = {
                position: {lat: this.destination.vr_northwestlat, lng: this.destination.vr_northwestlong},
                content: new PinElement({
                    background: '#0000FF',
                }).element,
                gmpDraggable: !this.reviewMode,
                draggable: !this.reviewMode,
                title: "First",
                icon: "F"
            };
            this.secondMarker = {
                position: {lat: this.destination.vr_northeasttlat, lng: this.destination.vr_northeastlong},
                content: new PinElement({
                    background: '#00FF00',
                }).element,
                gmpDraggable: !this.reviewMode,
                draggable: !this.reviewMode,
                title: "Second",
                icon: "S"
            };
            this.thirdMarker = {
                position: {lat: this.destination.vr_southwestlat, lng: this.destination.vr_southwestlong},
                content: new PinElement({
                    background: '#800000',
                }).element,
                gmpDraggable: !this.reviewMode,
                draggable: !this.reviewMode,
                title: "Third",
                icon: "T"
            };
            this.fourthMarker = {
                position: {lat: this.destination.vr_southeastlat, lng: this.destination.vr_southeastlong},
                content: new PinElement({
                    background: '#808080',
                }).element,
                gmpDraggable: !this.reviewMode,
                draggable: !this.reviewMode,
                title: "Fourth",
                icon: "F"
            }
            this.markersList.push(this.firstMarker);
            this.markersList.push(this.secondMarker);
            this.markersList.push(this.thirdMarker);
            this.markersList.push(this.fourthMarker);
        }

        this.loadMapVar = true;

        this._changeDetectorRef.detectChanges();
    }

    clickGoogleMap(event: any) {
        if (this.reviewMode) {
            return;
        }
        this.centralMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
        this.destination.vr_lat = event.latLng.lat();
        this.destination.vr_long = event.latLng.lng();

        this._changeDetectorRef.detectChanges();
        this.saveAndClose();
    }

    onMarkerDragEnd(event: any, marker: any) {
        if (this.reviewMode) {
            return;
        }
        if (marker.title === "Central") {
            this.centralMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this.destination.vr_lat = event.latLng.lat();
            this.destination.vr_long = event.latLng.lng();
        }
        if (marker.title === "First") {
            this.firstMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this.destination.vr_northwestlat = event.latLng.lat();
            this.destination.vr_northwestlong = event.latLng.lng();
        }
        if (marker.title === "Second") {
            this.secondMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this.destination.vr_northeasttlat = event.latLng.lat();
            this.destination.vr_northeastlong = event.latLng.lng();
        }
        if (marker.title === "Third") {
            this.thirdMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this.destination.vr_southwestlat = event.latLng.lat();
            this.destination.vr_southwestlong = event.latLng.lng();
        }
        if (marker.title === "Fourth") {
            this.fourthMarker.position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
            this.destination.vr_southeastlat = event.latLng.lat();
            this.destination.vr_southeastlong = event.latLng.lng();
        }
        this._changeDetectorRef.detectChanges();
        this.saveAndClose();
    }

    loadMap(event: any) {
        if (event.index === 2) {
            this.mapAngularInit().then();
        }
    }

    verifyGoogleAddress() {
        const payload = new FormData();

        payload.append("ds_address", this.googleAddressSearch);
        payload.append("id_destination", this.destination.id_destination);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_VERIFY_GOOGLE_ADDRESS, payload, "destination")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (response.google_sugested) {
                        this.destination.ds_googlematch = response.google_sugested;
                        this.destination.ds_googleaddress = response.formatted_address;
                        this.destination.vr_lat = response.location_lat;
                        this.destination.vr_long = response.location_long;
                        this.centralMarker.position = {lat: response.location_lat, lng: response.location_long};
                        this.destination.vr_northwestlat = response.northeast_lat;
                        this.destination.vr_northwestlong = response.southwest_long;
                        this.firstMarker.position = {lat: response.northeast_lat, lng: response.southwest_long};
                        this.destination.vr_northeasttlat = response.northeast_lat;
                        this.destination.vr_northeastlong = response.northeast_long;
                        this.secondMarker.position = {lat: response.northeast_lat, lng: response.northeast_long};
                        this.destination.vr_southwestlat = response.southwest_lat;
                        this.destination.vr_southwestlong = response.southwest_long;
                        this.thirdMarker.position = {lat: response.southwest_lat, lng: response.southwest_long};
                        this.destination.vr_southeastlat = response.southwest_lat;
                        this.destination.vr_southeastlong = response.northeast_long;
                        this.fourthMarker.position = {lat: response.southwest_lat, lng: response.northeast_long};
                        this._changeDetectorRef.markForCheck();
                        this.saveAndClose();
                    } else {
                        const message = this._fuseConfirmationService.open({
                            title: "Error!",
                            message: "No address found. Please try again.",
                            actions: {
                                confirm: {
                                    show: false
                                },
                                cancel: {
                                    show: false
                                }
                            },
                            dismissible: false
                        });
                        setTimeout(() => {
                            message.close();
                        }, 5000);
                    }
                });
    }

    onKeyup(event: any, value: number, obj: any, objKey: string, toEditLat: boolean): void {
        if (event) event.preventDefault();
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            this.destination[objKey] = Number(value);
            if (toEditLat) {
                obj.position.lat = Number(value);
            } else {
                obj.position.lng = Number(value);
            }
            this._changeDetectorRef.detectChanges();
            this.saveAndClose();
        }, 500);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public Methods
    // -----------------------------------------------------------------------------------------------------

    onKeyupAutosave(): void {
        if (this.disableAutoSave) {
            return;
        }
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            this.saveAndClose();
        }, 500);
    }

    featuresAutosave(prop: any): void {
        prop.bl_Active = !prop.bl_Active;
        this.saveAndClose();
    }

    featuresAutosaveUnique(propArray: any, selectedProp: any): void {
        // Select the clicked option
        // If the option is already active, return
        if (selectedProp.bl_Active) {
            return;
        }

        // Deselect all other options
        for (let prop of propArray) {
            prop.bl_Active = false;
        }

        // Select the clicked option
        selectedProp.bl_Active = true;
        this.saveAndClose();
    }

    initializeParentType(): ParentType {
        return {
            id_destinationtype: '',
            ds_destinationtype: '',
            en_destinationtype: 0,
            ds_en_destinationtype: '',
            ds_friendlyurl: '',
            ds_friendlyurlfull: '',
            id_destination: '',
            ds_name: '',
            ds_shortname: '',
            ds_label: ''
        };
    }

    initializeDestination(): void {
        this.destination = {
            bl_locationboundaries: false,
            bl_offers: false,
            ds_targeturl: '',
            ar_gallery: [],
            ar_products: [],
            ar_props: [],
            ar_stories: [],
            ds_client: '',
            ds_countryiso2: '',
            ds_countryiso3: '',
            ds_countryname: '',
            ds_destinationtype: '',
            ds_en_destinationtype: '',
            ds_en_state: '',
            ds_friendlyurl: '',
            ds_friendlyurlfull: '',
            ds_googleaddress: '',
            ds_googlematch: '',
            ds_iatacitycode: '',
            ds_label: '',
            ds_languageietftag: '',
            ds_languageiso2: '',
            ds_layoutdestination: '',
            ds_name: '',
            ds_regioniso3: '',
            ds_regionname: '',
            ds_shortname: '',
            ds_subtitle: '',
            ds_summary: '',
            ds_title: '',
            en_destinationtype: 0,
            en_state: 0,
            ggparent: this.initializeParentType(),
            gparent: this.initializeParentType(),
            id_client: '',
            id_country: '',
            id_destination: '',
            id_destinationtype: '',
            id_displaylayout: '',
            id_language: '',
            id_region: '',
            im_media: '',
            im_mediacard: '',
            im_mediamap: '',
            im_mediavideo: '',
            parent: this.initializeParentType(),
            tx_description: '',
            vr_lat: 0,
            vr_long: 0,
            vr_northeastlong: 0,
            vr_northeasttlat: 0,
            vr_northwestlat: 0,
            vr_northwestlong: 0,
            vr_rating: 0,
            vr_southeastlat: 0,
            vr_southeastlong: 0,
            vr_southwestlat: 0,
            vr_southwestlong: 0,
        };
    }

    /**
     * Initialize missing data in the object...
     */
    dataInitializer() {
        const payload = new FormData();
        const urlParams = new URLSearchParams(window.location.search);
        const idDestination = urlParams.get('id_destination'); // replace 'id_type' with the actual parameter name

        if (idDestination !== null) {
            this.destination_ID = idDestination;
        }
        if (this.destination_ID) {
            payload.append("id_destination", this.destination_ID);
        }

        let dynaAPI = this.API_OWNER_GET_DESTINATION;

        if (window.location.pathname === '/content/destinations/list/review') {
            dynaAPI = this.API_REVIEW_GET;
        }
        if (window.location.pathname === '/content/destinations/list/publish') {
            dynaAPI = this.API_PUBLISH_GET;
        }

        console.log(dynaAPI);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(dynaAPI, payload, "destination")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.destination = response;
                    this.destination_ID = response.id_destination;
                    if (this.destination.ar_gallery.length > 0) {
                        for (let i = 0; i < this.destination.ar_gallery.length; i++) {
                            this.galleryImages.push(new ImageItem({
                                src: this.destination.ar_gallery[i].im_media,
                                thumb: this.destination.ar_gallery[i].im_media
                            }));
                        }
                    } else {
                        this.galleryImages.push(new ImageItem({
                            src: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png",
                            thumb: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png"
                        }));
                    }
                    this.loadGallery = true;
                    this.parentCheckerRequired = !!this.destination.parent;
                    this._changeDetectorRef.markForCheck();
                    if (this.init) {
                        this.parentControl.setValue(this.destination.parent?.ds_name);
                        this.searchParent();
                    }
                    this.getStoryType();
                });
    }

    onFileInputClick(FileUpload: string) {
        const fileInput = this.el.nativeElement.querySelector('#FileUpload' + FileUpload);
        this.renderer.selectRootElement(fileInput).click();
    }

    setValueByPath(obj: any, path: string, value: any): void {
        const parts = path.split('.');
        let current = obj;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];

            if (i === parts.length - 1) {
                // If this is the last part, set the value
                if (part.includes('[')) {
                    // Extract the property name and index from the string
                    const [property, index] = part.split(/[\[\]]/).filter(Boolean);
                    current[property][parseInt(index)] = value;
                } else {
                    current[part] = value;
                }
            } else {
                // If this is not the last part, navigate to the next part
                if (part.includes('[')) {
                    // Extract the property name and index from the string
                    const [property, index] = part.split(/[\[\]]/).filter(Boolean);
                    current = current[property][parseInt(index)];
                } else {
                    current = current[part];
                }
            }

            if (current === undefined) {
                throw new Error(`Path ${path} does not exist in the object`);
            }
        }
    }

    initializeGalleryType(im_media: string, id_destinationelement = null, id_media = null): GalleryType {
        return {
            id_media: id_media,
            id_destinationelement: id_destinationelement,
            im_media: im_media,
            en_elementtype: 0,
            ds_en_elementtype: '',
            ds_name: '',
            ds_shortname: '',
            vi_order: 0
        };
    }

    removeProduct(tab: any, j: number): void {
        this._fuseConfirmationService.open({
            title: "Warning!",
            message: "This item will be deleted!",
            actions: {
                confirm: {
                    show: true
                },
                cancel: {
                    show: true
                }
            },
            dismissible: true
        }).afterClosed().subscribe((result) => {
            if (result === "confirmed") {
                tab.ar_items.splice(j, 1);
                this.saveAndClose();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    addGalleryElement(event: any): void {
        const inpF = event.target as HTMLInputElement;
        const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp"];
        const fileExtension = (inpF.files[0] as any).name.toLowerCase().split('.').pop();

        if (allowedExtensions.includes(fileExtension)) {

            const payload = new FormData();

            const file = inpF.files[0];

            payload.append("im_image", file);

            if (this.destination_ID) {
                payload.append("id_destination", this.destination_ID);
            }

            this.spinner.show('managementSpinner').then();
            this._unsubscribeAll.next(null);
            this._restFactoryService.httpPostService(this.API_POST_GALLERY_IMAGE, payload)
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    finalize(() => {
                        this.spinner.hide('managementSpinner').then();
                        this._changeDetectorRef.detectChanges();
                    }),
                    catchError((err) => {
                        return err;
                    })
                )
                .subscribe(
                    (response) => {
                        const urlCreator = window.URL || window.webkitURL;
                        // const newElementUrl = 'https://media.myaltituderewards.com/content/destination/' + response.id_destinationelement + '.jpg';
                        const newElement = this.initializeGalleryType(response.im_media, response.id_destinationelement, response.id_media);
                        if (this.destination.ar_gallery.length === 0) {
                            if (this.index !== 0) {
                                this.index--;
                            }
                            this.destination.ar_gallery.splice(this.index, 1);
                            this.gallery.remove(this.index);
                            this.galleryImages.splice(this.index, 1);
                        }
                        this.destination.ar_gallery.push(newElement);
                        this.gallery.add(new ImageItem({
                            src: newElement.im_media,
                            thumb: newElement.im_media
                        }));
                        this.galleryImages.push(new ImageItem({
                            src: newElement.im_media,
                            thumb: newElement.im_media
                        }));
                        this.gallery.set(this.destination.ar_gallery.length - 1);
                        this._changeDetectorRef.markForCheck();
                    });
        } else {
            const message = this._fuseConfirmationService.open({
                title: "Error!",
                message: "Invalid image format. Please upload a .jpg, .png, .jpeg, .gif, or .webp file.",
                actions: {
                    confirm: {
                        show: false
                    },
                    cancel: {
                        show: false
                    }
                },
                dismissible: false
            });
            setTimeout(() => {
                message.close();
            }, 2000);
        }
    }

    /**
     * upload File Manager
     */
    onChangeFile(event: any, property: string, source: string, id: string = null): void {

        const inpF = event.target as HTMLInputElement;
        const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp", "mp4", "mov"];
        const fileExtension = (inpF.files[0] as any).name.toLowerCase().split('.').pop();

        if (allowedExtensions.includes(fileExtension)) {
            const file = inpF.files[0];

            // if (source === "video") {
            //     return this.mediaUploader(property, source, id, file);
            // }

            return this.mediaUploader(property, source, id, file);

            // Create a new Image object
            // let img = new Image();

            // // When the image has loaded, check the aspect ratio
            // img.onload = () => {
            //     const width = img.width;
            //     const height = img.height;
            //
            //     // Check if the aspect ratio is 4:3
            //     const deviation = 0.15; // Allow deviation of up to 5%
            //     const lowerBound = ratio1 / ratio2 * (1 - deviation);
            //     const upperBound = ratio1 / ratio2 * (1 + deviation);
            //     const imageRatio = width / height;
            //
            //     return this.mediaUploader(property, source, id, file);
            //
            //     if (imageRatio >= lowerBound && imageRatio <= upperBound) {
            //         return this.mediaUploader(property, source, id, file);
            //     } else {
            //         // The image does not have a 4:3 aspect ratio, show an error message
            //         const errorFormatMessage = `The image must be in a ${ratio1}:${ratio2} format. Please upload an image with a width that is ${(ratio1 / ratio2).toFixed(2)} times its height.`;
            //         const message = this._fuseConfirmationService.open({
            //             title: "Error!",
            //             message: errorFormatMessage,
            //             actions: {
            //                 confirm: {
            //                     show: false
            //                 },
            //                 cancel: {
            //                     show: false
            //                 }
            //             },
            //             dismissible: false
            //         });
            //         setTimeout(() => {
            //             message.close();
            //         }, 2000);
            //     }
            // };
            // // Start loading the image
            // img.src = imageUrl;
        } else {
            let messageErr = "Invalid image format. Please upload a .jpg, .png, .jpeg, .gif, or .webp file.";
            if (source === "video") {
                messageErr = "Invalid video format. Please upload a .mp4 or .mov file.";
            }
            const message = this._fuseConfirmationService.open({
                title: "Error!",
                message: messageErr,
                actions: {
                    confirm: {
                        show: false
                    },
                    cancel: {
                        show: false
                    }
                },
                dismissible: false
            });
            setTimeout(() => {
                message.close();
            }, 2000);
        }
    }

    mediaUploader(property: string, source: string, id: string = null, file: any): void {
        const payload = new FormData();

        let api = "";
        if (source === "main") {
            api = this.API_POST_MAIN_IMAGE;
        } else if (source === "card") {
            api = this.API_POST_MAIN_CARD_IMAGE;
        } else if (source === "video") {
            api = this.API_POST_MAIN_VIDEO;
        } else if (source === "gallery") {
            api = this.API_POST_GALLERY_IMAGE;
        } else if (source === "product") {
            api = this.API_POST_PRODUCT_IMAGE;
            payload.append("id_destinationproduct", id);
        } else if (source === "story") {
            api = this.API_POST_STORY_IMAGE;
            payload.append("id_destinationstory", id);
        }

        payload.append("im_image", file);

        if (this.destination_ID) {
            payload.append("id_destination", this.destination_ID);
        }


        this.spinner.show('managementSpinner').then();
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(api, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                    this.spinner.hide('managementSpinner').then();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    const urlCreator = window.URL || window.webkitURL;
                    this.destination[property] = response.im_media;
                    this.setValueByPath(this.destination, property, response.im_media);
                    if (property === "im_mediavideo") {
                        this.showVideoDelete = true;
                    }
                    this._changeDetectorRef.markForCheck();
                });
    }

    countSelectedProps(properties: PropertiesType): number {
        return properties.ar_items.filter(prop => prop.bl_Active === true).length;
    }

    /**
     * Save and close
     */
    saveAndClose(navigate = false): void {

        // return;
        const payload = new FormData();

        payload.append("js_destinationdata", JSON.stringify({destination: this.destination}));

        if (this.destination_ID) {
            payload.append("id_destination", this.destination_ID);
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_POST_DESTINATION, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    if (navigate) {
                        this.onNavigate();
                    }
                });
    }

    onNavigate() {
        window.open(environment.previewURL + this.destination.ds_targeturl, "_blank");
    }

    alertToggle(item: PropertiesType) {
        return this.countSelectedProps(item) < item.vi_min_destinationpropdtype || this.countSelectedProps(item) > item.vi_max_destinationpropdtype;
    }

    /**
     * Remove Gallery Image
     */
    removeGalleryImage(index: number): void {
        const payload = new FormData();

        payload.append("id_element", this.destination.ar_gallery[index].id_destinationelement);

        if (this.destination_ID) {
            payload.append("id_destination", this.destination_ID);
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_GALLERY_IMAGE, payload, "image")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.gallery.remove(index);
                    if (this.index !== 0) {
                        this.index--;
                    }
                    this.destination.ar_gallery.splice(index, 1);
                    this.galleryImages.splice(index, 1);
                    if (this.destination.ar_gallery.length === 0) {
                        this.index = 1;
                        this.gallery.add(new ImageItem({
                            src: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png",
                            thumb: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png"
                        }));
                        this.galleryImages.push(new ImageItem({
                            src: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png",
                            thumb: "https://property.myaltituderewards.com/assets/images/altitude%20rewards/logo/ar-logo-no-slogan-1.png"
                        }));
                    }
                    this._changeDetectorRef.detectChanges();
                });
    }

    extractUUID(url: string): string | null {
        const regex = /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
        const match = url.match(regex);
        return match ? match[0] : null;
    }

    /**
     * Remove Gallery Image
     */
    setImageToBanner(index: number, type: string): void {
        const payload = new FormData();

        // payload.append("id_media", this.destination.ar_gallery[index].id_destinationelement);
        payload.append("id_media", this.extractUUID(this.destination.ar_gallery[index].id_media));

        if (this.destination_ID) {
            payload.append("id_destination", this.destination_ID);
        }
        let api = "";
        if (type === "card") {
            api = this.API_POST_MAIN_CARD_IMAGE;
        }
        if (type === "main") {
            api = this.API_POST_MAIN_IMAGE;
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(api, payload, "image")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    if (type === "card") {
                        this.destination.im_mediacard = this.destination.ar_gallery[index].im_media;
                    }
                    if (type === "main") {
                        this.destination.im_media = this.destination.ar_gallery[index].im_media;
                    }
                    this._changeDetectorRef.detectChanges();
                });
    }

    addProduct(product: any, call: number): void {
        const payload = new FormData();
        let api: string;
        if (call === 0) {
            api = this.API_POST_PRODUCT;
            payload.append("id_producttype", product.id_producttype);
        } else {
            api = this.API_POST_STORY;
            payload.append("id_storytype", this.selectedStoryID);
        }
        payload.append("id_destination", this.destination_ID ? this.destination_ID : this.destination.id_destination);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(api, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    if (call === 0) {
                        product.ar_items.push(response.product);
                    } else {
                        this.destination.ar_stories.push(response.story);
                    }
                    this._changeDetectorRef.markForCheck();
                });
    }

    deleteVideo() {
        const payload = new FormData();

        payload.append("id_destination", this.destination.id_destination);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_DELETE_MAIN_VIDEO, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.showVideoDelete = false;
                    this.destination.im_mediavideo = null;
                    this._changeDetectorRef.markForCheck();
                });
    }

    togglePublish() {
        const payload = new FormData();

        payload.append("id_destination", this.destination.id_destination);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_UNPUBLISH_DESTINATION, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe();
    }

    isVideoUrl(url: string) {
        // if (this.showVideoDelete) {
        //     return true;
        // }
        if (url) {
            if (url.endsWith('undefined')) {
                return false;
            }
            return ['.mp4', '.avi', '.flv', '.mov', '.wmv', '.mkv'].some(ext => url.toLowerCase().endsWith(ext));
        }
        return false;
    }

    onInputMaxReach(event: any, maxReach: number = 15): void {
        const inputValue = event.target.value;
        if (inputValue.length >= 25) {
            // The input value has reached or exceeded the limit
            // You can take appropriate action here
            this._snackBar.open(`This field has a limit of ${maxReach} characters. Any text exceeding this limit will be cut off.`, "Close", {
                duration: 5000,  // Duration is in milliseconds (5000ms = 5s),
                panelClass: ['bg-card', 'dark:bg-transparent']
            });
        }
    }

    moreInfoTooltip(line1: string, line2: string, imageUrl: string) {
        // noinspection AngularNgOptimizedImage
        const message = this._fuseConfirmationService.open({
            title: "More Information",
            icon: {
                show: false
            },
            message: `${line1}<br>${line2}<br><img src="${imageUrl}" alt="Image description" style="width: 250px; height: 100px;">`,
            actions: {
                confirm: {
                    show: false
                },
                cancel: {
                    show: false
                }
            },
            dismissible: true
        });
        setTimeout(() => {
            message.close();
        }, 10000);
    }

    searchParentWindow() {
        if (!this.adminMode) {
            return;
        }
        const dialogRef = this._matDialog.open(SearchParentWindowComponent);
        dialogRef.componentInstance.parent = this.destination.parent?.ds_name;
        dialogRef.componentInstance.id_destination = this.destination.id_destination;
        dialogRef.afterClosed().subscribe(result => {
            if (result === "success") {
                this.dataInitializer();
            }
        });
    }

    // Parent search functions

    // noinspection DuplicatedCode
    onBlur(): void {
        setTimeout(() => {
            const value = this.parentControl.value;
            if (typeof value === 'string' && !this.parentArray.find(city => city.ds_name === value)) {
                this.parentControl.reset();
                this.parentControl.setErrors({'invalid': true});
                this.parentControl.markAsTouched();
            }
            if (!value) {
                this.parentControl.reset();
                this.parentControl.setErrors({'invalid': true});
                this.parentControl.markAsTouched();
            }
        }, 500);
    }

    displayFn(city: CityTypes): string {
        return city ? city.ds_name : '';
    }

    countryClick(event: any) {
        this.selectedParent = event.option.value.id_destination;
        if (this.selectedParent !== this.destination.parent?.id_destination) {
            const payload = new FormData();

            payload.append("id_destination", this.destination.id_destination);
            payload.append("id_destinationparent", this.selectedParent);

            this._unsubscribeAll.next(null);
            this._restFactoryService.httpPostService(this.API_CHANGE_PARENT, payload)
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    finalize(() => {
                        this._changeDetectorRef.detectChanges();
                    }),
                    catchError((err) => {
                        return err;
                    })
                )
                .subscribe(
                    () => {
                        this.dataInitializer();
                    }
                );
        }
    }


    onKeyupAutoSearch(event: any): void {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            if (event.target.value.length > 2) {
                this.searchParent(event.target.value);
            }
        }, 500);
    }

    searchParent(searchTerm: string = null): void {
        const data = {
            "ds_name": searchTerm ? searchTerm : this.parentControl?.value,
        }
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_DESTINATION, payload, "ar_results")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.filteredParents = of(response);
                    if (this.init) {
                        this.init = false;
                        // this.autoCompleteTrigger.writeValue(this.parent);
                    }
                    if (response.find((city: any) => city.ds_name === this.destination.parent?.ds_name)) {
                        this.parentControl.setValue(response.find((city: any) => city.ds_name === this.destination.parent?.ds_name));
                    }
                    if (response.length === 0) {
                        this.parentControl.setErrors({'invalid': true});
                        this.parentControl.markAsTouched();
                    }
                    this._changeDetectorRef.detectChanges();
                });

    }

    reviewAction(action: string) {
        const payload = new FormData();

        payload.append("id_destination", this.destination.id_destination);
        payload.append("ds_comment", this.commentInputControl.value);

        let dynaAPI = "";

        if (window.location.pathname === '/content/destinations/list/review') {
            if (action === "approve") {
                dynaAPI = this.API_REVIEW_APPROVE;
            } else {
                dynaAPI = this.API_REVIEW_REJECT;
            }
        }
        if (window.location.pathname === '/content/destinations/list/publish') {
            if (action === "approve") {
                dynaAPI = this.API_PUBLISH_APPROVE;
            } else {
                dynaAPI = this.API_PUBLISH_REJECT;
            }
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(dynaAPI, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this._fuseConfirmationService.open({
                        title: "Destination " + (action === "approve" ? "Approval" : "Rejection"),
                        message: `The destination ${this.destination.ds_name} has been ` + (action === "approve" ? " approved." : " rejected."),
                        actions: {
                            confirm: {
                                show: true
                            },
                            cancel: {
                                show: false
                            }
                        },
                        dismissible: false
                    }).afterClosed().subscribe(() => {
                        this.cancelingReview = true;
                        this.sendCommand("search");
                    });
                }
            );
    }

    sendToReview() {
        const payload = new FormData();

        payload.append("id_destination", this.destination.id_destination);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_SEND_TO_REVIEW, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {

                    this._fuseConfirmationService.open({
                        title: "Awaiting for Approval",
                        message: `The destination ${this.destination.ds_name} has been sent for review.`,
                        actions: {
                            confirm: {
                                show: true
                            },
                            cancel: {
                                show: false
                            }
                        },
                        dismissible: false
                    }).afterClosed().subscribe(() => {
                        this.cancelingReview = true;
                        this.sendCommand("search");
                    });
                }
            );
    }

    cancelReview() {
        const payload = new FormData();

        payload.append("id_destination", this.destination.id_destination);

        let dynaAPI = "";

        if (window.location.pathname === '/content/destinations/list/review') {
            dynaAPI = this.API_REVIEW_CANCEL;
        }
        if (window.location.pathname === '/content/destinations/list/publish') {
            dynaAPI = this.API_PUBLISH_CANCEL;
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(dynaAPI, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.cancelingReview = true;
                    this.sendCommand("search");
                }
            );
    }



    getStoryType() {
        const data = {
            "id_destinationtype": this.destination.id_destinationtype
        };
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        if (this.storyTypesArray.length > 0) {
            return;
        }

        this._restFactoryService.httpPostService(this.API_GET_STORY_TYPES, payload)
            .pipe(
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    // this.spinner.hide().then();
                    // this.loadingMessage = "No results found!";
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.storyTypesArray = response.data;
                    this._changeDetectorRef.markForCheck();
                });
    }

}
