import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, finalize, forkJoin, of, Subject, switchMap, takeUntil} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class RestFactoryService {

    private api: string = environment.apiUrl;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient
    ) {
    }

    private buildURL(serviceUrl: string): string {
        return `${environment.protocol}//${this.api + serviceUrl}`;
    }

    /**
     * Setter & getter for session ID
     */
    set sessionID(token: string) {
        localStorage.setItem("sessionID", token);
    }

    get sessionID(): string {
        return localStorage.getItem("sessionID") ?? "";
    }

    getApiService(
        apis: { api: string; key: string; table: any; last: boolean }[],
        _unsubscribeAll: Subject<any>,
        spinner: NgxSpinnerService = null
    ): void {
        forkJoin(
            apis.map(({api, key}) =>
                this.httpPostService(api, new FormData(), key)
                    .pipe(
                        takeUntil(_unsubscribeAll),
                        catchError(() => {
                            return of([]);
                        })
                    )
            )
        ).pipe(
            finalize(() => {
                if (spinner) {
                    spinner.hide().then();
                }
            })
        ).subscribe((responses) => {
            responses.forEach((response, index) => {
                apis[index].table.push(...response);
                if (apis[index].last) {
                    return true;
                    // You may need to handle the last API call in the component
                }
            });
        });
    }


    createPayloadPaginator(searcher: string, _paginator: any, data: { [key: string]: any }, pageSize: number = 1000): FormData {
        const payload = new FormData();
        if (searcher === "paginator") {
            payload.append("vi_page", _paginator.pageIndex);
            payload.append("vi_pagesize", _paginator.pageSize);
        } else if (searcher === "searcher") {
            payload.append("vi_page", String(_paginator.pageIndex));
            payload.append("vi_pagesize", String(_paginator.pageSize));
        } else {
            if (_paginator) {
                _paginator.pageIndex = 0;
                _paginator.pageSize = 20;
                _paginator.length = 0;
            }
            payload.append("vi_page", "0");
            payload.append("vi_pagesize", String(pageSize));
        }

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                payload.append(key, data[key]);
            }
        }
        return payload;
    }

    /**
     * Payload Append for general data
     *
     */
    appendGeneralPayload(payload: FormData) {
        payload.append("id_application", "af137dde-31f7-11ed-a261-0242ac120002");
        payload.append("vi_appVersionMinor", "1");
        payload.append("vi_appVersionMajor", "1");
        payload.append("id_session", this.sessionID);
        payload.append("id_calltoken", "9f628bf8-147d-4782-9a76-98d7d1730a72");

        return payload;
    }

    /**
     * HTTP SERVICE
     *
     */
    httpPostService(URL: string, payload: FormData, key?: string) {
        if (!payload) {
            payload = new FormData();
        }
        return this._httpClient.post(this.buildURL(URL), this.appendGeneralPayload(payload)).pipe(
            switchMap((response: any) => {
                // Return a new observable with the response
                if (key) {
                    return of(response[key]);
                } else {
                    return of(response);
                }
            })
        );
    }


}
