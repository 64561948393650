<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
     @zoomIn>
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight">
            {{ destinationType.ds_name ? destinationType.ds_name : "New Attribute" }}
        </div>


        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Go Back -->
            <button
                *ngIf="adminMode"
                class="ml-4"
                mat-stroked-button
                (click)="sendCommand()">
                Back
            </button>
            <!-- Delete -->
            <button
                *ngIf="destinationType.id_destinationtype"
                class="ml-4"
                color="warn"
                mat-stroked-button
                (click)="deleteType()">
                Delete
            </button>
            <!-- Save -->
            <div
                [matTooltipDisabled]="verifyValidSave()"
                [matTooltip]="'Destinations and Attributes are empty!'">
                <button
                    [disabled]="!verifyValidSave()"
                    class="ml-4"
                    mat-stroked-button
                    [color]="'primary'"
                    (click)="saveType()">
                    Save
                </button>
            </div>
        </div>
    </div>

    <mat-tab-group class="pt-2 md:px-2 full-height-tabs" [animationDuration]="'0ms'"
                   (selectedTabChange)="inputRowEditor = null">

        <mat-tab label="Destination Type Settings">
            <div class="pb-26 pr-4 max-h-fit">
                <div class="pb-4" *ngIf="!destinationType.id_destinationtype">
                    <mat-label class="text-yellow-600 text-2xl">Save the type to unlock the other tabs!</mat-label>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Name{{ destinationType.ds_name ? "" : "*" }}</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_name"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Label{{ destinationType.ds_label ? "" : "*" }}</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_label"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Title</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_title"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Icon Name</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_iconname"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Shortname</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            maxlength="15"
                            [(ngModel)]="destinationType.ds_shortname"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Subtitle</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Summary</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_summary"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Data Label 1</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_datalabel1"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Data Label 2</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_datalabel2"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Data Label 3</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_datalabel3"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Layout 1</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_layout1"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Layout 2</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_layout2"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Layout 3</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_layout3"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Layout 4</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_layout4"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Layout 5</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_layout5"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-5 hide-required-marker">
                        <mat-label class="flex"><p>Internal Destination
                            Type{{ destinationType.en_destinationtype ? "" : "*" }}</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="Choose the Property Type"></mat-icon>
                        </mat-label>
                        <mat-select placeholder="Select an option"
                                    required [(ngModel)]="destinationType.en_destinationtype">
                            <mat-option *ngFor="let prop of destinationDestinationTypeArray"
                                        [value]="prop.value">
                                {{ prop.view }}
                            </mat-option>
                        </mat-select>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-1/3 pr-5 hide-required-marker">
                        <mat-label class="flex"><p>Destination Super
                            Type{{ destinationType.id_destinationsupertype ? "" : "*" }}</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="Choose the Property Type"></mat-icon>
                        </mat-label>
                        <mat-select placeholder="Select an option"
                                    required [(ngModel)]="destinationType.id_destinationsupertype">
                            <mat-option *ngFor="let prop of destinationSuperTypeArray"
                                        [value]="prop.id_destinationsupertype">
                                {{ prop.ds_name }}
                            </mat-option>
                        </mat-select>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-full lg:w-1/3 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Internal Super Destination Type</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            readonly
                            [(ngModel)]="destinationType.ds_en_destinationsupertype"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field [subscriptSizing]="'dynamic'"
                                    class="w-full lg:w-1/6 pr-2 d-flex justify-content-center">
                        <mat-label class="flex pr-5"><p>Order:</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="The Order To Appear"></mat-icon>
                        </mat-label>
                        <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                  (click)="destinationType.vi_order = destinationType.vi_order - 1"></mat-icon>
                        <!--suppress TypeScriptUnresolvedReference -->
                        <input matInput [(ngModel)]="destinationType.vi_order" type="number"
                               (focus)="$event.target.select()"
                               class="no-arrows text-center"
                               (change)="destinationType.vi_order = checkIfNumber(destinationType.vi_order) || destinationType.vi_order === null ? 99 : destinationType.vi_order">
                        <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                  (click)="destinationType.vi_order = destinationType.vi_order + 1"></mat-icon>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field [subscriptSizing]="'dynamic'"
                                    class="w-full lg:w-1/6 pr-2 d-flex justify-content-center">
                        <mat-label class="flex pr-5"><p>Has Offers:</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="Toggle for attribute"></mat-icon>
                        </mat-label>
                        <mat-slide-toggle
                            [(ngModel)]="destinationType.bl_offers">{{ destinationType.bl_offers ? 'Active' : 'Inactive' }}
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field [subscriptSizing]="'dynamic'"
                                    class="w-full lg:w-1/6 pr-2 d-flex justify-content-center">
                        <mat-label class="flex pr-5"><p>Has Main Button:</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="Toggle for attribute"></mat-icon>
                        </mat-label>
                        <mat-slide-toggle
                            [(ngModel)]="destinationType.bl_mainbutton">{{ destinationType.bl_mainbutton ? 'Active' : 'Inactive' }}
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field [subscriptSizing]="'dynamic'"
                                    class="w-full lg:w-1/6 pr-2 d-flex justify-content-center">
                        <mat-label class="flex pr-5"><p>Has Location Boundaries:</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="Toggle for attribute"></mat-icon>
                        </mat-label>
                        <mat-slide-toggle
                            [(ngModel)]="destinationType.bl_locationboundaries">{{ destinationType.bl_locationboundaries ? 'Active' : 'Inactive' }}
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>

                <div class="w-full max-h-fit lg:w-2/3 pt-4">
                    <!-- Description -->
                    <mat-label class="flex"><p>Description</p>
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-1"
                                  matTooltip="This text appears in the body of the member portal."></mat-icon>
                    </mat-label>
                    <!-- Body -->
                    <quill-editor
                        (onContentChanged)="onKeyupAutosave()"
                        class="mt-2 w-full custom-quill"
                        [(ngModel)]="destinationType.tx_description"
                        [bounds]="'self'"
                        [modules]="quillModules"></quill-editor>
                </div>

            </div>
        </mat-tab>

        <mat-tab label="Destination Parent Config {{destinationType.ar_destinationparentconfig.length > 0 ? '' : '!'}}"
                 [disabled]="!destinationType.id_destinationtype">
            <div class="pb-26 max-h-fit">
                <div
                    class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
                    <div class="flex flex-auto overflow-hidden">
                        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                            <div class="grid">
                                <!-- Header -->
                                <div
                                    class="grid-destinations z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-black bg-primary dark:bg-opacity-100">
                                    <div>Destination</div>
                                    <div>Dependency</div>
                                    <div>Order</div>
                                    <div class="hidden sm:block">Actions</div>
                                </div>
                                <!-- Rows -->
                                <ng-container
                                    *ngFor="let item of destinationType.ar_destinationparentconfig; let i = index">
                                    <div
                                        class="grid-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- ID -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            *ngIf="inputRowEditor === i; else displayModeDestination"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'Parent Destination'"
                                                            [(ngModel)]="item.id_destinationtype">
                                                    <mat-option [value]="option.id_destinationtype"
                                                                *ngFor="let option of destinationTypes">{{ option.ds_name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <ng-template #displayModeDestination>
                                                {{ item.ds_destinationtype }}
                                            </ng-template>
                                        </div>

                                        <!-- ID -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            *ngIf="inputRowEditor === i; else displayModeDependency"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'Dependency'"
                                                            [(ngModel)]="item.en_dependencytype">
                                                    <mat-option [value]="option.value"
                                                                *ngFor="let option of destinationDestinationTypeArray">{{ option.view }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <ng-template #displayModeDependency>
                                                {{ item.ds_en_dependencytype }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeOrder">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="sumValues(item,'vi_order', -1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_order" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="item.vi_order">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="sumValues(item,'vi_order', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeOrder>
                                                {{ item.vi_order }}
                                            </ng-template>
                                        </div>

                                        <!-- Details button -->
                                        <div>
                                            <button
                                                [hidden]="inputRowEditor === i"
                                                (click)="inputRowEditor === i ? inputRowEditor = null : inputRowEditor = i"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="deleteConfig(item, true)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2 mr-4"
                                                color="warn"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="addEditConfig(item, true)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2"
                                                color="primary"
                                                mat-flat-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Add Rows -->
                                <ng-container>
                                    <div
                                        class="grid-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- Destination -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'" class="w-4/5">
                                                <mat-select [placeholder]="'Parent Destination'"
                                                            [(ngModel)]="inputDestinationID">
                                                    <mat-option [value]="option.id_destinationtype"
                                                                *ngFor="let option of destinationTypes">{{ option.ds_name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>

                                        <!-- Max -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'" class="w-4/5">
                                                <mat-select [placeholder]="'Dependency'"
                                                            [(ngModel)]="inputDependency">
                                                    <mat-option [value]="option.value"
                                                                *ngFor="let option of destinationDestinationTypeArray">{{ option.view }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- Order -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="inputOrder = inputOrder - 1"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputOrder" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="inputOrder = checkIfNumber(inputOrder) || inputOrder === null ? 99 : inputOrder">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="inputOrder = inputOrder + 1"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Action -->
                                        <div>
                                            <button
                                                [disabled]="inputDestinationID === null"
                                                (click)="addEditConfig(null, true)"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Destination Child Config {{destinationType.ar_destinationchildconfig.length > 0 ? '' : '!'}}"
                 [disabled]="!destinationType.id_destinationtype">
            <div class="pb-26 max-h-fit">
                <div
                    class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
                    <div class="flex flex-auto overflow-hidden">
                        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                            <div class="grid">
                                <!-- Header -->
                                <div
                                    class="grid-destinations z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-black bg-primary dark:bg-opacity-100">
                                    <div>Destination</div>
                                    <div>Dependency</div>
                                    <div>Order</div>
                                    <div class="hidden sm:block">Actions</div>
                                </div>
                                <!-- Rows -->
                                <ng-container
                                    *ngFor="let item of destinationType.ar_destinationchildconfig; let i = index">
                                    <div
                                        class="grid-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- ID -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            *ngIf="inputRowEditor === i; else displayModeDestination"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'Child Destination'"
                                                            [(ngModel)]="item.id_destinationtypechild">
                                                    <mat-option [value]="option.id_destinationtype"
                                                                *ngFor="let option of destinationTypes">{{ option.ds_name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <ng-template #displayModeDestination>
                                                {{ item.ds_destinationtypechild }}
                                            </ng-template>
                                        </div>

                                        <!-- ID -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            *ngIf="inputRowEditor === i; else displayModeDependency"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'Dependency'"
                                                            [(ngModel)]="item.en_dependencytype">
                                                    <mat-option [value]="option.value"
                                                                *ngFor="let option of destinationDestinationTypeArray">{{ option.view }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <ng-template #displayModeDependency>
                                                {{ item.ds_en_dependencytype }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeOrder">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="sumValues(item,'vi_order', -1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_order" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="item.vi_order">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="sumValues(item,'vi_order', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeOrder>
                                                {{ item.vi_order }}
                                            </ng-template>
                                        </div>

                                        <!-- Details button -->
                                        <div>
                                            <button
                                                [hidden]="inputRowEditor === i"
                                                (click)="inputRowEditor === i ? inputRowEditor = null : inputRowEditor = i"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="deleteConfig(item, false)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2 mr-4"
                                                color="warn"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="addEditConfig(item, false)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2"
                                                color="primary"
                                                mat-flat-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Add Rows -->
                                <ng-container>
                                    <div
                                        class="grid-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- Destination -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'" class="w-4/5">
                                                <mat-select [placeholder]="'Child Destination'"
                                                            [(ngModel)]="inputDestinationID">
                                                    <mat-option [value]="option.id_destinationtype"
                                                                *ngFor="let option of destinationTypes">{{ option.ds_name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>

                                        <!-- Max -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'" class="w-4/5">
                                                <mat-select [placeholder]="'Dependency'"
                                                            [(ngModel)]="inputDependency">
                                                    <mat-option [value]="option.value"
                                                                *ngFor="let option of destinationDestinationTypeArray">{{ option.view }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- Order -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="inputOrder = inputOrder - 1"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputOrder" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="inputOrder = checkIfNumber(inputOrder) || inputOrder === null ? 99 : inputOrder">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="inputOrder = inputOrder + 1"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Action -->
                                        <div>
                                            <button
                                                [disabled]="inputDestinationID === null"
                                                (click)="addEditConfig(null, false)"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Story Types Config {{destinationType.ar_destinationstoryconfig.length > 0 ? '' : '!'}}"
                 [disabled]="!destinationType.id_destinationtype">
            <div class="pb-26 max-h-fit">
                <div
                    class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
                    <div class="flex flex-auto overflow-hidden">
                        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                            <div class="grid">
                                <!-- Header -->
                                <div
                                    class="grid-stories z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-black bg-primary dark:bg-opacity-100">
                                    <div>Story</div>
                                    <div>Min</div>
                                    <div>Max</div>
                                    <div>Order</div>
                                    <div class="hidden sm:block">Actions</div>
                                </div>
                                <!-- Rows -->
                                <ng-container
                                    *ngFor="let item of destinationType.ar_destinationstoryconfig; let i = index">
                                    <div
                                        class="grid-stories grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- ID -->
                                        <div class="truncate">
                                            {{ item.ds_storytype }}
                                        </div>

                                        <!-- Name -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeMin">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="modifyValueGeneric(item,'vi_min', -1)"></mat-icon>
                                                    <input matInput [(ngModel)]="item.vi_min" type="number"
                                                           class="no-arrows text-center"
                                                           (change)="adjustMinMaxValuesGeneric(item, 'vi_min', 'vi_max')">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="modifyValueGeneric(item, 'vi_min', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeMin>
                                                {{ item.vi_min }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeMax">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="modifyValueGeneric(item,'vi_max', -1, 1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_max" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="adjustMinMaxValuesGeneric(item, 'vi_min', 'vi_max')">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="modifyValueGeneric(item, 'vi_max', 1,1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeMax>
                                                {{ item.vi_max }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeOrder">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="sumValues(item,'vi_order', -1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_order" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="item.vi_order">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="sumValues(item,'vi_order', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeOrder>
                                                {{ item.vi_order }}
                                            </ng-template>
                                        </div>

                                        <!-- Details button -->
                                        <div>
                                            <button
                                                [hidden]="inputRowEditor === i"
                                                (click)="inputRowEditor === i ? inputRowEditor = null : inputRowEditor = i"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="deleteStoryConfig(item)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2 mr-4"
                                                color="warn"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="addEditStoryConfig(item)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2"
                                                color="primary"
                                                mat-flat-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Add Rows -->
                                <ng-container>
                                    <div
                                        class="grid-stories grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- Destination -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'New Story'"
                                                            [(ngModel)]="inputDestinationID">
                                                    <mat-option [value]="item.id_storytype"
                                                                *ngFor="let item of destinationStoryTypeArray">{{ item.ds_label }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- Min -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="modifyValue('inputDestinationMin', -1)"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationMin" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="adjustMinMaxValues()">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="modifyValue('inputDestinationMin', 1)"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Max -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="modifyValue('inputDestinationMax', -1, 1)"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationMax" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center" min="1"
                                                       (change)="adjustMinMaxValues()">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="modifyValue('inputDestinationMax', 1, 1)"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Order -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="inputDestinationOrder = inputDestinationOrder - 1"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationOrder" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="inputDestinationOrder = checkIfNumber(inputDestinationOrder) || inputDestinationOrder === null ? 99 : inputDestinationOrder">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="inputDestinationOrder = inputDestinationOrder + 1"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Action -->
                                        <div>
                                            <button
                                                [disabled]="inputDestinationID === null"
                                                (click)="addEditStoryConfig()"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Destinations Attributes {{destinationType.ar_destinationpropconfig.length > 0 ? '' : '!'}}"
                 [disabled]="!destinationType.id_destinationtype">
            <div class="pb-26 max-h-fit">
                <div
                    class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
                    <div class="flex flex-auto overflow-hidden">
                        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                            <div class="grid">
                                <!-- Header -->
                                <div
                                    class="grid-stories z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-black bg-primary dark:bg-opacity-100">
                                    <div>Property</div>
                                    <div>Min</div>
                                    <div>Max</div>
                                    <div>Order</div>
                                    <div class="hidden sm:block">Actions</div>
                                </div>
                                <!-- Rows -->
                                <ng-container
                                    *ngFor="let item of destinationType.ar_destinationpropconfig; let i = index">
                                    <div
                                        class="grid-stories grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- ID -->
                                        <div class="truncate">
                                            {{  item.ds_destinationprop }}
                                        </div>

                                        <!-- Name -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeMin">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="modifyValueGeneric(item,'vi_min', -1)"></mat-icon>
                                                    <input matInput [(ngModel)]="item.vi_min" type="number"
                                                           class="no-arrows text-center"
                                                           (change)="adjustMinMaxValuesGeneric(item, 'vi_min', 'vi_max')">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="modifyValueGeneric(item, 'vi_min', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeMin>
                                                {{ item.vi_min }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeMax">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="modifyValueGeneric(item,'vi_max', -1, 1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_max" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="adjustMinMaxValuesGeneric(item, 'vi_min', 'vi_max')">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="modifyValueGeneric(item, 'vi_max', 1,1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeMax>
                                                {{ item.vi_max }}
                                            </ng-template>
                                        </div>

                                        <!-- Type -->
                                        <div class="">
                                            <div *ngIf="inputRowEditor === i; else displayModeOrder">
                                                <mat-form-field [subscriptSizing]="'dynamic'"
                                                                class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                    <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                              (click)="sumValues(item,'vi_order', -1)"></mat-icon>
                                                    <!--suppress TypeScriptUnresolvedReference -->
                                                    <input matInput [(ngModel)]="item.vi_order" type="number"
                                                           (focus)="$event.target.select()"
                                                           class="no-arrows text-center"
                                                           (change)="item.vi_order">
                                                    <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                              (click)="sumValues(item,'vi_order', 1)"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <ng-template #displayModeOrder>
                                                {{ item.vi_order }}
                                            </ng-template>
                                        </div>

                                        <!-- Details button -->
                                        <div>
                                            <button
                                                [hidden]="inputRowEditor === i"
                                                (click)="inputRowEditor === i ? inputRowEditor = null : inputRowEditor = i"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="deleteAttributeConfig(item)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2 mr-4"
                                                color="warn"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                            </button>
                                            <button
                                                [hidden]="inputRowEditor !== i"
                                                (click)="addEditAttributeConfig(item)"
                                                class="min-w-10 min-h-7 h-7 leading-6 px-2"
                                                color="primary"
                                                mat-flat-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Add Rows -->
                                <ng-container>
                                    <div
                                        class="grid-stories grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                                        <!-- Destination -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-4/5">
                                                <mat-select [placeholder]="'Attributes'"
                                                            [(ngModel)]="inputDestinationID">
                                                    <mat-option [value]="item.id_destinationprop"
                                                                *ngFor="let item of destinationsPropertiesArray">{{ item.ds_name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- Min -->
                                        <div class="truncate">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex align-items-center justify-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="modifyValue('inputDestinationMin', -1)"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationMin" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="adjustMinMaxValues()">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="modifyValue('inputDestinationMin', 1)"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Max -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="modifyValue('inputDestinationMax', -1, 1)"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationMax" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center" min="1"
                                                       (change)="adjustMinMaxValues()">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="modifyValue('inputDestinationMax', 1, 1)"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Order -->
                                        <div class="">
                                            <mat-form-field [subscriptSizing]="'dynamic'"
                                                            class="w-2/3 pr-2 d-flex justify-content-center">
                                                <mat-icon matPrefix svgIcon="heroicons_solid:minus-circle"
                                                          (click)="inputDestinationOrder = inputDestinationOrder - 1"></mat-icon>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input matInput [(ngModel)]="inputDestinationOrder" type="number"
                                                       (focus)="$event.target.select()"
                                                       class="no-arrows text-center"
                                                       (change)="inputDestinationOrder = checkIfNumber(inputDestinationOrder) || inputDestinationOrder === null ? 99 : inputDestinationOrder">
                                                <mat-icon matSuffix svgIcon="heroicons_solid:plus-circle"
                                                          (click)="inputDestinationOrder = inputDestinationOrder + 1"></mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- Action -->
                                        <div>
                                            <button
                                                [disabled]="inputDestinationID === null"
                                                (click)="addEditAttributeConfig()"
                                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                mat-stroked-button>
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Destinations Section Labels" [disabled]="!destinationType.id_destinationtype">
            <div class="pb-26 pr-4 max-h-fit">

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 1</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_title1"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 1</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle1"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 2</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title2"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 2</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle2"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 3</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title3"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 3</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle3"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 4</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title4"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 4</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle4"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 5</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title5"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 5</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle5"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 6</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title6"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 6</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle6"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 7</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title7"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 7</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle7"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 8</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title8"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 8</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle8"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 9</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title9"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 9</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle9"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>

                <div class="flex w-full lg:w-3/4">
                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Title 10</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            required
                            [(ngModel)]="destinationType.ds_title10"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>

                    <!-- Name -->
                    <mat-form-field class="w-full lg:w-3/4 pr-2 hide-required-marker">
                        <mat-label class="flex"><p>Section Subtitle 10</p>
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="pb-2"
                                      matTooltip="???"></mat-icon>
                        </mat-label>
                        <input
                            [(ngModel)]="destinationType.ds_subtitle10"
                            matInput>
                        <mat-error>You must enter a value</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>


    </mat-tab-group>


</div>

