import {AsyncPipe, DOCUMENT, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    FormsModule,
    NgForm,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {FuseAlertComponent, FuseAlertType} from '@fuse/components/alert';
import {AuthService} from 'app/core/auth/auth.service';
import {catchError, debounceTime, of, Subject, takeUntil} from 'rxjs';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CdkScrollable} from '@angular/cdk/overlay';
import {MatTabGroup, MatTabsModule} from '@angular/material/tabs';
import {MatListItem, MatNavList} from '@angular/material/list';
import {FuseFindByKeyPipe} from '../../../../@fuse/pipes/find-by-key';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';
import {matchValidator, minLengthValidator} from '../../../core/services/validators';


@Component({
    selector: 'business-register',
    templateUrl: './business-register.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations
    ],
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        FuseAlertComponent,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        NgOptimizedImage,
        MatSelect,
        MatOption,
        MatAutocomplete,
        MatAutocompleteTrigger,
        NgForOf,
        AsyncPipe,
        MatSidenavModule, MatIconModule, MatButtonModule, MatProgressBarModule, CdkScrollable, MatTabsModule, MatListItem, MatNavList, NgClass, FuseFindByKeyPipe],
})
export class BusinessRegisterComponent implements OnInit {
    @ViewChild('signUpNgForm') signUpNgForm: NgForm;
    @ViewChild('courseSteps', {static: true}) courseSteps: MatTabGroup;

    formSteps: any[] = [
        {title: 'Welcome', subtitle: 'Introduction to Altitude Rewards', step: 0},
        {title: 'Activate Key', subtitle: 'Enter Your Activation Key', step: 1},
        {title: 'Business Info', subtitle: 'Verify Business Details', step: 2},
        {title: 'Owner Info', subtitle: 'Enter Owner Information', step: 3},
        {title: 'Finish', subtitle: 'Complete Your Registration', step: 4},
    ];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;

    currentStep: number = 0;

    signUpForm: UntypedFormGroup;
    showAlert: boolean = false;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: '',
    };

    private POST_ACTIVATION_KEY = '/destinationowner/activation/activate';
    private POST_GET_CODE = '/destinationowner/activation/getcode';
    private POST_VERIFY_CODE = '/destinationowner/activation/verifycode';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _authService: AuthService,
        private _formBuilder: UntypedFormBuilder,
        private _router: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // this.getPath();
        const id_redeem = this._router.snapshot.queryParamMap.get('key');

        // Create the form
        this.signUpForm = this._formBuilder.group({
            id_activationkey: [{value: id_redeem, disabled: true}, [Validators.required]],
            ds_reservationCode: ['', [Validators.required]],
            ds_business_name: [''],
            ds_phone_number: [''],
            ds_address: [''],
            ds_email: ["", [Validators.required, Validators.email]],
            ds_name: ["", Validators.required],
            ds_lastname: ["", Validators.required],
            ds_password: ["", Validators.required],
            ds_password_verification: ["", [Validators.required]],
        }, { validators: matchValidator('ds_password', 'ds_password_verification') });

        this.signUpForm.get('ds_password_verification').valueChanges
            .pipe(debounceTime(500))
            .subscribe(() => this.checkPasswords());

        this.getReservationCode();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    checkPasswords(): void {
        const password = this.signUpForm.get('ds_password').value;
        const passwordVerification = this.signUpForm.get('ds_password_verification').value;

        if (password && passwordVerification && password !== passwordVerification) {
            this.showAlert = true;
            this.alert = {
                type: 'error',
                message: 'Passwords do not match!'
            };
        } else {
            this.showAlert = false;
        }
    }

    getReservationCode(): void {
        const payload = new FormData();
        payload.append('id_activationkey', this.signUpForm.get('id_activationkey').value);

        this._authService.httpPostService(this.POST_GET_CODE, payload)
            .pipe(
                catchError((error: any) => {
                    return of([]);
                })
            )
            .subscribe(
                (response) => {
                    this.signUpForm.get('ds_reservationCode').setValue(response['ds_reservationcode']);
                }
            );
    }

    getActivationKey(): void {
        const payload = new FormData();
        payload.append('ds_reservationCode', this.signUpForm.get('ds_reservationCode').value);

        this._authService.httpPostService(this.POST_VERIFY_CODE, payload)
            .pipe(
                catchError((error: any) => {
                    return of([]);
                })
            )
            .subscribe(
                (response) => {
                    if (response['ds_Status'] === "Ok") {
                        this.signUpForm.get('ds_business_name').setValue(response.client['ds_legalname']);
                        this.signUpForm.get('ds_phone_number').setValue(response.client['ds_phone']);
                        this.signUpForm.get('ds_address').setValue(response.client['ds_address1']);
                        this.signUpForm.get('ds_email').setValue(response.client['ds_email']);
                        this.signUpForm.get('ds_name').setValue(response.client['ds_ownerfirstname']);
                        this.signUpForm.get('ds_lastname').setValue(response.client['ds_ownerlastname']);
                        // Go to step
                        this.goToStep(this.currentStep + 1);

                        // Scroll the current step selector from sidenav into view
                        this._scrollCurrentStepElementIntoView();
                    }
                }
            );
    }

    onNavigate() {
        const baseUrl = window.location.origin; // This will give you the base URL
        window.open(baseUrl + '/sign-in', "_blank");
    }

    /**
     * Sign up
     */
    signUp(): void {

        // Do nothing if the form is invalid
        if (this.signUpForm.invalid) {
            return;
        }

        // Disable the form
        this.signUpForm.disable();


        const payload = new FormData();
        payload.append('id_activationkey', this.signUpForm.get('id_activationkey').value);
        payload.append('ds_reservationCode', this.signUpForm.get('ds_reservationCode').value);
        payload.append('ds_business_name', this.signUpForm.get('ds_business_name').value);
        payload.append('ds_phone_number', this.signUpForm.get('ds_phone_number').value);
        payload.append('ds_address', this.signUpForm.get('ds_address').value);
        payload.append('ds_email', this.signUpForm.get('ds_email').value);
        payload.append('ds_name', this.signUpForm.get('ds_name').value);
        payload.append('ds_lastname', this.signUpForm.get('ds_lastname').value);
        payload.append('ds_password', this.signUpForm.get('ds_password').value);
        payload.append('ds_password_verification', this.signUpForm.get('ds_password_verification').value);

        this._authService.httpPostService(this.POST_ACTIVATION_KEY, payload)
            .pipe(
                catchError((error) => {
                    // Re-enable the form
                    this.signUpForm.enable();
                    return error;
                })
            )
            .subscribe(
                (response) => {
                    // Go to step
                    this.goToStep(this.currentStep + 1);

                    // Scroll the current step selector from sidenav into view
                    this._scrollCurrentStepElementIntoView();
                });
    }


    // -----------------------------------------------------------------------------------------------------


    /**
     * Go to given step
     *
     * @param step
     */
    goToStep(step: number): void {
        // Set the current step
        this.currentStep = step;

        // Go to the step
        this.courseSteps.selectedIndex = this.currentStep;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Go to previous step
     */
    goToPreviousStep(): void {
        // Return if we already on the first step
        if (this.currentStep === 0) {
            return;
        }

        // Go to step
        this.goToStep(this.currentStep - 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    /**
     * Go to next step
     */
    goToNextStep(): void {
        console.log(this.signUpForm.get('ds_reservationCode').valid);
        console.log(this.currentStep);
        // Return if we already on the last step
        if (this.currentStep === 5 - 1) {
            return;
        }
        if (this.currentStep === 1) {
            this.getActivationKey();
            return;
        }
        if (this.currentStep === 3) {
            this.signUp();
            return;
        }

        // Go to step
        this.goToStep(this.currentStep + 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Scrolls the current step element from
     * sidenav into the view. This only happens when
     * previous/next buttons pressed as we don't want
     * to change the scroll position of the sidebar
     * when the user actually clicks around the sidebar.
     *
     * @private
     */
    private _scrollCurrentStepElementIntoView(): void {
        // Wrap everything into setTimeout so we can make sure that the 'current-step' class points to correct element
        setTimeout(() => {
            // Get the current step element and scroll it into view
            const currentStepElement =
                this._document.getElementsByClassName('current-step')[0];
            if (currentStepElement) {
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        });
    }

}
