import Quill from 'quill';
import { QuillModules } from 'ngx-quill';

const Inline = Quill.import('blots/inline') as any;

class GoldText extends Inline {
    static create(value: any) {
        let node = super.create(value);
        node.classList.add('md:text-h2', 'font-regular', 'tracking-wide', 'text-gold', 'my-2', 'text-left', 'text-[32px]');
        return node;
    }

    static formats(node) {
        return node.className;
    }

    format(name, value) {
        if (name === GoldText.blotName && value) {
            this.domNode.classList.add('md:text-h2', 'font-regular', 'tracking-wide', 'text-gold', 'my-2', 'text-left', 'text-[32px]');
        } else {
            super.format(name, value);
        }
    }
}

GoldText.blotName = 'goldText';
GoldText.tagName = 'h1';

Quill.register(GoldText, true);

export const quillModulesStandard: QuillModules = {
    toolbar: {
        container: [
            ['custom-gold-text']
        ],
        handlers: {
            'custom-gold-text': function() {
                const range = this.quill.getSelection();
                if (range) {
                    this.quill.formatText(range.index, range.length, 'goldText', true);
                }
            }
        }
    },
    formats: ['goldText']
};
