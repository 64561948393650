<div class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b"
         *ngIf="false">
        <!-- Title -->
        <div
            class="w-2/3 lg:4/5 text-4xl font-extrabold tracking-tight">{{ destinationType?.ds_name ? destinationType.ds_name : "Special Offers" }}
        </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center sm:mt-0 sm:ml-4">
            <!-- Search by Keyword -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 pr-1" *ngIf="false"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup.enter)="searchElements(null)"
                    [formControl]="keywordSearchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search for keyword'">
            </mat-form-field>
            <!-- Search by Type -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64" *ngIf="false"
                            [subscriptSizing]="'dynamic'">
                <mat-select [formControl]="typeSearchInputControl" [placeholder]="'Status'">
                    <ng-container>
                        <mat-option [value]="item.value" *ngFor="let item of searchParameters">
                            {{ item.state }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <!-- Add product button -->
            <button
                *ngIf="false"
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="searchElements(null)">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Search</span>
            </button>
            <!-- Add product button -->
            <button
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="createAndToggleNewSpecialOffer()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="grid">
                <!-- Header -->
                <div
                    class="special-offers-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black">
                    <div class="mt-2">Special Offers</div> <!-- Second -->
                    <div
                        class="hidden sm:block mt-2"> <!-- Third -->
                        Active Range
                    </div>
                    <div
                        class="hidden lg:block mt-2"> <!-- Fourth -->
                        Active
                    </div>
                    <div class="hidden lg:block mt-2">Details</div> <!-- Fifth -->
                    <button
                        class="sm:block"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="createAndToggleNewSpecialOffer()">
                        <span class="ml-2 mr-1">Add</span>
                    </button> <!-- Sixth -->
                </div>
                <!-- Rows -->
                <ng-container *ngIf="specialOffersList.length > 0; else noProducts">
                    <ng-container *ngFor="let specialOffer of specialOffersList">
                        <div class="special-offers-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                            <!-- SKU -->
                            <div class="truncate"> <!-- Second -->
                                {{ specialOffer.ds_offer }}
                            </div>

                            <!-- Name -->
                            <div class="hidden sm:block truncate"> <!-- Third -->
                                {{ formatDate(specialOffer.dt_start) + ' - ' + formatDate(specialOffer.dt_end) }}
                            </div>

                            <!-- Active -->
                            <div class="hidden lg:block"> <!-- Sixth -->
                                <ng-container *ngIf="isCurrentOffer(specialOffer.dt_start, specialOffer.dt_end)">
                                    <mat-icon
                                        class="text-green-400 icon-size-5"
                                        [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                </ng-container>
                                <ng-container *ngIf="!isCurrentOffer(specialOffer.dt_start, specialOffer.dt_end)">
                                    <mat-icon
                                        class="text-gray-400 icon-size-5"
                                        [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                                </ng-container>
                            </div>

                            <!-- Details button -->
                            <div class="flex items-center"> <!-- Seventh -->
                                <!--                                            class="min-w-10 min-h-7 h-7 px-2 leading-6"-->
                                <button
                                    class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 overflow-hidden"
                                    mat-stroked-button
                                    (click)="toggleDetails(specialOffer.id_offer)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="selectedSpecialOffer?.id_offer === specialOffer.id_offer ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="grid"> <!-- First -->
                            <ng-container
                                *ngIf="selectedSpecialOffer?.id_offer === specialOffer.id_offer">
                                <ng-container
                                    *ngTemplateOutlet="rowDetailsTemplate; context: {$implicit: specialOffer}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-template #noProducts>
                    <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">{{
                            loadingMessage
                        }}
                        <ngx-spinner
                            bdColor="rgba(51,51,51,0)"
                            color="#fff"
                            size="medium"
                            type="ball-clip-rotate-multiple">
                            <p style="font-size: 20px; color: white">Loading...</p></ngx-spinner>
                    </div>
                </ng-template>
            </div>

            <mat-paginator
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                (page)="searchElements('paginator', $event)"
                [ngClass]="{'pointer-events-none': isLoading}"
                [length]="20"
                [pageIndex]="0"
                [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="!isMobile"></mat-paginator>


            <ng-template #rowDetailsTemplate>
                <div class="shadow-lg overflow-hidden">
                    <div class="flex border-b">
                        <!-- Selected product form -->
                        <form
                            class="flex flex-col w-full"
                            [formGroup]="selectedSpecialOfferForm">

                            <div class="flex flex-col sm:flex-row p-8">

                                <!-- Product images and status -->
                                <div class="flex flex-col items-center sm:items-start mb-8 sm:mb-0">
                                    <div class="flex flex-col items-center">
                                        <div class="relative w-32 h-44 rounded-lg overflow-hidden">
                                            <!--suppress AngularNgOptimizedImage -->
                                            <img alt="No Image Available" [src]="imageURLHelper"
                                                 default="./assets/images/other/broken-image-icon-19.png"
                                                 class="w-full h-full object-cover rounded-lg">
                                            <button class="absolute bottom-0 right-0 mb-2 mr-2" mat-mini-fab
                                                    (click)="MainImageFileInput.click()">
                                                <mat-icon
                                                    svgIcon="heroicons_outline:arrow-up-on-square-stack"></mat-icon>
                                            </button>
                                            <input hidden type="file"
                                                   #MainImageFileInput
                                                   (change)="onChangeFile($event, 'im_media', 'main')"
                                                   name="fileUpload"
                                                   multiple="multiple" accept="image/*"/>
                                        </div>
                                    </div>

                                    <div class="flex flex-col mt-8" *ngIf="false">
                                        <span class="font-semibold mb-2">Offer status</span>
                                        <mat-slide-toggle
                                            disabled="true"
                                            [checked]="isCurrentOffer(selectedSpecialOfferForm.get('dt_start').value, selectedSpecialOfferForm.get('dt_end').value)"
                                            [color]="'primary'">
                                            {{ isCurrentOffer(selectedSpecialOfferForm.get('dt_start').value, selectedSpecialOfferForm.get('dt_end').value) ? 'Active' : 'Disabled' }}
                                        </mat-slide-toggle>
                                    </div>
                                </div>

                                <div class="flex flex-auto flex-wrap">
                                    <!-- Name, SKU & etc. -->
                                    <div class="flex flex-col w-full lg:w-2/4 sm:pl-8">

                                        <!-- Name -->

                                        <div class="flex">
                                            <mat-form-field class="w-full">
                                                <mat-label>Short Description</mat-label>
                                                <input
                                                    (input)="onInputMaxReach($event, 150)"
                                                    maxlength="150"
                                                    matInput
                                                    [formControlName]="'ds_offer'">
                                            </mat-form-field>
                                        </div>

                                        <!-- Name -->

                                        <div class="flex">
                                            <mat-form-field class="w-full">
                                                <mat-label>Short Title</mat-label>
                                                <input
                                                    (input)="onInputMaxReach($event, 15)"
                                                    maxlength="15"
                                                    matInput
                                                    [formControlName]="'ds_shortname'">
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3 pl-2" *ngIf="false">
                                                <mat-label>Global Redeems</mat-label>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input
                                                    matInput
                                                    (click)="$event.target.select()"
                                                    (input)="onInputChange($event, 'vi_maxglobalredeem')"
                                                    [formControlName]="'vi_maxglobalredeem'">
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3 pl-2" *ngIf="false">
                                                <mat-label>Member Redeems</mat-label>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input
                                                    matInput
                                                    (click)="$event.target.select()"
                                                    (input)="onInputChange($event, 'vi_maxmemberredeem')"
                                                    [formControlName]="'vi_maxmemberredeem'">
                                            </mat-form-field>
                                        </div>

                                        <!-- SKU and Barcode -->
                                        <div class="flex">
                                            <mat-form-field class="w-1/2">
                                                <mat-label>Start Date</mat-label>
                                                <mat-icon
                                                    matPrefix
                                                    class="hidden sm:flex icon-size-5"
                                                    [svgIcon]="'heroicons_solid:calendar-days'"></mat-icon>
                                                <input
                                                    matInput
                                                    [matDatepicker]="StartDatepicker"
                                                    [formControl]="dtStartDate"
                                                    (dateChange)="onDateChange($event)"
                                                    [placeholder]="'YYYY-MM-DD'"
                                                    readonly
                                                    (focus)="StartDatepicker.open()">
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="StartDatepicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #StartDatepicker></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field class="w-1/2 pl-2">
                                                <mat-label>End Date</mat-label>
                                                <mat-icon
                                                    matPrefix
                                                    class="hidden sm:flex icon-size-5"
                                                    [svgIcon]="'heroicons_solid:calendar-days'"></mat-icon>
                                                <input
                                                    matInput
                                                    [matDatepicker]="EndDatepicker"
                                                    [formControl]="dtEndDate"
                                                    (dateChange)="onDateChange($event)"
                                                    [placeholder]="'YYYY-MM-DD'"
                                                    readonly
                                                    (focus)="EndDatepicker.open()">
                                                <mat-datepicker-toggle
                                                    matSuffix
                                                    [for]="EndDatepicker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #EndDatepicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- Name, SKU & etc. -->
                                    <div class="flex flex-col w-full lg:w-2/4 sm:pl-8">

                                        <!-- SKU and Barcode -->
                                        <div class="flex"  *ngIf="false">

                                            <mat-form-field class="w-1/3">
                                                <mat-label>Offer Type</mat-label>
                                                <mat-select [formControlName]="'id_offertype'">
                                                    <ng-container *ngFor="let category of offerTypesArray">
                                                        <mat-option [value]="category.id_offertype">
                                                            {{ category.ds_offertype }}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="w-1/3 pl-2">
                                                <mat-label>Redeem Type</mat-label>
                                                <mat-select [formControlName]="'en_redeemtype'">
                                                    <ng-container *ngFor="let category of ['Basic Redeem']">
                                                        <mat-option [value]="0">
                                                            {{ category }}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="w-1/3 pl-2">
                                                <mat-label>Language</mat-label>
                                                <mat-select [formControlName]="'id_language'">
                                                    <ng-container *ngFor="let language of languagesList">
                                                        <mat-option [value]="language.id_language">
                                                            {{ language.ds_laguagename }}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>

                                        </div>
                                        <div class="flex">
                                            <mat-form-field class="fuse-mat-textarea flex-auto">
                                                <mat-label>Long Description</mat-label>
                                                <textarea
                                                    matInput
                                                    [formControlName]="'tx_description'"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="flex items-center justify-between w-full border-t px-8 py-4">
                                <button
                                    [disabled]="!selectedSpecialOfferForm.get('id_offer').value"
                                    class="-ml-4"
                                    mat-stroked-button
                                    [color]="'warn'"
                                    (click)="deleteSpecialOffer()">
                                    Delete
                                </button>
                                <div class="flex items-center">
                                    <div
                                        class="flex items-center mr-4"
                                        *ngIf="flashMessage">
                                        <ng-container *ngIf="flashMessage === 'success'">
                                            <mat-icon
                                                class="text-green-500"
                                                [svgIcon]="'heroicons_outline:check'"></mat-icon>
                                            <span class="ml-2">Product updated</span>
                                        </ng-container>
                                        <ng-container *ngIf="flashMessage === 'error'">
                                            <mat-icon
                                                class="text-red-500"
                                                [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                            <span class="ml-2">An error occurred, try again!</span>
                                        </ng-container>
                                        <ng-container *ngIf="flashMessage === 'dateChange'">
                                            <mat-icon
                                                class="text-orange-500"
                                                [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                            <span
                                                class="ml-2">Invalid range dates, End Date changed to Start Date!</span>
                                        </ng-container>
                                    </div>
                                    <button
                                        [disabled]="selectedSpecialOfferForm.invalid"
                                        mat-stroked-button
                                        [color]="'primary'"
                                        (click)="saveSpecialOffer()">
                                        {{ selectedSpecialOffer.id_offer ? "Update" : "Create" }}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </ng-template>

        </div>

    </div>

</div>
