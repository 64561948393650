import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {
    AbstractControl,
    FormControl,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators
} from "@angular/forms";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {catchError, finalize, forkJoin, of, Subject, takeUntil} from "rxjs";
import {fuseAnimations} from "@fuse/animations";
import {MatDialogModule} from "@angular/material/dialog";
import {CommonModule, DatePipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerInputEvent, MatDatepickerModule} from '@angular/material/datepicker';
import {DestinationSmallType, SpecialOffersType, TimeDetails} from '../destinations.types';
import {ImagePreloadDirective} from '../../../../core/directives/image-preload.directive';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {Languages} from '../../../../core/types/common.types';

@Component({
    selector: "special-offers-list",
    templateUrl: "./special-offers-list.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatDatepickerModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        ImagePreloadDirective,
        NgOptimizedImage,
        NgxSpinnerComponent,
    ]
})
export class SpecialOffersListComponent implements OnInit, OnDestroy {
    @Input() destination_ID: any; // Declare the input property
    @Input() fromProperty: boolean = false; // Declare the input property

    @ViewChild(MatPaginator) private _paginator: MatPaginator;

    isMobile: boolean;
    isLoading: boolean = false;
    loadingMessage: string = null;
    keywordSearchInputControl: UntypedFormControl = new UntypedFormControl("");
    typeSearchInputControl: UntypedFormControl = new UntypedFormControl("");

    destinationType: DestinationSmallType;

    elementFormGroup: UntypedFormGroup;
    specialOffersList: SpecialOffersType[] = [];

    selectedSpecialOffer: SpecialOffersType = null;
    selectedSpecialOfferForm: UntypedFormGroup;

    languagesList: Languages[];

    offerTypesArray: any = [];

    imageURLHelper: string = null;

    flashMessage: 'success' | 'error' | 'dateChange' | null = null;

    searchParameters = [
        {state: "All", value: ""},
        {state: "Active", value: 0},
        {state: "Pending", value: 1}
    ];

    // API URL
    private API_GET_OFFERS = "/system/offer/get";
    private API_GET_OFFER_TYPES = "/admin/list/offertypes";
    private API_ADD_OFFER = "/system/offer/addedit";
    private API_DELETE_OFFER = "/system/offer/delete";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private _restFactoryService: RestFactoryService,
        private spinner: NgxSpinnerService,
        private breakpointObserver: BreakpointObserver,
        private _snackBar: MatSnackBar
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            this.isMobile = result.matches;
        });

        if (!this.destination_ID) {
            this.destination_ID = this.route.snapshot.queryParamMap.get('id_destination');
        }
        this.languagesList = JSON.parse(localStorage.getItem("LANGUAGES"));

        this.spinner.show().then();
        this.getApiService([
            {api: this.API_GET_OFFER_TYPES, key: "data", table: this.offerTypesArray, last: true},
        ]);

        // Get the data
        // this.searchElements(null);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Table Elements
     */
    getApiService(apis: { api: string; key: string; table: any; last: boolean }[]): void {
        forkJoin(
            apis.map(({api, key}) =>
                this._restFactoryService.httpPostService(api, new FormData(), key)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        catchError(() => {
                            return of([]);
                        })
                    )
            )
        ).pipe(
            finalize(() => {
                this.isLoading = false;
                this.spinner.hide().then();
            })
        ).subscribe((responses) => {
            responses.forEach((response, index) => {
                apis[index].table.push(...response);
                if (apis[index].last) {
                    this.searchElements(null);
                }
            });
        });
    }

    /**
     * Open new-search-window dialog
     */
    searchElements(searcher: string, paginator = null): void {
        const data = {
            "id_destination": this.destination_ID
        };
        const payload = this._restFactoryService.createPayloadPaginator(searcher, this._paginator, data);

        this.specialOffersList = [];
        this.loadingMessage = null;
        this.spinner.show().then();
        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_OFFERS, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    this.spinner.hide().then();
                    this.loadingMessage = "No results found!";
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.selectedSpecialOffer = null;
                    this.selectedSpecialOfferForm = null;
                    this.imageURLHelper = null;
                    this.specialOffersList = response.ar_results;
                    this.destinationType = response.destinationType;
                    this._changeDetectorRef.markForCheck();
                    setTimeout(() => {
                        this._paginator.length = response.vi_TotalRows;
                        // Code to execute after 1.5 seconds
                    }, 500);
                    this.spinner.hide().then();
                    if (this.specialOffersList.length === 0) {
                        this.loadingMessage = "No results found!";
                    }
                });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    deleteSpecialOffer(): void {
        const message = this._fuseConfirmationService.open({
            title: "Delete Special Offer",
            message: "Are you sure you want to delete this special offer?",
            actions: {
                confirm: {
                    show: true
                },
                cancel: {
                    show: true
                }
            },
            dismissible: false
        });

        message.afterClosed().subscribe(
            (value) => {
                if (value === "confirmed") {
                    const payload = new FormData();
                    payload.append("id_offer", this.selectedSpecialOffer.id_offer);
                    this._unsubscribeAll.next(null);
                    this._restFactoryService.httpPostService(this.API_DELETE_OFFER, payload)
                        .pipe(
                            takeUntil(this._unsubscribeAll),
                            finalize(() => {
                                this._changeDetectorRef.detectChanges();
                            }),
                            catchError((err) => {
                                return err;
                            })
                        )
                        .subscribe(
                            (response) => {
                                this.specialOffersList = this.specialOffersList.filter(offer => offer.id_offer !== this.selectedSpecialOffer.id_offer);
                                this.selectedSpecialOffer = null;
                                this.selectedSpecialOfferForm = null;
                                if (this.specialOffersList.length === 0) {
                                    this.loadingMessage = "No results found!";
                                }
                                this._changeDetectorRef.markForCheck();
                            });
                }
            }
        );
    }

    convertDateFormat(inputDate: string): string {
        // Create a new Date object from the input string
        let date = new Date(inputDate);

        // Format the date components
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
        let milliseconds = String(date.getMilliseconds()).padStart(6, '0'); // Pad with zeros to get microseconds

        // Return the formatted date string
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }


    /**
     * Open new-search-window dialog
     */
    saveSpecialOffer(): void {

        const payload = new FormData();

        const language = this.languagesList.find(language => language.ds_ietftag === "en-US");

        payload.append("id_language", language.id_language);
        payload.append("id_destination", this.selectedSpecialOfferForm.get("id_destination").value);
        payload.append("id_offertype", this.offerTypesArray[0].id_offertype);
        payload.append("ds_offer", this.selectedSpecialOfferForm.get("ds_offer").value);
        payload.append("ds_shortname", this.selectedSpecialOfferForm.get("ds_shortname").value);
        payload.append("tx_description", this.selectedSpecialOfferForm.get("tx_description").value);
        payload.append("vi_maxglobalredeem", "-1");
        payload.append("vi_maxmemberredeem", "1");
        payload.append("dt_start", this.convertDateFormat(this.selectedSpecialOfferForm.get("dt_start").value));
        payload.append("dt_end", this.convertDateFormat(this.selectedSpecialOfferForm.get("dt_end").value));
        payload.append("en_redeemtype", "0");

        if (this.selectedSpecialOfferForm.get("id_offer").value) {
            payload.append("id_offer", this.selectedSpecialOfferForm.get("id_offer").value);
        }

        if (this.selectedSpecialOfferForm.get("im_media").value !== this.selectedSpecialOffer.im_media) {
            payload.append("im_image", this.selectedSpecialOfferForm.get("im_media").value);
        }

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_OFFER, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.searchElements(null);
                });
    }


    getTodayFormatted() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');
        const milliseconds = String(today.getMilliseconds()).padStart(6, '0'); // Pad with zeros to get microseconds

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    createAndToggleNewSpecialOffer(): void {
        // Create a new SpecialOffersType object
        let newSpecialOffer: SpecialOffersType = {
            // Fill in the properties of the new special offer here
            // For example:
            id_offer: null,
            id_destination: this.destination_ID,
            id_offertype: null,
            id_language: null,
            en_state: null,
            vi_maxmemberredeem: -1,
            vi_maxglobalredeem: -1,
            dt_start: {
                datetime: {
                    date: this.getTodayFormatted(),
                    timezone_type: null,
                    timezone: null
                },
                microseconds: null,
                nanoseconds: null,
                ns100: null
            },
            dt_end: {
                datetime: {
                    date: this.getTodayFormatted(),
                    timezone_type: null,
                    timezone: null
                },
                microseconds: null,
                nanoseconds: null,
                ns100: null
            },
            ds_offer: "New Offer",
            ds_shortname: null,
            tx_description: null,
            en_redeemtype: null,
            tx_redeemdata: null,
            dt_created: {
                datetime: {
                    date: this.getTodayFormatted(),
                    timezone_type: null,
                    timezone: null
                },
                microseconds: null,
                nanoseconds: null,
                ns100: null
            },
            dt_lastmodified: {
                datetime: {
                    date: this.getTodayFormatted(),
                    timezone_type: null,
                    timezone: null
                },
                microseconds: null,
                nanoseconds: null,
                ns100: null
            },
            im_media: null,
            ds_en_state: null,
            ds_en_redeemtype: null,
            parent: null,
            ggparent: null,
            gparent: null
            // ...
        };

        this.imageURLHelper = null;

        // Push the new special offer to the top of the specialOffersList array
        this.specialOffersList.unshift(newSpecialOffer);

        // Toggle the new special offer
        this.toggleDetails(newSpecialOffer.id_offer);
    }

    /**
     * Toggle product details
     *
     * @param soID
     */
    toggleDetails(soID: string): void {
        // If the product is already selected...
        if (this.selectedSpecialOffer && this.selectedSpecialOffer.id_offer === soID) {
            // Close the details
            this.closeDetails();
            return;
        }

        if (this.selectedSpecialOffer && this.selectedSpecialOffer.id_offer === null) {
            this.closeDetails();
            return;
        }

        // Find the selected special offer
        this.selectedSpecialOffer = this.specialOffersList.find(item => item.id_offer === soID) || null;

        // If a special offer is selected...
        if (this.selectedSpecialOffer) {
            // Create the selected product form
            this.imageURLHelper = this.selectedSpecialOffer.im_media;
            this.selectedSpecialOfferForm = this._formBuilder.group({
                id_offer: [this.selectedSpecialOffer.id_offer],
                id_destination: [this.selectedSpecialOffer.id_destination, Validators.required],
                // id_offertype: [this.selectedSpecialOffer.id_offertype, Validators.required],
                // id_language: [this.selectedSpecialOffer.id_language, Validators.required],
                // vi_maxmemberredeem: [this.selectedSpecialOffer.vi_maxmemberredeem, [Validators.required, this.numberAndMinValidator]],
                // vi_maxglobalredeem: [this.selectedSpecialOffer.vi_maxglobalredeem, [Validators.required, this.numberAndMinValidator]],
                dt_start: [this.convertToDate(this.selectedSpecialOffer.dt_start.datetime.date), Validators.required],
                dt_end: [this.convertToDate(this.selectedSpecialOffer.dt_end.datetime.date), Validators.required],
                ds_offer: [this.selectedSpecialOffer.ds_offer, Validators.required],
                ds_shortname: [this.selectedSpecialOffer.ds_shortname, Validators.required],
                tx_description: [this.selectedSpecialOffer.tx_description, Validators.required],
                // en_redeemtype: [this.selectedSpecialOffer.en_redeemtype, Validators.required],
                im_media: [this.selectedSpecialOffer.im_media, this.selectedSpecialOffer.id_offer ? Validators.required : null]
            });
        }

        this._changeDetectorRef.markForCheck();
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        if (this.selectedSpecialOffer.id_offer === null || (this.selectedSpecialOfferForm.touched && this.selectedSpecialOfferForm.dirty)) {
            const message = this._fuseConfirmationService.open({
                title: "Warning!",
                message: "The offer is not saved, and will be lost on toggle!",
                actions: {
                    confirm: {
                        show: true
                    },
                    cancel: {
                        show: true
                    }
                },
                dismissible: false
            });
            message.afterClosed().subscribe(
                (value) => {
                    if (value === "confirmed") {
                        if (this.selectedSpecialOffer.id_offer === null) {
                            this.specialOffersList.shift();
                        }
                        this.selectedSpecialOffer = null;
                        this.selectedSpecialOfferForm = null;
                        this._changeDetectorRef.markForCheck();
                    }
                }
            );
        } else {
            this.selectedSpecialOffer = null;
            this.selectedSpecialOfferForm = null;
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * upload File Manager
     */
    onChangeFile(event: any, property: string, source: string, id: string = null): void {
        const inpF = event.target as HTMLInputElement;
        const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp"];
        const fileExtension = (inpF.files[0] as any).name.toLowerCase().split('.').pop();

        if (allowedExtensions.includes(fileExtension)) {


            const file = inpF.files[0];
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(file);

            this.selectedSpecialOfferForm.get('im_media').setValue(file);
            this.imageURLHelper = urlCreator.createObjectURL(file);
            this._changeDetectorRef.markForCheck();

            // Create a new Image object
            // let img = new Image();
            //
            // // When the image has loaded, check the aspect ratio
            // img.onload = () => {
            //     const width = img.width;
            //     const height = img.height;
            //
            //     // Check if the aspect ratio is 4:3
            //     const deviation = 0.15; // Allow deviation of up to 5%
            //     const lowerBound = 4 / 3 * (1 - deviation);
            //     const upperBound = 4 / 3 * (1 + deviation);
            //     const imageRatio = width / height;
            //
            //     if (imageRatio >= lowerBound && imageRatio <= upperBound) {
            //         this.selectedSpecialOfferForm.get('im_media').setValue(file);
            //         this.imageURLHelper = urlCreator.createObjectURL(file);
            //         this._changeDetectorRef.markForCheck();
            //     } else {
            //         // The image does not have a 4:3 aspect ratio, show an error message
            //         const errorFormatMessage = `The image must be in a ${4}:${3} format. Please upload an image with a width that is ${(4 / 3).toFixed(2)} times its height.`;
            //         const message = this._fuseConfirmationService.open({
            //             title: "Error!",
            //             message: errorFormatMessage,
            //             actions: {
            //                 confirm: {
            //                     show: false
            //                 },
            //                 cancel: {
            //                     show: false
            //                 }
            //             },
            //             dismissible: false
            //         });
            //         setTimeout(() => {
            //             message.close();
            //         }, 2000);
            //     }
            // };
            //
            // // Start loading the image
            // img.src = imageUrl;
        } else {
            let messageErr = "Invalid image format. Please upload a .jpg, .png, .jpeg, .gif, or .webp file.";
            const message = this._fuseConfirmationService.open({
                title: "Error!",
                message: messageErr,
                actions: {
                    confirm: {
                        show: false
                    },
                    cancel: {
                        show: false
                    }
                },
                dismissible: false
            });
            setTimeout(() => {
                message.close();
            }, 2000);
        }
    }

    formatDate(timeDetails: TimeDetails): string {
        const date = new Date(timeDetails.datetime.date);
        return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    }

    isCurrentOffer(start: TimeDetails, end: TimeDetails): boolean {
        const today = new Date();
        const startDate = new Date(start.datetime.date);
        const endDate = new Date(end.datetime.date);

        return startDate <= today && today <= endDate;
    }

    get dtStartDate(): FormControl {
        return this.selectedSpecialOfferForm.get('dt_start') as FormControl;
    }

    get dtEndDate(): FormControl {
        return this.selectedSpecialOfferForm.get('dt_end') as FormControl;
    }

    onDateChange(event: MatDatepickerInputEvent<Date>): void {
        const formattedDate = this.datePipe.transform(event.value, 'yyyy-MM-dd HH:mm:ss.SSSSSS');
        // Check if dtEndDate is less than dtStartDate
        if (this.dtEndDate.value && this.dtStartDate.value > this.dtEndDate.value) {
            // If dtEndDate is less than dtStartDate, set dtEndDate to be the same as dtStartDate
            this.dtEndDate.setValue(this.dtStartDate.value);
            this.flashMessage = 'dateChange';
            setTimeout(() => {
                this.flashMessage = null;
            }, 5000);
        }
    }

    convertToDate(dateString: string): Date {
        let dateParts = dateString.split(' ')[0].split('-');
        return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
    }

    numberAndMinValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (isNaN(value) || value < -1) {
            return {'numberAndMin': true};
        }
        return null;
    }

    onInputChange(event: any, controlName): void {
        const value = event.target.value;
        if (isNaN(value)) {
            this.selectedSpecialOfferForm.get(controlName).setValue(-1);
        }
        if (value < -1) {
            this.selectedSpecialOfferForm.get(controlName).setValue(-1);
        }
    }

    get vi_maxglobalredeem(): string {
        const value = this.selectedSpecialOfferForm.get('vi_maxglobalredeem').value;
        return value === -1 ? 'Unlimited' : value;
    }

    get vi_maxmemberredeem(): string {
        const value = this.selectedSpecialOfferForm.get('vi_maxmemberredeem').value;
        return value === -1 ? 'Unlimited' : value;
    }

    onInputMaxReach(event: any, maxReach: number = 15): void {
        const inputValue = event.target.value;
        if (inputValue.length >= 25) {
            // The input value has reached or exceeded the limit
            // You can take appropriate action here
            this._snackBar.open(`This field has a limit of ${maxReach} characters. Any text exceeding this limit will be cut off.`, "Close", {
                duration: 5000,  // Duration is in milliseconds (5000ms = 5s),
                panelClass: ['bg-card', 'dark:bg-transparent']
            });
        }
    }

}
