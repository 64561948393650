import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {catchError, Observable, of, tap, throwError} from 'rxjs';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';
import {MessageFactoryService} from '../services/message-factory.service';

// Initialize the counter
let error500Count = 0;

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {

    // Get the services
    const authService = inject(AuthService);
    const fuseConfirmationService = inject(MessageFactoryService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // The request was successful
                // Perform your action here
                error500Count = 0; // Reset the counter if the error status is not 500
            }
        }),
        catchError((error) => {
            let message = "Unknown error occurred. Please try again.";
            if (error?.error?.error_Description) {
                message = error.error.error_Description;
            }
            fuseConfirmationService.createErrorMessageBoxPerm("Error", message);


            // Catch "400 Data Error" responses
            // @ts-ignore
            if (error instanceof HttpErrorResponse && error.status === 400) {
                error500Count = 0; // Reset the counter if the error status is not 500
                // setTimeout(() => {
                //     // Sign out
                //     authService.signOut();
                //
                //     // Reload the app
                //     location.reload();
                // }, 3000);
            }

            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                error500Count = 0; // Reset the counter if the error status is not 500
                setTimeout(() => {
                    // Sign out
                    authService.signOut();

                    // Reload the app
                    location.reload();
                }, 3000);
            }

            // Catch "406 Error" responses
            if (error instanceof HttpErrorResponse && (error.status === 406 || error.status === 409)) {
                error500Count = 0; // Reset the counter if the error status is not 500
                setTimeout(() => {
                    // Sign out
                    authService.signOut();

                    // Reload the app
                    location.reload();
                }, 3000);
            }

            // Catch "500 Unauthorized" responses
            if (error instanceof HttpErrorResponse && (error.status === 500 || error.status === 0)) {
                error500Count++; // Increment the counter

                // If there have been 3 errors with status 500, redirect the user
                if (error500Count >= 3) {
                    error500Count = 0; // Reset the counter
                    setTimeout(() => {
                        // Sign out
                        authService.signOut();

                        // Reload the app
                        location.reload();
                    }, 3000);
                }
            }

            return throwError(error);
        }),
    );
};
