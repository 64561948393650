import {Injectable} from '@angular/core';
import {Navigation} from 'app/core/navigation/navigation.types';
import {Observable, ReplaySubject} from 'rxjs';
import {UserService} from '../user/user.service';
import {FuseNavigationItem} from '../../../@fuse/components/navigation';
import {isEqual} from 'lodash-es';
import {AuthService} from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): void {
        const payload = new FormData();
        this._authService.httpPostService("/system/menu/get", payload, 'menuData').subscribe(
            (response) => {
                let nagivationData = JSON.parse(localStorage.getItem("MENU_DATA"));
                if (!isEqual(nagivationData, response)) {
                    localStorage.setItem("MENU_DATA", JSON.stringify(response));
                    nagivationData = response;
                }
                return this._navigation.next({
                    compact: [],
                    default: nagivationData,
                    futuristic: [],
                    horizontal: []
                });
            });
    }
}
