import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {AuthService} from 'app/core/auth/auth.service';
import {of, switchMap} from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) => {
            // If the user is authenticated...
            if (authenticated) {
                const url = state.url === '/sign-in' ? '' : `redirectURL=${state.url}`;
                const urlParams = new URLSearchParams(url.split('?')[1]);
                const redirectURL = urlParams.get('redirectURL');
                if (redirectURL) {
                    return of(router.parseUrl(redirectURL));
                } else {
                    return of(router.parseUrl(''));
                }
            }

            // Allow the access
            return of(true);
        }),
    );
};
