<div class="sm:absolute pt-2 px-6 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->
        <div
            class="w-2/3 lg:4/5 text-4xl font-extrabold tracking-tight">Story Types
        </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center sm:mt-0 sm:ml-4">
            <!-- Search by Keyword -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 pr-1"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup)="searchElements(true)"
                    [formControl]="inputSearchControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search for keyword'">
            </mat-form-field>
            <!-- Search button -->
            <button
                *ngIf="false"
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="searchElements(true)">
                <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-plus'"></mat-icon>
                <span class="ml-2 mr-1">Search</span>
            </button>
            <!-- Add button -->
            <button
                class="ml-4"
                mat-stroked-button
                [color]="'primary'"
                (click)="createAndToggleNewSpecialOffer()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="grid">
                <!-- Header -->
                <div
                    class="story-types-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black">
                    <div class="mt-2">Story Types</div> <!-- Second -->
                    <div
                        class="hidden sm:block mt-2"> <!-- Third -->
                        Description
                    </div>
                    <div
                        class="hidden lg:block mt-2"> <!-- Fourth -->
                        Type
                    </div>
                    <div class="hidden lg:block mt-2">Details</div> <!-- Fifth -->
                </div>
                <!-- Rows -->
                <ng-container *ngIf="tableStoryTypesArray.length > 0; else noProducts">
                    <ng-container *ngFor="let storyType of tableStoryTypesArray">
                        <div class="story-types-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                            <!-- SKU -->
                            <div class="truncate"> <!-- Second -->
                                {{ storyType.ds_label }}
                            </div>

                            <!-- Name -->
                            <div class="hidden sm:block truncate"> <!-- Third -->
                                {{ storyType.tx_description }}
                            </div>

                            <!-- Active -->
                            <div class="truncate"> <!-- Second -->
                                {{ findStoryType(storyType.en_storytype)?.viewValue }}
                            </div>

                            <!-- Details button -->
                            <div class="flex items-center"> <!-- Seventh -->
                                <!--                                            class="min-w-10 min-h-7 h-7 px-2 leading-6"-->
                                <button
                                    class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 overflow-hidden"
                                    mat-stroked-button
                                    (click)="toggleDetails(storyType.id_storytype)">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="selectedStoryType?.id_storytype === storyType.id_storytype ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="grid"> <!-- First -->
                            <ng-container
                                *ngIf="selectedStoryType?.id_storytype === storyType.id_storytype">
                                <ng-container
                                    *ngTemplateOutlet="rowDetailsTemplate; context: {$implicit: storyType}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-template #noProducts>
                    <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">{{
                            loadingMessage
                        }}
                        <ngx-spinner
                            bdColor="rgba(51,51,51,0)"
                            color="#fff"
                            size="medium"
                            type="ball-clip-rotate-multiple">
                            <p style="font-size: 20px; color: white">Loading...</p></ngx-spinner>
                    </div>
                </ng-template>
            </div>

            <mat-paginator
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                [ngClass]="{'pointer-events-none': isLoading}"
                (page)="pageChanged($event)"
                [length]="pageLength"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100]"
                [showFirstLastButtons]="true"></mat-paginator>


            <ng-template #rowDetailsTemplate>
                <div class="shadow-lg overflow-hidden">
                    <div class="flex border-b">
                        <!-- Selected product form -->
                        <form
                            class="flex flex-col w-full"
                            [formGroup]="selectedStoryTypeForm">

                            <div class="flex flex-col sm:flex-row p-8">

                                <!-- Product images and status -->
                                <div class="flex flex-col items-center sm:items-start mb-8 sm:mb-0">
                                    <div class="flex flex-col items-center">
                                        <div class="relative w-32 h-44 rounded-lg overflow-hidden">
                                            <!--suppress AngularNgOptimizedImage -->
                                            <img alt="No Image Available" [src]="imageURLHelper"
                                                 default="./assets/images/other/broken-image-icon-19.png"
                                                 class="w-full h-full object-cover rounded-lg">
                                            <button class="absolute bottom-0 right-0 mb-2 mr-2" mat-mini-fab
                                                    (click)="MainImageFileInput.click()">
                                                <mat-icon
                                                    svgIcon="heroicons_outline:arrow-up-on-square-stack"></mat-icon>
                                            </button>
                                            <input hidden type="file"
                                                   #MainImageFileInput
                                                   (change)="onChangeFile($event, 'im_media', 'main')"
                                                   name="fileUpload"
                                                   multiple="multiple" accept="image/*"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-auto flex-wrap">
                                    <!-- Name, SKU & etc. -->
                                    <div class="flex flex-col w-full lg:w-2/4 sm:pl-8">

                                        <!-- Name -->

                                        <div class="flex">
                                            <mat-form-field class="w-full">
                                                <mat-label>Name</mat-label>
                                                <input
                                                    (input)="onInputMaxReach($event, 150)"
                                                    maxlength="150"
                                                    matInput
                                                    [formControlName]="'ds_name'">
                                            </mat-form-field>
                                        </div>

                                        <!-- Name -->

                                        <div class="flex">
                                            <mat-form-field class="w-1/3">
                                                <mat-label>Shortname</mat-label>
                                                <input
                                                    (input)="onInputMaxReach($event, 15)"
                                                    maxlength="15"
                                                    matInput
                                                    [formControlName]="'ds_shortname'">
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3 pl-2">
                                                <mat-label>Label</mat-label>
                                                <input
                                                    (input)="onInputMaxReach($event, 15)"
                                                    maxlength="15"
                                                    matInput
                                                    [formControlName]="'ds_label'">
                                            </mat-form-field>
                                            <mat-form-field class="w-1/3 pl-2">
                                                <mat-label>Subtitle</mat-label>
                                                <input
                                                    matInput
                                                    [formControlName]="'ds_subtitle'">
                                            </mat-form-field>
                                        </div>

                                        <!-- SKU and Barcode -->
                                        <div class="flex">
                                            <mat-form-field class="w-1/2">
                                                <mat-label>Order</mat-label>
                                                <!--suppress TypeScriptUnresolvedReference -->
                                                <input
                                                    matInput
                                                    type="number"
                                                    [formControlName]="'vi_order'">
                                            </mat-form-field>

                                            <mat-form-field class="w-1/2 pl-2">
                                                <mat-label>Type</mat-label>
                                                <mat-select [formControlName]="'en_storytype'">
                                                    <ng-container *ngFor="let category of enStoryTypesArray">
                                                        <mat-option [value]="category.value">
                                                            {{ category.viewValue }}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- Name, SKU & etc. -->
                                    <div class="flex flex-col w-full lg:w-2/4 sm:pl-8">

                                        <!-- SKU and Barcode -->
                                        <div class="flex">

                                            <mat-form-field class="w-full">
                                                <mat-label>Title</mat-label>
                                                <input
                                                    matInput
                                                    [formControlName]="'ds_title'">
                                            </mat-form-field>

                                        </div>
                                        <div class="flex">
                                            <mat-form-field class="fuse-mat-textarea flex-auto">
                                                <mat-label>Description</mat-label>
                                                <textarea
                                                    matInput
                                                    [formControlName]="'tx_description'"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="flex items-center justify-between w-full border-t px-8 py-4">
                                <button
                                    [disabled]="!selectedStoryTypeForm.get('id_storytype').value"
                                    class="-ml-4"
                                    mat-stroked-button
                                    [color]="'warn'"
                                    (click)="deleteStoryType()">
                                    Delete
                                </button>
                                <div class="flex items-center">
                                    <div
                                        class="flex items-center mr-4"
                                        *ngIf="flashMessage">
                                        <ng-container *ngIf="flashMessage === 'success'">
                                            <mat-icon
                                                class="text-green-500"
                                                [svgIcon]="'heroicons_outline:check'"></mat-icon>
                                            <span class="ml-2">Product updated</span>
                                        </ng-container>
                                        <ng-container *ngIf="flashMessage === 'error'">
                                            <mat-icon
                                                class="text-red-500"
                                                [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                            <span class="ml-2">An error occurred, try again!</span>
                                        </ng-container>
                                        <ng-container *ngIf="flashMessage === 'dateChange'">
                                            <mat-icon
                                                class="text-orange-500"
                                                [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                            <span
                                                class="ml-2">Invalid range dates, End Date changed to Start Date!</span>
                                        </ng-container>
                                    </div>
                                    <button
                                        [disabled]="selectedStoryTypeForm.invalid"
                                        mat-stroked-button
                                        [color]="'primary'"
                                        (click)="saveStoryType()">
                                        {{ selectedStoryTypeForm.get('id_storytype').value ? 'Update' : 'Create' }}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </ng-template>

        </div>

    </div>

</div>
