import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {RestFactoryService} from '../../../../../core/services/rest-factory.service';
import {CityTypes} from '../../../../auth/auth.types';
import {catchError, finalize, Observable, of, Subject, takeUntil} from 'rxjs';

@Component({
    selector: 'app-search-parent-window',
    standalone: true,
    imports: [
        MatButton,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatOption,
        MatSelect,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatError
    ],
    templateUrl: './search-parent-window.component.html',
    styleUrl: './search-parent-window.component.scss'
})
export class SearchParentWindowComponent implements OnInit, AfterViewInit {

    @Input() public id_destination: string;
    @Input() public parent: string;
    @ViewChild('autoCompleteTrigger') autoCompleteTrigger: MatAutocompleteTrigger;
    init: boolean = true;

    typingTimeout: any;

    selectedParent: string;
    parentArray: CityTypes[] = [];
    parentControl = new FormControl(null, Validators.required);
    filteredParents: Observable<CityTypes[]>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private API_GET_DESTINATION = "/admin/destination/search";
    private API_CHANGE_PARENT = "/admin/destination/setparent";

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        public matDialogRef: MatDialogRef<SearchParentWindowComponent>,
        private _restFactoryService: RestFactoryService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
    }

    ngAfterViewInit(): void {
        this.parentControl.setValue(this.parent);
        this.searchParent();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // noinspection DuplicatedCode
    onBlur(): void {
        setTimeout(() => {
            const value = this.parentControl.value;
            if (typeof value === 'string' && !this.parentArray.find(city => city.ds_name === value)) {
                this.parentControl.reset();
                this.parentControl.setErrors({'invalid': true});
                this.parentControl.markAsTouched();
            }
        }, 250);
    }

    displayFn(city: CityTypes): string {
        return city ? city.ds_name : '';
    }

    countryClick(event: any) {
        this.selectedParent = event.option.value.id_destination;
    }


    onKeyupAutoSearch(): void {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            if (this.parentControl?.value?.length > 2) {
                this.searchParent();
            }
        }, 500);
    }

    searchParent(): void {
        const data = {
            "ds_name": this.parentControl.value
        }
        const payload = this._restFactoryService.createPayloadPaginator(null, null, data);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_GET_DESTINATION, payload, "ar_results")
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    // this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.filteredParents = of(response);
                    if (response.length === 1) {
                        this.parentControl.setValue(response[0]);
                    }
                    if (this.init) {
                        this.init = false;
                        this.autoCompleteTrigger.writeValue(this.parent);
                        this._changeDetectorRef.detectChanges();
                    }
                });

    }

    /**
     * Save and close
     */
    saveAndClose(): void {
        const payload = new FormData();

        payload.append("id_destination", this.id_destination);
        payload.append("id_destinationparent", this.selectedParent);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_CHANGE_PARENT, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.matDialogRef.close("success");
                }
            );
    }

    /**
     * Discard the message
     */
    closeDialog(): void {
        this.matDialogRef.close(false);
    }

}
