<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
     *ngIf="!showList else showDestination" [@zoomOut]="!showList" [@zoomIn]="showList && showDestination">

    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Title -->
        <div
            class="text-4xl font-extrabold tracking-tight">{{ title }}
        </div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search by Origin -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full lg:min-w-64 pr-1"
                            *ngIf="!displayAddDestination"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup)="onKeyup()"
                    [formControl]="parentSearchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search by Parent'">
            </mat-form-field>
            <!-- Search by Name -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full lg:min-w-64 pr-1"
                            *ngIf="!displayAddDestination"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup)="onKeyup()"
                    [formControl]="nameSearchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search by Name'">
            </mat-form-field>
            <!-- Search by Keyword -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full lg:min-w-64 pr-1"
                            *ngIf="!displayAddDestination"
                            [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input
                    matInput
                    (keyup)="onKeyup()"
                    [formControl]="keywordSearchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search by Keyword'">
            </mat-form-field>
            <!-- Search by Type -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full lg:min-w-64"
                            *ngIf="!displayAddDestination && !reviewMode"
                            [subscriptSizing]="'dynamic'">
                <mat-select [formControl]="typeSearchInputControl" [placeholder]="'Status'" (selectionChange)="onKeyup()">
                    <ng-container>
                        <mat-option [value]="item.value" *ngFor="let item of searchParameters">
                            {{ item.view }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <!-- Add Destination button -->
            <button
                *ngIf="!displayAddDestination && !reviewMode"
                class="ml-4"
                mat-stroked-button
                [color]="'primary'"
                (click)="displayAddDestination = !displayAddDestination">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto"
             *ngIf="!displayAddDestination">
            <div class="grid">
                <!-- Header -->
                <div
                    class="grid-five-rows-destinations z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black">
                    <div class="hidden lg:block">ID</div>
                    <div>{{ destinationType?.ds_name }}</div>
                    <div class="hidden lg:block">Description</div>
                    <div>Status</div>
                    <div class="hidden lg:block">Actions</div>
                </div>
                <!-- Rows -->
                <ng-container *ngIf="tableElements?.length > 0; else noProducts">
                    <ng-container *ngFor="let element of tableElements">
                        <div
                            class="grid-five-rows-destinations grid items-center gap-4 py-3 px-6 md:px-8 border-b">

                            <!-- ID -->
                            <div class="truncate hidden md:block">
                                {{ element.id_destination }}
                            </div>

                            <!-- ID -->
                            <div class="truncate">
                                {{ element.ds_name }}
                            </div>

                            <!-- Name -->
                            <div class="truncate hidden md:block">
                                {{ removeHtmlTags(element.tx_description) }}
                            </div>

                            <!-- Type -->
                            <div class="truncate">
                                {{ element.ds_en_state }}
                            </div>

                            <!-- Details button -->
<!--                                [disabled]="element.en_state === 6"-->
                            <button
                                class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                mat-stroked-button
                                color="primary"
                                (click)="switchToDestination(element.id_destination)">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_outline:cog-6-tooth'"></mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-template #noProducts>
                    <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">{{
                            loadingMessage
                        }}
                        <ngx-spinner
                            bdColor="rgba(51,51,51,0)"
                            color="#fff"
                            size="medium"
                            type="ball-clip-rotate-multiple">
                            <p style="font-size: 20px; color: white">Loading...</p></ngx-spinner>
                    </div>
                </ng-template>
            </div>
            <mat-paginator
                class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                (page)="searchElements('paginator')"
                [ngClass]="{'pointer-events-none': showList}"
                [length]="20"
                [pageIndex]="0"
                [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 100]"
                [showFirstLastButtons]="true"></mat-paginator>
        </div>


        <div class="flex justify-center items-center w-full px-4 mb-20" *ngIf="displayAddDestination"
             [@zoomIn]="displayAddDestination">
            <div class="flex flex-col flex-auto w-full max-w-xl">
                <fuse-card
                    class="mt-8 p-6 pb-10 sm:p-10 sm:pb-7 rounded-2xl bg-card"
                    [flippable]="true"
                    [face]="'front'"
                    #flippableCard01="fuseCard">
                    <ng-container fuseCardFront>
                        <!--Alert-->
                        <fuse-alert
                            class="mb-8"
                            *ngIf="alert"
                            [type]="alert.type"
                            [showIcon]="false">
                            {{ alert.message }}
                        </fuse-alert>
                        <form
                            class="space-y-3"
                            (ngSubmit)="createDestination($event)"
                            [formGroup]="newDestinationForm">
                            <div class="mb-6">
                                <div class="text-2xl font-bold tracking-tight">Add New {{ destinationType?.ds_name }}
                                </div>
                            </div>
                            <!-- Name -->
                            <mat-form-field class="w-full">
                                <input
                                    readonly
                                    matInput
                                    class="text-yellow-600"
                                    value="{{destinationType.ds_label}}"
                                    [required]="true">
                                <mat-label>Type</mat-label>
                                <mat-error *ngIf="false">
                                    Required
                                </mat-error>
                            </mat-form-field>
                            <!-- Name -->

                            <mat-form-field class="w-full">
                                <mat-label>Parent</mat-label>
                                <input type="text"
                                       placeholder="Parent"
                                       aria-label="Parent"
                                       (blur)="onBlur()"
                                       matInput [formControl]="parentControl"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete
                                    (optionSelected)="selectClick($event)"
                                    #auto="matAutocomplete"
                                    [displayWith]="displayFn"
                                    autoActiveFirstOption="true">
                                    <mat-option *ngFor="let parent of destinationParentsArrayObserve | async" [value]="parent">
                                        {{parent.ds_name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="parentControl.invalid && (parentControl.touched || parentControl.dirty)">
                                    Please select a valid Parent.
                                </mat-error>
                            </mat-form-field>

                            <!-- Name -->
                            <mat-form-field class="w-full" *ngIf="false">
                                <mat-label>Parent</mat-label>
                                <mat-select [formControlName]="'id_parent'">
                                    <mat-option
                                        *ngFor="let parent of destinationParentsArray; index as i;"
                                        value="{{parent.id_destination}}" >{{ parent.ds_name }}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="newDestinationForm.get('id_parent').hasError('required')">
                                    A type is required
                                </mat-error>
                            </mat-form-field>

                            <!-- Name -->
                            <mat-form-field class="w-full">
                                <input
                                    maxlength="150"
                                    matInput
                                    [formControlName]="'ds_name'"
                                    [required]="true">
                                <mat-label>Name</mat-label>
                                <mat-error *ngIf="newDestinationForm.get('ds_name').hasError('required')">
                                    Required
                                </mat-error>
                            </mat-form-field>
                            <!-- Actions -->
                            <div class="flex items-center justify-end pb-4">
                                <button
                                    class="text-red-600"
                                    mat-stroked-button
                                    (click)="displayAddDestination = !displayAddDestination"
                                    [color]="'accent'">
                                    Cancel
                                </button>
                                <button
                                    class="ml-2"
                                    type="submit"
                                    mat-stroked-button
                                    [color]="'primary'">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </ng-container>

                </fuse-card>
            </div>
        </div>

    </div>

</div>

<ng-template #showDestination>
    <destinations-management (commandEvent)="handleCommand($event)" class="max-w-full overflow-hidden" [storyTypesArray]="storyTypesArray" [reviewMode]="reviewMode"
                                  [destination_ID]="destination_ID" [adminMode]="true"></destinations-management>
</ng-template>
