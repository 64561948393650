import {Injectable} from '@angular/core';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';

@Injectable({
    providedIn: 'root'
})
export class MessageFactoryService {

    /**
     * Constructor
     */
    constructor(
        private _fuseConfirmationService: FuseConfirmationService
    ) {
    }

    createErrorMessageBox(title:string = "Error!", message: string = "Error", timeout: number = 2000): void {
        const dialogBox = this._fuseConfirmationService.open({
            title:title,
            message: message,
            icon: {
                "show": true,
                "name": "heroicons_outline:exclamation-triangle",
                "color": "error"
            },
            actions: {
                confirm: {
                    show: false
                },
                cancel: {
                    show: false
                }
            },
            dismissible: false
        });
        setTimeout(() => {
            dialogBox.close();
        }, timeout);
    }

    createErrorMessageBoxPerm(title:string = "Error!", message: string = "Error"): void {
        const dialogBox = this._fuseConfirmationService.open({
            title:title,
            message: message,
            icon: {
                "show": true,
                "name": "heroicons_outline:exclamation-triangle",
                "color": "error"
            },
            actions: {
                confirm: {
                    show: true
                },
                cancel: {
                    show: false
                }
            },
            dismissible: true
        });
    }

    createSuccessMessageBox(title:string = "Success!", message: string = "The Action was successful", timeout: number = 2000): void {
        const dialogBox = this._fuseConfirmationService.open({
            title:title,
            message: message,
            icon: {
                show: true,
                name: "heroicons_outline:exclamation-circle",
                color: "success"
            },
            actions: {
                confirm: {
                    show: false
                },
                cancel: {
                    show: false
                }
            },
            dismissible: false
        });
        setTimeout(() => {
            dialogBox.close();
        }, timeout);
    }
}
