import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CommonModule, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {ImagePreloadDirective} from '../../../../core/directives/image-preload.directive';
import {NgxSpinnerComponent, NgxSpinnerService} from 'ngx-spinner';
import {catchError, finalize, Subject, takeUntil} from 'rxjs';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {RestFactoryService} from '../../../../core/services/rest-factory.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StoryTypesTypes} from '../types/story-types.types';

@Component({
    selector: "story-types-list",
    templateUrl: "./story-types-list.component.html",
    styleUrls: ["./story-types-list.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatDatepickerModule,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        NgForOf,
        ImagePreloadDirective,
        NgOptimizedImage,
        NgxSpinnerComponent,
    ]
})
export class StoryTypesListComponent implements OnInit, OnDestroy {

    isMobile: boolean;
    loadingMessage: string = null;

    selectedStoryType: StoryTypesTypes = null;
    selectedStoryTypeForm: UntypedFormGroup;

    imageURLHelper: string = null;

    flashMessage: 'success' | 'error' | 'dateChange' | null = null;

    enStoryTypesArray: {value: any, viewValue: any}[] = [
        {value: 1, viewValue: 'Info'},
        {value: 2, viewValue: 'FlyInfo'},
        {value: 3, viewValue: 'Place'},
        {value: 4, viewValue: 'Advertisement'},
        {value: 5, viewValue: 'Showcase'},
        {value: 6, viewValue: 'Experience'},
        {value: 7, viewValue: 'Activity'},
        {value: 8, viewValue: 'Learning'},
        {value: 9, viewValue: 'Map'},
    ];


    // New TODO

    inputSearchControl: UntypedFormControl = new UntypedFormControl("");
    originalStoryTypesArray: StoryTypesTypes[] = [];
    tableStoryTypesArray: StoryTypesTypes[] = [];

    // Pagination
    pageIndex: number = 0;
    pageSize: number = 10;
    pageLength: number = 0;
    isLoading: boolean = false;
    @ViewChild(MatPaginator) private _paginator: MatPaginator;


    // API URL
    private API_GET_STORY_TYPES = "/admin/storytype/list";
    private API_ADD_EDIT_STORY_TYPES = "/admin/storytype/addedit";
    private API_DELETE_STORY_TYPES = "/admin/storytype/delete";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _restFactoryService: RestFactoryService,
        private spinner: NgxSpinnerService,
        private breakpointObserver: BreakpointObserver,
        private _snackBar: MatSnackBar
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.breakpointObserver.observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            this.isMobile = result.matches;
        });

        this.spinner.show().then();
        this.getTableElements();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getTableElements(sID: string = null): void {
        this.isLoading = true;
        if (!sID) {
            this.selectedStoryType = null;
            this.selectedStoryTypeForm = null;
        }
        this._unsubscribeAll.next(null);
        this.spinner.show().then();
        this._restFactoryService.httpPostService(this.API_GET_STORY_TYPES, null)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                    this.spinner.hide().then();
                    this._changeDetectorRef.detectChanges();
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                (response) => {
                    this.originalStoryTypesArray = response.data;
                    if (this.originalStoryTypesArray.length === 0) {
                        this.pageIndex = 0;
                        this.pageLength = 0;
                        this._paginator.firstPage();
                        this.loadingMessage = "No results found";
                    } else {
                        this.searchElements(true);
                    }
                    if (sID) {
                        this.toggleDetails(sID);
                    }
                    this.isLoading = false;
                    this.spinner.hide().then();
                });
    }

    /**
     * Page changed
     */
    pageChanged(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.searchElements();
    }

    /**
     * Open new-search-window dialog
     */
    searchElements(searcher: boolean = false): void {
        if (searcher) {
            this.pageIndex = 0;
            this._paginator.firstPage();
        }
        const start = this.pageIndex * this.pageSize;
        const end = start + this.pageSize;

        if (this.inputSearchControl.getRawValue()) {
            this.tableStoryTypesArray = this.originalStoryTypesArray
                .filter(prop => prop.ds_name.toLowerCase().includes(this.inputSearchControl.getRawValue()))
                .slice(start, end);
            this.pageLength = this.tableStoryTypesArray.length;
        } else {
            this.tableStoryTypesArray = this.originalStoryTypesArray.slice(start, end);
            this.pageLength = this.originalStoryTypesArray.length;
        }
        this._changeDetectorRef.detectChanges();
    }

    findStoryType(id: number) {
        return this.enStoryTypesArray.find(storyType => storyType.value === id);
    }

    /**
     * Toggle product details
     *
     * @param soID
     */
    toggleDetails(soID: string): void {
        // If the product is already selected...
        if (this.selectedStoryType && this.selectedStoryType.id_storytype === soID) {
            // Close the details
            this.closeDetails();
            return;
        }

        if (this.selectedStoryType && this.selectedStoryType.id_storytype === null) {
            this.closeDetails();
            return;
        }

        // Find the selected special offer
        this.selectedStoryType = this.tableStoryTypesArray.find(item => item.id_storytype === soID) || null;

        // If a special offer is selected...
        if (this.selectedStoryType) {
            // Create the selected product form
            this.imageURLHelper = this.selectedStoryType.id_media;
            this.selectedStoryTypeForm = this._formBuilder.group({
                id_storytype: [this.selectedStoryType.id_storytype],
                id_media: [this.selectedStoryType.id_media],
                en_storytype: [this.selectedStoryType.en_storytype, Validators.required],
                vi_order: [this.selectedStoryType.vi_order, Validators.required],
                ds_label: [this.selectedStoryType.ds_label, Validators.required],
                ds_title: [this.selectedStoryType.ds_title, Validators.required],
                ds_subtitle: [this.selectedStoryType.ds_subtitle, Validators.required],
                ds_name: [this.selectedStoryType.ds_name, Validators.required],
                ds_shortname: [this.selectedStoryType.ds_shortname, Validators.required],
                ds_summary: [this.selectedStoryType.ds_summary],
                tx_description: [this.selectedStoryType.tx_description, Validators.required]
            });
        }

        this._changeDetectorRef.detectChanges();
    }

    /**
     * Close the details
     */
    closeDetails(): void {
        if (this.selectedStoryType.id_storytype === null || (this.selectedStoryTypeForm.touched && this.selectedStoryTypeForm.dirty)) {
            const message = this._fuseConfirmationService.open({
                title: "Warning!",
                message: "The story type is not saved, and will be lost on toggle!",
                actions: {
                    confirm: {
                        show: true
                    },
                    cancel: {
                        show: true
                    }
                },
                dismissible: false
            });
            message.afterClosed().subscribe(
                (value) => {
                    if (value === "confirmed") {
                        if (this.selectedStoryType.id_storytype === null) {
                            this.tableStoryTypesArray.shift();
                        }
                        this.selectedStoryType = null;
                        this.selectedStoryTypeForm = null;
                        this._changeDetectorRef.markForCheck();
                    }
                }
            );
        } else {
            this.selectedStoryType = null;
            this.selectedStoryTypeForm = null;
            this._changeDetectorRef.markForCheck();
        }
    }

    createAndToggleNewSpecialOffer(): void {
        // Create a new SpecialOffersType object
        let newStoryType: StoryTypesTypes = {
            id_storytype:null,
            id_media:null,
            en_storytype:1,
            vi_order:0,
            ds_label:null,
            ds_title:null,
            ds_subtitle:null,
            ds_name:null,
            ds_shortname:null,
            ds_summary:null,
            tx_description:null
            // ...
        };

        this.imageURLHelper = null;

        // Push the new special offer to the top of the specialOffersList array
        this.tableStoryTypesArray.unshift(newStoryType);

        // Toggle the new special offer
        this.toggleDetails(newStoryType.id_storytype);
    }

    deleteStoryType(): void {
        const message = this._fuseConfirmationService.open({
            title: "Delete Story Type",
            message: "Are you sure you want to delete this Story Type?",
            actions: {
                confirm: {
                    show: true
                },
                cancel: {
                    show: true
                }
            },
            dismissible: false
        });

        message.afterClosed().subscribe(
            (value) => {
                if (value === "confirmed") {
                    const payload = new FormData();
                    payload.append("id_storytype", this.selectedStoryType.id_storytype);
                    this._unsubscribeAll.next(null);
                    this._restFactoryService.httpPostService(this.API_DELETE_STORY_TYPES, payload)
                        .pipe(
                            takeUntil(this._unsubscribeAll),
                            finalize(() => {
                                this._changeDetectorRef.detectChanges();
                            }),
                            catchError((err) => {
                                return err;
                            })
                        )
                        .subscribe(
                            () => {
                                this.getTableElements();
                            });
                }
            }
        );
    }

    /**
     * upload File Manager
     */
    onChangeFile(event: any, property: string, source: string, id: string = null): void {
        const inpF = event.target as HTMLInputElement;
        const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp"];
        const fileExtension = (inpF.files[0] as any).name.toLowerCase().split('.').pop();

        if (allowedExtensions.includes(fileExtension)) {


            const file = inpF.files[0];
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(file);

            this.selectedStoryTypeForm.get('im_media').setValue(file);
            this.imageURLHelper = urlCreator.createObjectURL(file);
            this._changeDetectorRef.markForCheck();

        } else {
            let messageErr = "Invalid image format. Please upload a .jpg, .png, .jpeg, .gif, or .webp file.";
            const message = this._fuseConfirmationService.open({
                title: "Error!",
                message: messageErr,
                actions: {
                    confirm: {
                        show: false
                    },
                    cancel: {
                        show: false
                    }
                },
                dismissible: false
            });
            setTimeout(() => {
                message.close();
            }, 2000);
        }
    }

    /**
     * Open new-search-window dialog
     */
    saveStoryType(): void {

        const payload = new FormData();

        if (this.selectedStoryTypeForm.get("id_storytype").value) {
            payload.append("id_offer", this.selectedStoryTypeForm.get("id_storytype").value);
        }

        if (this.selectedStoryTypeForm.get("id_media").value !== this.selectedStoryType.id_media) {
            payload.append("im_image", this.selectedStoryTypeForm.get("id_media").value);
        }

        payload.append("en_storytype", this.selectedStoryTypeForm.get("en_storytype").value);
        payload.append("vi_order", this.selectedStoryTypeForm.get("vi_order").value);
        payload.append("ds_label", this.selectedStoryTypeForm.get("ds_label").value);
        payload.append("ds_title", this.selectedStoryTypeForm.get("ds_title").value);
        payload.append("ds_subtitle", this.selectedStoryTypeForm.get("ds_subtitle").value);
        payload.append("ds_name", this.selectedStoryTypeForm.get("ds_name").value);
        payload.append("ds_shortname", this.selectedStoryTypeForm.get("ds_shortname").value);
        payload.append("ds_summary", this.selectedStoryTypeForm.get("ds_summary").value);
        payload.append("tx_description", this.selectedStoryTypeForm.get("tx_description").value);

        this._unsubscribeAll.next(null);
        this._restFactoryService.httpPostService(this.API_ADD_EDIT_STORY_TYPES, payload)
            .pipe(
                takeUntil(this._unsubscribeAll),
                finalize(() => {
                }),
                catchError((err) => {
                    return err;
                })
            )
            .subscribe(
                () => {
                    this.getTableElements();
                });
    }

    onInputMaxReach(event: any, maxReach: number = 15): void {
        const inputValue = event.target.value;
        if (inputValue.length >= 25) {
            // The input value has reached or exceeded the limit
            // You can take appropriate action here
            this._snackBar.open(`This field has a limit of ${maxReach} characters. Any text exceeding this limit will be cut off.`, "Close", {
                duration: 5000,  // Duration is in milliseconds (5000ms = 5s),
                panelClass: ['bg-card', 'dark:bg-transparent']
            });
        }
    }

}
